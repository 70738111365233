import { Dropdown, Menu } from 'antd';
import React from 'react';

import useActiveUsers from '../../apps/trading-app/hooks/general/useActiveUsers';
import useEvents from '../../apps/trading-app/hooks/general/useEvents';
import useUser from '../../apps/trading-app/hooks/user/useUser';
import WithModal from '../../helpers/with-modal';
import SendMessageModal from './send-message-modal';
import UserAvatar from './user-avatar';

import style from './user-container.module.scss';

const UserContainer = (props: { path: string }) => {
  const { data } = useActiveUsers();
  const self = useUser();
  const events = useEvents();

  const hoverView = (
    <WithModal>
      {modal => (
        <Menu>
          {data
            ?.filter(x => {
              return x.location === props.path;
            })
            .sort((a, b) => a.lastUpdated - b.lastUpdated)
            .map(user => (
              <Menu.Item key={user.email} style={{ cursor: 'default' }}>
                <div
                  onClick={() => {
                    const modalRef = modal.info({
                      maskClosable: true,
                      okButtonProps: { style: { display: 'none' } },
                      content: (
                        <SendMessageModal
                          sendFrom={self.data!}
                          sendTo={user}
                          OnMessageSent={message => {
                            modalRef.destroy();
                            events.connection?.invoke(
                              'onSendUserMessage',
                              `${self.data?.firstName} ${self.data?.lastName}`,
                              self.data?.email,
                              user.email,
                              message
                            );
                          }}
                        />
                      ),
                    });
                  }}
                  className={style.activeUserList}
                >
                  <UserAvatar key={user.email} email={user.email} />
                  <span>
                    {user.firstName} {user.lastName}
                  </span>
                </div>
              </Menu.Item>
            ))}
        </Menu>
      )}
    </WithModal>
  );

  return (
    <Dropdown overlay={hoverView}>
      <div className={style.container}>
        {data
          ?.filter(x => {
            return x.location === props.path;
          })
          .map(user => (
            <UserAvatar key={user.email} email={user.email} />
          ))}
      </div>
    </Dropdown>
  );
};

export default UserContainer;
