import { Alert, Col, Row, Typography } from 'antd';
import React from 'react';
import { v4 } from 'uuid';

import useAlerts from '../../../apps/sharedHooks/useAlerts';
import useFullScreenMode from '../../hooks/useFullScreenMode';

export default function AppHostAlerts() {
  const [fullScreen] = useFullScreenMode();
  const { alerts, removeAlert } = useAlerts();

  if (fullScreen) return null;

  return (
    <>
      {alerts.map(alert => {
        return (
          <Alert
            key={v4()}
            banner
            type={alert.style}
            closable
            onClose={() => removeAlert(alert)}
            message={
              <Row justify="space-between">
                <Col flex={1}>
                  <Typography.Text>{alert.title}</Typography.Text>
                </Col>
              </Row>
            }
            description={alert.content}
          />
        );
      })}
    </>
  );
}
