import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Dropdown, Menu } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';

import useRWHistory from '../../../../../../components/hooks/general/useRWHistory';
import UserAvatar from '../../../../../../components/user-avatar/user-avatar';
import Css from '../../../../../../helpers/css/Css';
import { useAuth0 } from '../../../../../../helpers/useAuth0';
import useApps from '../../../../../apps';
import useUser from '../../../../hooks/user/useUser';

import styles from './user-profile.module.scss';

const UserProfile = () => {
  const { logout } = useAuth0();
  const { allowedApps } = useApps();

  const isDesktop = useBreakpoint().lg;

  const accountApp = allowedApps.find(app => app.name === 'Account');
  const logoutUrl = window.ENVARGS.REACT_APP_AUTH0_LOGOUT_URL;
  return isDesktop ? (
    <DesktopUserProfile logout={() => logout({ returnTo: logoutUrl })} accountAppBaseRoute={accountApp?.baseRoute} />
  ) : (
    <MobileUserProfile logout={() => logout({ returnTo: logoutUrl })} accountAppBaseRoute={accountApp?.baseRoute} />
  );
};

function DesktopUserProfile(props: { logout: () => void; accountAppBaseRoute: string | undefined }) {
  const { data: user } = useUser();
  const history = useRWHistory();

  return (
    <Dropdown
      overlay={
        <Menu className={styles.menu} selectedKeys={[]}>
          <Menu.Item key="account" onClick={() => history.push(`/${props.accountAppBaseRoute}/overview`)}>
            <UserOutlined />
            Account
          </Menu.Item>
          <Menu.Item key="logout" onClick={props.logout}>
            <LogoutOutlined />
            Logout
          </Menu.Item>
        </Menu>
      }
    >
      <span style={{ cursor: 'pointer' }} className={`${styles.action} ${styles.account}`}>
        <UserAvatar email={user?.email} />
        <span className={styles.name}>{user?.firstName + ' ' + user?.lastName}</span>
      </span>
    </Dropdown>
  );
}

function MobileUserProfile(props: { logout: () => void; accountAppBaseRoute: string | undefined }) {
  const history = useRWHistory();

  return (
    <>
      <Col
        className={[Css.Packs.Clickable.Primary, styles.mobileIcon].join(' ')}
        onClick={() => history.push(`/${props.accountAppBaseRoute}/overview`)}
      >
        <UserOutlined />
      </Col>
      <Col className={styles.mobileIcon} onClick={props.logout}>
        <LogoutOutlined />
      </Col>
    </>
  );
}

export default UserProfile;
