import createAuth0Client, {
  Auth0Client,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  IdToken,
  LogoutOptions,
  PopupConfigOptions,
  PopupLoginOptions,
  RedirectLoginOptions,
  getIdTokenClaimsOptions,
} from '@auth0/auth0-spa-js';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

type Auth0User = {
  'https://app.rarewine-trading.com/user_metadata': { [key: string]: string };
  'http://roles': Role[];
  nickname: string;
  picture: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  sub: string;
};

type Auth0Ctx = {
  isAuthenticated: boolean;
  user?: Auth0User;
  hasRoles: (roles: Role | Role[]) => boolean;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup: (options?: PopupLoginOptions, config?: PopupConfigOptions) => Promise<void>;
  handleRedirectCallback: () => Promise<void>;
  getIdTokenClaims: (p?: getIdTokenClaimsOptions) => Promise<IdToken>;
  loginWithRedirect: (p?: RedirectLoginOptions) => Promise<void>;
  getTokenSilently: (p?: GetTokenSilentlyOptions) => Promise<any>;
  getTokenWithPopup: (p?: GetTokenWithPopupOptions) => Promise<string>;
  logout: (p?: LogoutOptions) => void;
};

export const Auth0Context = React.createContext<Auth0Ctx>({
  isAuthenticated: false,
  user: undefined,
  hasRoles: roles => false,
  loading: false,
  popupOpen: false,
  loginWithPopup: async (options?: PopupLoginOptions, config?: PopupConfigOptions) => {},
  handleRedirectCallback: async () => {},
  getIdTokenClaims: async (p?: getIdTokenClaimsOptions) => {
    return {} as IdToken;
  },
  loginWithRedirect: async (p?: RedirectLoginOptions) => {},
  getTokenSilently: async (p?: GetTokenSilentlyOptions) => undefined,
  getTokenWithPopup: async (p?: GetTokenWithPopupOptions) => '',
  logout: (p?: LogoutOptions) => {},
});

export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider: React.FunctionComponent = ({
  children,
  // onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<Auth0User>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        domain: window.ENVARGS.REACT_APP_AUTH0_DOMAIN!,
        client_id: window.ENVARGS.REACT_APP_AUTH0_CLIENTID!,
        redirect_uri: window.location.origin + '/callback',
        audience: window.ENVARGS.REACT_APP_AUTH0_AUDIENCE!,
      });

      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=') && !window.location.search.includes('state=docusign_')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();

        history.push(appState?.targetPath ?? '/');
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      if (isAuthenticated) {
        const user = (await auth0FromHook.getUser()) as Auth0User;

        setUser(user);
      }

      setIsAuthenticated(isAuthenticated);

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (options?: PopupLoginOptions, config?: PopupConfigOptions) => {
    setPopupOpen(true);

    try {
      await auth0Client!.loginWithPopup(options, config);
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    } finally {
      setPopupOpen(false);
    }

    const user = (await auth0Client!.getUser()) as Auth0User;

    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);

    await auth0Client!.handleRedirectCallback();

    const user = (await auth0Client!.getUser()) as Auth0User;

    setLoading(false);
    setUser(user);
    setIsAuthenticated(true);
  };

  const hasRoles = (roles: Role | Role[]) => {
    const roleArray = Array.isArray(roles) ? roles : [roles];

    return !!user && (roleArray.every(role => user?.['http://roles'].includes(role)) || user?.['http://roles'].includes('Developer'));
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        hasRoles,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (p?: getIdTokenClaimsOptions) => auth0Client!.getIdTokenClaims(p),
        loginWithRedirect: (p?: RedirectLoginOptions) => auth0Client!.loginWithRedirect(p),
        getTokenSilently: (p?: GetTokenSilentlyOptions) => auth0Client!.getTokenSilently(p),
        getTokenWithPopup: (p?: GetTokenWithPopupOptions) => auth0Client!.getTokenWithPopup(p),
        logout: (p?: LogoutOptions) => auth0Client!.logout(p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
