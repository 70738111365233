import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

import useUser from '../../apps/trading-app/hooks/user/useUser';
import { useAuth0 } from '../../helpers/useAuth0';
import crate1 from '../../images/splash/crate_01.jpg';
import OverlayLoading from '../overlay-loading/overlay-loading';

import style from './splash-screen.module.scss';

const SplashScreen = () => {
  const { loginWithRedirect, loading } = useAuth0();
  const { loading: userLoading } = useUser();

  const history = useHistory();

  return (
    <>
      <img alt="" className={style.bg} src={crate1} />
      {loading || userLoading ? (
        <OverlayLoading />
      ) : (
        <div className={style.container}>
          <div className={[style.login, style.fitToWidth].join(' ')}>
            <p>Welcome to Rare Wine Trading - Please login to continue to a world of fine wines and spirits</p>
            <Button size="large" type="primary" onClick={() => loginWithRedirect({ appState: { targetPath: history.location.pathname } })}>
              Enter
            </Button>
          </div>
          <div className={style.affiliation}>
            <h5>
              A part of <a href="https://rarewine.com">RareWine A/S</a> and <a href="https://www.rarewineinvest.com/">Rare Wine Invest</a>
            </h5>
          </div>
        </div>
      )}
    </>
  );
};

export default SplashScreen;
