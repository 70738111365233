import {
  FullscreenExitOutlined,
  FullscreenOutlined,
  PictureOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button, Col, Descriptions, Drawer, Row, Space, Spin, Table, Tooltip, notification } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { useEffect, useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { FormattedDate } from 'react-intl';
import { useParams } from 'react-router-dom';

import ContentArea from '../../../../components/content-area/content-area';
import useRWHistory from '../../../../components/hooks/general/useRWHistory';
import useFullScreenMode from '../../../../components/hooks/useFullScreenMode';
import IconComposition from '../../../../components/icons/IconComposition';
import { OverlayLoadingComponent } from '../../../../components/overlay-loading/overlay-loading';
import Css from '../../../../helpers/css/Css';
import WithModal from '../../../../helpers/with-modal';
import Searcher from '../../../../rarewine-library-frontend/components/searcher/seacher';
import useFilterer from '../../../../rarewine-library-frontend/hooks/useFilterer';
import useGuide from '../../../sharedHooks/useGuide';
import useImageProductMaps from '../../../warehouse-app/hooks/useImageProductMaps';
import WineImages from '../../components/wine-images/wine-images';
import useQueryParams from '../../hooks/general/useQueryParams';
import useResyncStocklist from '../../hooks/warehouse/useSyncStocklist';
import useWarehouseData from '../../hooks/warehouse/useWarehouseData';
import PendingImageForm from './components/PendingImageForm';
import LivExLogo from './components/liv-ex-logo';
import NestedVariantTable from './components/nested-variant-table';

import style from './instock.module.scss';

function uniqueFilter(value: any, index: any, arr: any[]) {
  return arr.indexOf(value) === index;
}

type LivExCandidateInStockWine = InStockWine & { livexVariants: NavItemVariant[] };

export default function InStock() {
  const [livexFilterEnabled, setLivexFilterEnabled] = useState(false);
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [selectedWine, setSelectedWine] = useState<NavItemVariant | undefined>();

  const [fullScreen, setFullScreen] = useFullScreenMode();
  const { showGuide, completeStep } = useGuide('See/Request images');
  const history = useRWHistory();

  const { search } = useQueryParams(['search']);
  const { data, loading } = useWarehouseData();
  const { data: images, loading: imagesLoading } = useImageProductMaps();
  const filteredData = useFilterer(
    data,
    searchTerms,
    w =>
      `${w.wine.id} ${w.wine.lwin} ${w.wine.name} ${w.wine.producer} ${w.wine.subRegion} ${w.wine.year || 'nv'} 
       ${w.wine.bottleSize} (${w.wine.bottleSizeNumerical}ml) ${w.variants.map(x => x.packagingString).join(' ')} 
       ${w.variants.map(x => x.backLabelTypeString).join(' ')} ${w.variants.map(x => x.wineCondition).join(' ')}`
  );
  const livexFilteredData = useMemo(() => (livexFilterEnabled ? doLivexFiltering(filteredData, images) : filteredData), [
    livexFilterEnabled,
    filteredData,
  ]);

  const { mutate: resyncStocklist, loading: loadingResync } = useResyncStocklist();

  const getActiveVariants = (wine: InStockWine) => (livexFilterEnabled ? (wine as LivExCandidateInStockWine).livexVariants : wine.variants);

  const Highlight = ({ text }: { text?: string }) => (
    <Highlighter
      highlightClassName={style.highlight}
      highlightTag="span"
      searchWords={searchTerms}
      autoEscape={true}
      textToHighlight={text ?? ''}
    />
  );

  const nestedTable = (row: InStockWine) => {
    return <NestedVariantTable searchTerms={searchTerms} data={getActiveVariants(row)} showImagesFor={setSelectedWine} />;
  };

  return (
    <WithModal>
      {modal => (
        <ContentArea showBackground>
          <OverlayLoadingComponent loading={loading}>
            <ContentArea showBackground>
              {!fullScreen && (
                <Row>
                  <Col span={18}>
                    <SearchOverview data={livexFilteredData} />
                  </Col>
                  <Col span={6}>
                    <Row justify="end">
                      <Tooltip title="Show only items that are suitable for Liv|Ex.">
                        <Button onClick={() => setLivexFilterEnabled(!livexFilterEnabled)} disabled={imagesLoading} loading={imagesLoading}>
                          {livexFilterEnabled ? 'Disable Liv|Ex Filter' : 'Enable Liv|Ex Filter'}
                        </Button>
                      </Tooltip>
                    </Row>
                  </Col>
                </Row>
              )}
              <Searcher
                buttonsLeft={[
                  <Tooltip title="Check pending picture request">
                    <IconComposition
                      icon={<PictureOutlined />}
                      subIcon={<SearchOutlined />}
                      className={Css.Packs.Clickable.Success}
                      onClick={() => {
                        modal.info({
                          title: 'Pending images',
                          desktopWidth: '70%',
                          centered: true,
                          maskClosable: true,
                          content: <PendingImageForm StockListWines={data} />,
                        });
                      }}
                    />
                  </Tooltip>,
                  <Tooltip title="Refresh public stocklist">
                    {loadingResync ? (
                      <Spin />
                    ) : (
                      <IconComposition
                        icon={<ShoppingCartOutlined />}
                        subIcon={<SyncOutlined />}
                        className={Css.Packs.Clickable.Subtle}
                        onClick={async () => {
                          try {
                            await resyncStocklist({
                              variables: { input: {} },
                            });
                            notification.success({
                              message: 'Stocklist synchronisation was queued',
                              description: 'It may take up to 5 minutes before the new stocklist is available',
                            });
                          } catch (e) {
                            notification.error({
                              message: 'Stock list synchronisation could not be started',
                              description: e.message,
                            });
                          }
                        }}
                      />
                    )}
                  </Tooltip>,
                ]}
                buttonsRight={[
                  fullScreen ? (
                    <Tooltip title="Exit fullscreen">
                      <FullscreenExitOutlined
                        style={{ fontSize: 19 }}
                        className={Css.Packs.Clickable.Primary}
                        onClick={() => setFullScreen(false)}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Fullscreen">
                      <FullscreenOutlined
                        style={{ fontSize: 19 }}
                        className={Css.Packs.Clickable.Primary}
                        onClick={() => setFullScreen(true)}
                      />
                    </Tooltip>
                  ),
                ]}
                disableAutoFocusOnWriting={true}
                queryParameterName="itemNumber"
                onSearchTerms={setSearchTerms}
              />
              <Table<InStockWine>
                dataSource={livexFilteredData}
                scroll={fullScreen ? undefined : { x: 1500, y: 400 }}
                rowKey={row => row.wine.id}
                expandedRowRender={nestedTable}
                onExpand={() => completeStep(0)}
                defaultExpandedRowKeys={search ? [search] : undefined}
                className="bumbleBeeTable"
              >
                <Table.Column<InStockWine>
                  key="id"
                  title="Item Number (Lwin)"
                  fixed="left"
                  dataIndex="id"
                  sorter={(a, b) => parseInt(a.wine.id) - parseInt(b.wine.id)}
                  render={(id, row) => {
                    if (row.wine.id === livexFilteredData[0].wine.id) {
                      return (
                        <Tooltip visible={showGuide(0)} title="Fold out">
                          <>
                            <Highlight text={`${row.wine.id} (${row.wine.lwin})`} />;
                          </>
                        </Tooltip>
                      );
                    }
                    return <Highlight text={`${row.wine.id} (${row.wine.lwin})`} />;
                  }}
                />
                <Table.Column<InStockWine>
                  key="variant"
                  title="Variants"
                  dataIndex="variant"
                  sorter={(a, b) => getActiveVariants(a).length - getActiveVariants(b).length}
                  render={(id, row) => getActiveVariants(row).length}
                />
                <Table.Column<InStockWine>
                  key="producer"
                  title="Producer"
                  dataIndex="producer"
                  sorter={(a, b) => a.wine.producer?.length - b.wine.producer?.length}
                  render={(_, row) => <Highlight text={row.wine.producer} />}
                />
                <Table.Column<InStockWine>
                  key="subRegion"
                  title="SubRegion"
                  dataIndex="subRegion"
                  sorter={(a, b) => a.wine.subRegion?.length - b.wine.subRegion?.length}
                  render={(_, row) => <Highlight text={row.wine.subRegion} />}
                />
                <Table.Column<InStockWine>
                  key="name"
                  title="Name"
                  dataIndex="name"
                  sorter={(a, b) => a.wine.name?.length - b.wine.name?.length}
                  render={(_, row) => <Highlight text={row.wine.name} />}
                />
                <Table.Column<InStockWine>
                  key="year"
                  title="Year"
                  dataIndex="year"
                  sorter={(a, b) => a.wine.year - b.wine.year}
                  render={(_, row) => <Highlight text={'' + (row.wine.year || 'NV')} />}
                />
                <Table.Column<InStockWine>
                  key="size"
                  title="Bottle Size"
                  sorter={(a, b) => a.wine.bottleSizeNumerical - b.wine.bottleSizeNumerical}
                  render={(_, row) => <Highlight text={`${row.wine.bottleSize} (${row.wine.bottleSizeNumerical}ml)`} />}
                />
                <Table.Column<InStockWine>
                  key="boxType"
                  title="BoxType"
                  dataIndex="boxType"
                  sorter={(a, b) =>
                    getActiveVariants(a)
                      .map(x => x.packagingString)
                      .filter(uniqueFilter).length -
                    getActiveVariants(b)
                      .map(x => x.packagingString)
                      .filter(uniqueFilter).length
                  }
                  render={(_, row) => (
                    <Highlight
                      text={getActiveVariants(row)
                        .map(x => x.packagingString)
                        .filter(uniqueFilter)
                        .sort()
                        .join(', ')}
                    />
                  )}
                />
                <Table.Column<InStockWine>
                  key="boxSize"
                  title="Box Sizes"
                  dataIndex="boxSize"
                  sorter={(a, b) =>
                    getActiveVariants(a)
                      .map(x => x.packagingSize)
                      .filter(uniqueFilter).length -
                    getActiveVariants(b)
                      .map(x => x.packagingSize)
                      .filter(uniqueFilter).length
                  }
                  render={(_, row) => (
                    <Highlight
                      text={getActiveVariants(row)
                        .map(x => x.packagingSize || '-')
                        .filter(uniqueFilter)
                        .sort()
                        .join(', ')}
                    />
                  )}
                />
                <Table.Column<InStockWine>
                  key="wineConditions"
                  title="Conditions"
                  sorter={(a, b) =>
                    getActiveVariants(a)
                      .map(x => x.wineCondition)
                      .filter(uniqueFilter).length -
                    getActiveVariants(b)
                      .map(x => x.wineCondition)
                      .filter(uniqueFilter).length
                  }
                  render={(_, row) => (
                    <Highlight
                      text={getActiveVariants(row)
                        .map(x => x.wineCondition)
                        .filter(uniqueFilter)
                        .sort()
                        .join(', ')}
                    />
                  )}
                />
                <Table.Column<InStockWine>
                  key="totalAvailable"
                  title="Total Available"
                  render={(_, row) => row.wine.totalAvailable}
                  sorter={(a, b) => a.wine.totalAvailable - b.wine.totalAvailable}
                />
                <Table.Column<InStockWine>
                  key="livex"
                  title={<LivExLogo tooltip="Number of variants reserved for Liv|Ex" />}
                  render={(_, i) => i.variants.filter(v => v.reservedForLivEx).length}
                  filters={[
                    { text: 'Reserved for Liv|Ex', value: true },
                    { text: 'Not reserved', value: false },
                  ]}
                  onFilter={(showReserved, isw) => {
                    if (showReserved) return isw.variants.some(v => v.reservedForLivEx);
                    else return isw.variants.every(v => !v.reservedForLivEx);
                  }}
                />
                <Table.Column<InStockWine>
                  key="lastPoDate"
                  title="Last PO Date"
                  sorter={(a, b) => a.wine.lastPoDate.getTime() - b.wine.lastPoDate.getTime()}
                  // The number is equal to the date 01-01-1753, which is replaced to avoid confusion.
                  render={(_, row) =>
                    row.wine.lastPoDate.getTime() === -6847807820000 ? '-' : <FormattedDate value={row.wine.lastPoDate} />
                  }
                />
                <Table.Column<InStockWine>
                  key="actions"
                  title="Actions"
                  render={(_, row) => {
                    return (
                      <Space direction="vertical">
                        <Button
                          className={Css.Packs.Clickable.Subtle + Css.Effects.Transition.From.Left}
                          type="link"
                          onClick={() => {
                            history.push(`/trading/list/?search=${row.wine.producer} ${row.wine.subRegion} ${row.wine.name}`);
                          }}
                        >
                          Find in offer list
                        </Button>
                      </Space>
                    );
                  }}
                />
              </Table>
            </ContentArea>
            <AutoExpandWineImageArchive wines={data} selectedWine={selectedWine} onClose={() => setSelectedWine(undefined)} />
          </OverlayLoadingComponent>
        </ContentArea>
      )}
    </WithModal>
  );
}

const AutoExpandWineImageArchive = (props: { wines: InStockWine[]; selectedWine: NavItemVariant | undefined; onClose: () => void }) => {
  const { wines } = props;
  const params = useParams<{ itemNumber?: string; variantNumber?: string; showImages?: string }>();
  const [selectedWineVariant, setselectedWineVariant] = useState<NavItemVariant | undefined>();

  useEffect(() => {
    if (wines && params?.variantNumber) {
      const item = wines.find(x => x.wine.id === params.itemNumber)?.variants.find(v => v.variant === params.variantNumber);
      if (item) {
        setselectedWineVariant(item);
      }
    }
  }, [params, wines, setselectedWineVariant]);

  return (
    <WineImageArchiveDrawer
      wine={props.selectedWine || selectedWineVariant}
      onClose={() => {
        setselectedWineVariant(undefined);
        props.onClose();
      }}
    />
  );
};

const WineImageArchiveDrawer = (props: { wine: NavItemVariant | undefined; onClose: () => void }) => {
  const { wine } = props;
  const isDesktop = useBreakpoint().lg;

  return (
    <Drawer
      title={`Image archive for ${wine?.name}, ${wine?.year}`}
      visible={!!wine}
      closable={true}
      onClose={props.onClose}
      width={isDesktop ? '30%' : '95%'}
    >
      {wine && <WineImages {...wine} />}
    </Drawer>
  );
};

function SearchOverview({ data }: { data: InStockWine[] | undefined }) {
  const totalBottles = useMemo(() => (!data ? '?' : data.sumBy(w => w.wine.totalAvailable)), [data]);
  const totalLitres = useMemo(
    () => (!data ? '?' : Math.floor(data.sumBy(w => w.wine.totalAvailable * w.wine.bottleSizeNumerical) / 1000)),
    [data]
  );

  return (
    <Descriptions>
      <DescriptionsItem label="Total bottles">{totalBottles}</DescriptionsItem>
      <DescriptionsItem label="Total litres">{totalLitres}</DescriptionsItem>
    </Descriptions>
  );
}

function doLivexFiltering(data: InStockWine[], images: ProductImageMapRequest[] | undefined): LivExCandidateInStockWine[] {
  const hasImagesForItem = (itemNo: string, variant: string) => {
    const image = images?.find(i => i.productIds.some(pid => pid?.includes && pid?.includes(itemNo) && pid?.includes(variant)));
    return image?.imageIds?.length;
  };

  // Have a variant that is available, not already reserved for Liv|Ex and has images
  const variantFilter = (wine: InStockWine, variant: NavItemVariant) =>
    variant.available > 0 &&
    variant.availableWarehouse > 0 &&
    !variant.reservedForLivEx &&
    hasImagesForItem(wine.wine.id, variant.variant) &&
    variant.productPostingGroup !== 'LIQUOR' &&
    !variant.skipInExport;

  const filtered = data.filter(w => w.variants.some(v => variantFilter(w, v)));

  return filtered.map(w => {
    return { ...w, livexVariants: w.variants.filter(v => variantFilter(w, v)) };
  });
}
