import { Col, Layout, Menu, Row, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { useState } from 'react';

import useApps from '../../../apps/apps';
import Css from '../../../helpers/css/Css';
import { useAuth0 } from '../../../helpers/useAuth0';
import useRWHistory from '../../hooks/general/useRWHistory';
import useFullScreenMode from '../../hooks/useFullScreenMode';
import UserContainer from '../../user-avatar/user-container';

import style from './AppHostSideMenu.module.scss';

const { Text } = Typography;
const { Sider } = Layout;

const BetaTag = () => (
  <div style={{ display: 'inline-block' }}>
    <div className={style.beta}>Beta</div>
  </div>
);

const MenuItemRender = (menu: RouteInfo) => (
  <>
    {menu.customRender?.(menu) ?? (
      <>
        {menu.icon}
        <span>{menu.text}</span>
        {menu.notification && <span style={{ float: 'right' }}>{menu.notification}</span>}
      </>
    )}
    {menu.beta && <BetaTag />}
  </>
);

export default function AppHostSideMenu(props: { siderCollapsed: boolean; onSiderToggled: (collapsed: boolean) => void }) {
  const [fullScreen] = useFullScreenMode();
  const [deparmentPopdownActive, setDepartmentPopdownActive] = useState(false);
  const { hasRoles } = useAuth0();
  const history = useRWHistory();
  const { currentApp, allowedApps } = useApps();
  const isDesktop = useBreakpoint().lg;

  const closeSiderMenu = () => {
    setDepartmentPopdownActive(false);
    if (!isDesktop) props.onSiderToggled(true);
  };

  const createMenuItemFromRouteInfo = (menu: RouteInfo) => {
    if (menu.children) {
      return (
        <SubMenu key={menu.path} title={MenuItemRender(menu)}>
          {menu.children
            .filter(child => !child.hideFromSidebar && (!child.requiredRoles || hasRoles(child.requiredRoles)))
            .map(childMenu =>
              createMenuItemFromRouteInfo({
                ...childMenu,
                path: menu.path + '/' + childMenu.path,
              })
            )}
        </SubMenu>
      );
    }

    return (
      <Menu.Item
        className={Css.Packs.Clickable.Subtle + Css.Effects.Transition.From.Left}
        onClick={() => {
          history.push(`/${currentApp?.baseRoute}/${menu.path}`);
          closeSiderMenu();
        }}
        key={menu.path}
      >
        {MenuItemRender(menu)}
        <UserContainer path={`/${currentApp?.baseRoute}/${menu.path}`} />
      </Menu.Item>
    );
  };

  return (
    <Sider
      className={[fullScreen ? Css.Effects.Static.Opacity.Hidden : '', style.appHostMenu, props.siderCollapsed ? style.collapsed : ''].join(
        ' '
      )}
      width="256px"
      collapsible
      collapsed={props.siderCollapsed}
      onCollapse={props.onSiderToggled}
    >
      <Row>
        <div className={`${style.appContainer} ${deparmentPopdownActive && style.active}`}>
          {deparmentPopdownActive &&
            allowedApps
              .filter(app => !app.hiddenFromSideMenu)
              .map(app => (
                <div
                  key={app.name}
                  onClick={() => {
                    history.push('/' + app.baseRoute);
                    closeSiderMenu();
                  }}
                  className={`${app.className} ${style.appIcon} ${Css.Packs.Clickable.Subtle}`}
                >
                  {app.icon || app.name.substring(0, 1)}
                  <span>{app.name}</span>
                </div>
              ))}
        </div>
        <Row gutter={16} justify="start" style={{ padding: 12 }} onClick={() => setDepartmentPopdownActive(!deparmentPopdownActive)}>
          <Col flex={1}>
            <div className={`${style.logoContainer} ${style.appIcon} ${currentApp?.className}`}>
              <h1 className={style.logoText}>{currentApp?.name.substring(0, 1)}</h1>
            </div>
          </Col>
          {!props.siderCollapsed && (
            <Col flex={3}>
              <div className={style.appTitle}>
                <Text ellipsis>{currentApp?.name.toUpperCase()}</Text>
              </div>
            </Col>
          )}
        </Row>
      </Row>
      <Row>
        <Menu theme="dark" selectedKeys={[window.location.pathname]} mode="inline">
          {currentApp?.routes
            .filter(menu => !menu.hideFromSidebar && (!menu.requiredRoles || hasRoles(menu.requiredRoles)))
            .map(createMenuItemFromRouteInfo)}
        </Menu>
      </Row>
    </Sider>
  );
}
