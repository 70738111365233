import { Spin } from 'antd';
import React from 'react';

import { useAuth0 } from '../../helpers/useAuth0';

function Callback() {
  const { loading, handleRedirectCallback } = useAuth0();

  if (!loading) {
    handleRedirectCallback();
  }

  return <Spin />;
}

export default Callback;
