import { FallOutlined, FullscreenExitOutlined, FullscreenOutlined, MinusOutlined, PlusOutlined, RiseOutlined } from '@ant-design/icons';
import { Button, Col, Progress, Row, Table, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';

import ContentArea from '../../../../components/content-area/content-area';
import useFullScreenMode from '../../../../components/hooks/useFullScreenMode';
import { OverlayLoadingComponent } from '../../../../components/overlay-loading/overlay-loading';
import Css from '../../../../helpers/css/Css';
import Searcher from '../../../../rarewine-library-frontend/components/searcher/seacher';
import useCurrencyConverter from '../../hooks/currency/useCurrencyConverter';
import { useMasterList } from '../../hooks/offer/useMasterList';

import style from './master-list.module.scss';

const MasterList = () => {
  const [fullScreen, setFullScreen] = useFullScreenMode();
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [filteredOffers, setFilteredOffers] = useState<OfferHeader[]>([]);
  const { formatMessage: f } = useIntl();

  const { loadingCurrent, loadingTotal, api: masterListData, offers } = useMasterList();

  const cc = useCurrencyConverter();

  useEffect(() => {
    if (searchTerms.length) {
      const filteredOffers = offers.filter(
        offerLine =>
          !searchTerms.length ||
          searchTerms.every(
            s =>
              offerLine.wine?.toLowerCase().includes(s) ||
              offerLine.producer?.toLowerCase().includes(s) ||
              offerLine.region?.toLowerCase().includes(s) ||
              offerLine.subRegion?.toLowerCase().includes(s) ||
              ('' + (offerLine.year || 'nv')).includes(s) ||
              offerLine.packagingTypes?.join(' ').toLowerCase().includes(s) ||
              offerLine.bottleSize?.toLowerCase().includes(s)
          )
      );

      setFilteredOffers(filteredOffers);
    }
  }, [searchTerms, offers]);

  const CreateFilter = (list: OfferHeader[], property: keyof OfferHeader, keyFixer?: (value: any) => string) => {
    const groupByProp: { [key: string]: number } = {};
    const rawValues: { [key: string]: any } = {};
    list.forEach(ol => {
      const keyGetter = keyFixer ?? ((value: any) => value);

      let key = '' + keyGetter(ol[property]);
      let rawValue = '' + ol[property];

      if (groupByProp[key] !== undefined) {
        groupByProp[key]++;
      } else {
        groupByProp[key] = 1;
      }

      rawValues[key] = rawValue;
    });

    return Object.keys(groupByProp)
      .sort()
      .map(key => {
        return {
          text: `${key} (${groupByProp[key]})`,
          value: rawValues[key] || '-',
        };
      });
  };

  const yearFilter = CreateFilter(filteredOffers, 'year', year => year || 'NV');
  const bottleSizeFilter = CreateFilter(filteredOffers, 'bottleSize');
  const regionFilter = CreateFilter(filteredOffers, 'region', region => region || '-');
  const subRegionFilter = CreateFilter(filteredOffers, 'subRegion', subRegion => subRegion || '-');
  const wineFilter = CreateFilter(filteredOffers, 'wine', wine => wine || '-');
  const producerFilter = CreateFilter(filteredOffers, 'producer', producer => producer || '-');
  const packagingFilter = [
    { text: 'Loose', value: 'Loose' },
    { text: 'OC', value: 'OC' },
    { text: 'OWC', value: 'OWC' },
    { text: 'IB', value: 'IB' },
  ];

  const Highlight = ({ text }: { text?: string }) => (
    <Highlighter
      highlightClassName={style.highlight}
      highlightTag="span"
      searchWords={searchTerms}
      autoEscape={true}
      textToHighlight={text ?? ''}
    />
  );

  const list = useMemo(() => {
    return (
      <>
        <Table<OfferHeader>
          dataSource={filteredOffers}
          scroll={fullScreen ? undefined : { x: 1500, y: 400 }}
          rowKey={row => row.id}
          rowClassName={(record, index) => style[`row-${record.type?.toLowerCase() || 'still'}-${record.colour?.toLowerCase() || 'red'}`]}
          pagination={{ pageSize: 30 }}
        >
          <Table.Column<OfferHeader>
            key="producer"
            title="Producer"
            fixed="left"
            dataIndex="producer"
            filters={producerFilter}
            onFilter={(value, record) => record.producer === value || (value === '-' && !record.producer)}
            sorter={(a, b) => a.producer?.localeCompare(b.producer)}
            render={(value, record) => <Highlight text={record.producer} />}
          />
          <Table.Column<OfferHeader>
            key="region"
            title="Region"
            dataIndex="region"
            filters={regionFilter}
            onFilter={(value, record) => record.region === value || (value === '-' && !record.region)}
            sorter={(a, b) => a.region?.localeCompare(b.region)}
            className={style['region-cell']}
            render={(value, record) => <Highlight text={record.region} />}
          />
          <Table.Column<OfferHeader>
            key="subRegion"
            title="Sub-Region"
            dataIndex="subRegion"
            filters={subRegionFilter}
            onFilter={(value, record) => record.subRegion === value || (value === '-' && !record.subRegion)}
            sorter={(a, b) => a.subRegion?.localeCompare(b.subRegion)}
            className={style['region-cell']}
            render={(value, record) => <Highlight text={record.subRegion} />}
          />
          <Table.Column<OfferHeader>
            key="wine"
            title="Wine"
            dataIndex="wine"
            filters={wineFilter}
            onFilter={(value, record) => record.wine === value || (value === '-' && !record.wine)}
            sorter={(a, b) => a.wine?.localeCompare(b.wine)}
            className={style['wine-cell']}
            render={(value, record) => <Highlight text={record.wine} />}
          />
          <Table.Column<OfferHeader>
            key="year"
            title="Year"
            dataIndex="year"
            filters={yearFilter}
            onFilter={(value, record) => (value === 'N/V' ? record.year <= 0 : record.year.toString() === value)}
            sorter={(a, b) => a.year - b.year}
            render={(value, record) => <Highlight text={'' + (record.year || 'NV')} />}
          />
          <Table.Column<OfferHeader>
            key="packaging"
            title="Packaging"
            dataIndex="packaging"
            filters={packagingFilter}
            onFilter={(value, record) => record.packagingTypes.includes(value as string)}
            sorter={(a, b) => a.packaging.length - b.packaging.length}
          />
          <Table.Column<OfferHeader>
            key="bottleSize"
            title="Bottle size"
            dataIndex="bottleSize"
            filters={bottleSizeFilter}
            onFilter={(value, record) => record.bottleSize === value}
            sorter={(a, b) => (a.bottleSize > b.bottleSize ? 1 : -1)}
            render={(value, record) => <Highlight text={`${record.bottleSize} (${record.bottleSizeNumerical}ml)`} />}
          />
          <Table.Column<OfferHeader>
            key="quantity"
            title="Available bottles"
            dataIndex="quantity"
            sorter={(a, b) => a.quantity - b.quantity}
            render={(quantity, row) =>
              row.instock && row.instock.length > 0
                ? `${quantity} (${row.instock?.reduce((sum, next) => sum + next.available, 0)} from stock)`
                : quantity
            }
          />
          <Table.Column<OfferHeader>
            key="price"
            title="Price"
            dataIndex="price"
            sorter={(a, b) => a.priceAvg - b.priceAvg}
            render={(value, record) => {
              return (
                <>
                  <Row justify="start" align="middle">
                    <Col>
                      <Row>
                        <span>
                          <RiseOutlined style={{ color: 'green' }} />
                          {cc.ToSymbol(record.currency!)} {record.priceHigh}
                        </span>
                      </Row>
                      <Row>
                        <span>
                          <FallOutlined style={{ color: 'red' }} />
                          {cc.ToSymbol(record.currency!)} {record.priceLow}
                        </span>
                      </Row>
                    </Col>
                    <Col>
                      <span style={{ textAlign: 'left', fontSize: '18px', lineHeight: '40px', paddingLeft: '6px' }}>
                        {' '}
                        {cc.ToSymbol(record.currency!)} {record.priceAvg}{' '}
                        <span style={{ fontStyle: 'italic', fontSize: '12px' }}>AVG/BT</span>
                      </span>
                    </Col>
                  </Row>
                </>
              );
            }}
          />
          <Table.Column<OfferHeader>
            key="priceDiff"
            title="Price Difference"
            dataIndex="priceDiff"
            sorter={(a, b) => a.priceDiffPercent - b.priceDiffPercent}
            render={(value, record) => {
              return (
                <>
                  <Row justify="start">
                    <Col>
                      <Row>
                        <PlusOutlined />
                      </Row>
                      <Row>
                        <MinusOutlined />
                      </Row>
                    </Col>
                    <Col>
                      <span style={{ textAlign: 'left', fontSize: '18px', lineHeight: '40px', paddingLeft: '6px' }}>
                        {' '}
                        {cc.ToSymbol(record.currency!)} {record.priceDiff} ({record.priceDiffPercent}%){' '}
                        <span style={{ fontStyle: 'italic', fontSize: '12px' }}>AVG/BT</span>
                      </span>
                    </Col>
                  </Row>
                </>
              );
            }}
          />
          <Table.Column<OfferHeader>
            key="Margin"
            title="RW Stock Margin"
            dataIndex="margin"
            sorter={(a, b) => a.rwMarginAvg - b.rwMarginAvg}
            render={(value, record) => {
              const unknown = record.rwMarginAvg === -1 && record.rwMarginHigh === -1 && record.rwMarginLow === -1;
              const color = (n: number) => (unknown || n === 0 ? 'black' : n > 0 ? 'green' : 'red');

              return (
                <>
                  {unknown ? (
                    '-'
                  ) : (
                    <Row justify="start">
                      <Col>
                        <Row>
                          <span>
                            <RiseOutlined style={{ color: 'green' }} />
                            <span style={{ color: color(record.rwMarginHigh) }}>{record.rwMarginHigh}%</span>
                          </span>
                        </Row>
                        <Row>
                          <span>
                            <FallOutlined style={{ color: 'red' }} />
                            <span style={{ color: color(record.rwMarginLow) }}> {record.rwMarginLow}%</span>
                          </span>
                        </Row>
                      </Col>
                      <Col>
                        <span style={{ textAlign: 'left', fontSize: '18px', lineHeight: '40px', paddingLeft: '6px' }}>
                          <span style={{ color: color(record.rwMarginAvg) }}>{record.rwMarginAvg}</span>{' '}
                          <span style={{ fontStyle: 'italic', fontSize: '12px' }}>%</span>
                        </span>
                      </Col>
                    </Row>
                  )}
                </>
              );
            }}
          />
        </Table>
      </>
    );
  }, [filteredOffers, fullScreen]);

  return (
    <OverlayLoadingComponent loading={loadingCurrent !== loadingTotal}>
      <ContentArea showBackground>
        <Row justify="space-between">
          <Col style={{ flexGrow: 1 }}>
            <Searcher
              buttonsRight={[
                fullScreen ? (
                  <Tooltip title="Exit fullscreen">
                    <FullscreenExitOutlined
                      style={{ fontSize: 19 }}
                      className={Css.Packs.Clickable.Primary}
                      onClick={() => setFullScreen(false)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Fullscreen">
                    <FullscreenOutlined
                      style={{ fontSize: 19 }}
                      className={Css.Packs.Clickable.Primary}
                      onClick={() => setFullScreen(true)}
                    />
                  </Tooltip>
                ),
              ]}
              onSearchTerms={setSearchTerms}
            />
          </Col>
          <Col>
            <Button
              className={style.searchBar}
              type="primary"
              loading={loadingCurrent !== loadingTotal}
              onClick={() => masterListData.refetchOffers()}
            >
              {f({ id: 'tradingapp.masterlist.reload', defaultMessage: 'Reload' })}
            </Button>
          </Col>
        </Row>
        <Row>
          <Progress type="line" percent={Math.round((loadingCurrent * 100) / loadingTotal)} />
        </Row>
        {list}
      </ContentArea>
    </OverlayLoadingComponent>
  );
};

export default MasterList;
