import { Tooltip } from 'antd';
import { TooltipPropsWithTitle } from 'antd/lib/tooltip';
import { debounce } from 'lodash';
import React, { MutableRefObject, useCallback, useEffect, useReducer, useRef, useState } from 'react';

type TooltipTriggerFunction = MutableRefObject<() => void>;

export function useTriggerableTooltip(): TooltipTriggerFunction {
  const ref = useRef(() => {});
  return ref;
}

export default function TriggerableTooltip(
  props: React.PropsWithChildren<
    TooltipPropsWithTitle & {
      triggerDuration?: number;
      triggerFunction?: TooltipTriggerFunction;
    }
  >
) {
  // useReducer is more stable than useState, which comes in handy when debouncing
  const [forceVisisble, setForceVisible] = useReducer((state: boolean, input: boolean) => input, false);
  const [mouseOver, setMouseOver] = useState(false);

  const debouncedSetForceShowFalse = useCallback(
    debounce(() => {
      setForceVisible(false);
    }, props.triggerDuration ?? 2000),
    [props.triggerDuration]
  );

  useEffect(() => {
    if (props.triggerFunction) {
      props.triggerFunction.current = () => {
        setForceVisible(true);
        debouncedSetForceShowFalse();
      };
    }
  }, []);

  const show = props.visible || forceVisisble || mouseOver;

  return (
    <Tooltip {...props} visible={show}>
      <div style={{ display: 'inline-block' }} onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
        {props.children}
      </div>
    </Tooltip>
  );
}
