import { useApolloClient, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useEffect, useRef } from 'react';

import getData from './useGetApolloData';

interface Stats {
  totalValue: number;
  potentialEarnings: number;
  totalBottles: number;
  totalOffers: number;
  liters: number;
  oldestWine: number;
  split: number;
  quarter: number;
  clavelin_half: number;
  half: number;
  tenth: number;
  pinte: number;
  clavelin_full: number;
  rioja: number;
  lung_size: number;
  standard: number;
  cylinder: number;
  magnum: number;
  marie_jeanne: number;
  double_magnum: number;
  jerobaum: number;
  rehoboam: number;
  mckenzie: number;
  imperial: number;
  salmanazar: number;
  balthazar: number;
  nebuchadnezzar: number;
  melchior: number;
  primat: number;
  melchizedek: number;
  maximus: number;
}

const statsQuery = gql`
  query GetStats {
    stats @client {
      totalValue
      potentialEarnings
      totalBottles
      totalOffers
      liters
      oldestWine
      split
      quarter
      clavelin_half
      half
      tenth
      pinte
      clavelin_full
      rioja
      lung_size
      standard
      cylinder
      magnum
      marie_jeanne
      double_magnum
      jerobaum
      rehoboam
      mckenzie
      imperial
      salmanazar
      balthazar
      nebuchadnezzar
      melchior
      primat
      melchizedek
      maximus
    }
  }
`;

export function useStats() {
  const { data, loading, error } = useQuery<ApolloDataWrapper<Stats>>(statsQuery);
  return { data: getData(data), loading, error };
}

export function useWriteStats(offers: OfferHeader[]) {
  const client = useApolloClient();

  const totalValue = useRef(0);
  const potentialEarnings = useRef(0);
  const totalBottles = useRef(0);
  const totalOffers = useRef(0);
  const liters = useRef(0);
  const bottles: any = useRef({});
  const oldestWine = useRef(10000);

  useEffect(() => {
    offers.forEach(offer => {
      if (offer.year !== 0) {
        oldestWine.current = Math.min(oldestWine.current, offer.year);
      }
      totalValue.current += Math.round(offer.priceAvg * offer.quantity);
      liters.current += offer.quantity * (offer.bottleSizeNumerical / 1000);
      totalBottles.current += offer.quantity;
      if (bottles.current[offer.bottleSize.replace(' ', '_')] !== undefined) {
        bottles.current[offer.bottleSize.replace(' ', '_')] += offer.quantity;
      } else {
        bottles.current[offer.bottleSize.replace(' ', '_')] = offer.quantity;
      }

      potentialEarnings.current += offer.rwMarginAvg > 0 ? offer.priceAvg * offer.quantity * (offer.rwMarginAvg / 100) : 0;
      totalOffers.current += offer.totalOffers;
    });
  }, [offers]);

  const result = {
    totalValue: totalValue.current,
    potentialEarnings: potentialEarnings.current,
    totalBottles: totalBottles.current,
    totalOffers: totalOffers.current,
    oldestWine: oldestWine.current,
    liters: liters.current,
    ...bottles.current,
  };
  client.writeData({ data: { stats: { __typename: 'rwStats', ...result } } });
}
