/* eslint-disable */
// According to ts, string prototypes shouldn't be overwritten, therefore =>
declare global {
  interface String {
    toUpperFirst: () => string;
    asRandomHexColor: () => string;
    asRandomHslColor: (saturation?: number, light?: number) => string;
  }
}
String.prototype.toUpperFirst = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

function hashCode(str: String) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i: number) {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}

String.prototype.asRandomHexColor = function () {
  return intToRGB(hashCode(this));
};

String.prototype.asRandomHslColor = function stringToHslColor(saturation: number = 0.5, light: number = 0.8) {
  let hash = 0;
  for (let i = 0; i < this.length; i++) {
    hash = this.charCodeAt(i) + ((hash << 5) - hash);
  }

  var hue = hash % 360;

  return `hsl(${hue}, ${saturation * 100}%, ${light * 100}%)`;
};

export {};
