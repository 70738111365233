import { useCallback, useEffect, useReducer, useRef } from 'react';

function useStateDebounced<T>(intialValue: T, delay: number): [T, (newValue: T) => void, boolean] {
  const [state, setState] = useReducer((state: T, input: T) => input, intialValue);
  const [loading, setLoading] = useReducer((state: boolean, input: boolean) => input, false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const debouncedSetState = useCallback(
    (newValue: T) => {
      setLoading(true);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        setLoading(false);
        setState(newValue);
      }, delay);
    },
    [delay]
  );

  // stop action if component is unmounted
  useEffect(() => () => clearTimeout(timeoutRef.current!), []);

  return [state, debouncedSetState, loading];
}

export default useStateDebounced;
