import 'array-flat-polyfill';
import { useMemo } from 'react';
import { matchPath, useHistory } from 'react-router-dom';

import { useAuth0 } from '../helpers/useAuth0';
import AccountApp from './account-app/account-app';
import TradingApp from './trading-app/trading-app';
import WarehouseApp from './warehouse-app/warehouse-app';

const RegisteredApps: (AppInfo & { flatRoutes: RouteInfo[] })[] = [
  { ...TradingApp, flatRoutes: flattenRoutes(TradingApp.routes) },
  { ...WarehouseApp, flatRoutes: flattenRoutes(WarehouseApp.routes) },
  { ...AccountApp, flatRoutes: flattenRoutes(AccountApp.routes) },
];

function flattenRoutes(routes: RouteInfo[] | undefined) {
  if (!routes) return [];

  return routes.flatMap(route => {
    if (route.children) {
      return route.children.map(child => {
        return {
          ...child,
          path: route.path + '/' + child.path,
        };
      });
    }

    return [route];
  });
}

export default function useApps() {
  const { isAuthenticated, hasRoles } = useAuth0();
  const history = useHistory();

  const allowedApps = !isAuthenticated ? [] : RegisteredApps.filter(app => !app.requiredRoles || hasRoles(app.requiredRoles));

  const currentBaseRoute = history.location.pathname.split('/')[1];
  let currentApp = RegisteredApps.find(app => app.baseRoute === currentBaseRoute);

  if (isAuthenticated && !currentApp && allowedApps.length > 0) {
    history.push(allowedApps[0].baseRoute);
    currentApp = allowedApps[0];
  }

  let currentRoute = useMemo(() => {
    const route = currentApp?.flatRoutes.find(route => {
      const path = `/${currentApp!.baseRoute}/${route.path}`;
      const match = matchPath(history.location.pathname, {
        path: path,
        exact: true,
      });

      return !!match;
    });

    if (route) {
      document.title = `${currentApp?.name} - ${route.text}`;
    } else if (currentApp) {
      document.title = currentApp?.name;
    } else {
      document.title = 'RareWine Platform';
    }

    return route;
  }, [history.location.pathname, isAuthenticated]);

  return {
    currentApp: currentApp!,
    currentRoute: currentRoute,
    allowedApps: allowedApps,
  };
}
