import React from 'react';

import AccountOverview from './pages/account-overview/account-overview';

const AccountApp: AppInfo = {
  name: 'Account',
  baseRoute: 'account',
  hiddenFromSideMenu: true,
  routes: [
    {
      path: 'overview',
      text: 'Account Overview',
      render: () => <AccountOverview />,
    },
  ],
};

export default AccountApp;
