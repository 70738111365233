import {
  CalendarOutlined,
  PictureOutlined,
  ReloadOutlined,
  SettingOutlined,
  StockOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import React from 'react';

import TradingAppHeader from './components/trading-app-header/trading-app-header';
import InStock from './pages/instock/instock';
import LwinSync from './pages/lwin-sync/lwin-sync';
import MasterList from './pages/master-list/master-list';
import PictureList from './pages/picture-list/picture-list';
import Test from './pages/test/test';

import style from './trading-app.module.scss';

export const TradingApp: AppInfo = {
  name: 'Trading',
  requiredRoles: 'Trading',
  baseRoute: 'trading',
  className: style.app,
  headerBar: <TradingAppHeader />,
  routes: [
    {
      path: 'list',
      text: 'List',
      icon: <UnorderedListOutlined />,
      render: (props: any) => <MasterList {...props} />,
    },
    {
      path: 'instock',
      text: 'In Stock (Nav)',
      icon: <StockOutlined />,
      render: (props: any) => <InStock {...props} />,
    },
    {
      path: 'instock/:itemNumber/:variantNumber/:showImages',
      text: 'In Stock (Nav)',
      icon: <StockOutlined />,
      hideFromSidebar: true,
      render: (props: any) => <InStock {...props} />,
    },
    {
      path: 'pictures',
      text: 'Pictures',
      beta: true,
      icon: <PictureOutlined />,
      children: [
        {
          render: (props: any) => <PictureList {...props} />,
          path: 'recent',
          text: 'Recent',
          icon: <CalendarOutlined />,
        },
        {
          render: (props: any) => <PictureList {...props} />,
          path: 'recent/:pictureID',
          text: 'recent',
          hideFromSidebar: true,
        },
      ],
    },
    {
      path: 'developer',
      text: 'Dev tools',
      icon: <SettingOutlined />,
      requiredRoles: 'Developer',
      children: [
        {
          path: 'test',
          text: 'Test',
          icon: <SettingOutlined />,
          render: (props: any) => <Test {...props} />,
        },
        {
          path: 'sync_lwin',
          text: 'Synchronize Lwin',
          icon: <ReloadOutlined />,
          render: (props: any) => <LwinSync {...props} />,
        },
      ],
    },
  ],
};

export default TradingApp;
