import { Button, Col, Row, Select, Switch, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import Column from 'antd/lib/table/Column';
import React, { useState } from 'react';
import Highlighter from 'react-highlight-words';

import { OverlayLoadingComponent } from '../../../../components/overlay-loading/overlay-loading';
import WithModal from '../../../../helpers/with-modal';
import useLocalStorage from '../../../sharedHooks/useLocalStorage';
import usePurchaseOrders from '../../hooks/usePurchaseOrders';
import PurchaseOrderImageCarousel from './purchase-order-image-carousel';

import style from './purchase-orders.module.scss';

const statusDefinitions = [
  'Bestilt',
  'Afventer',
  'Leverandør_kontaktet',
  'Afhenting_igangsat',
  'Modtaget_ej_afstemt',
  'Delvist_modtaget',
  'Modtaget',
  'Eksternt_lager',
  'Kontakt_leverandør',
  'Purch_Inv_Header',
];

export default function PurchaseOrders() {
  const [statuses, setStatuses] = useLocalStorage<WarehouseStatus[]>('rw_warehouseStatus', ['Afventer', 'Modtaget_ej_afstemt']);
  const { data, loading } = usePurchaseOrders({ page: 0, pageSize: 1000, statuses: statuses });
  const [search, setSearch] = useState<string>('');
  const [onlyImages, setOnlyImages] = useState<boolean>(false);

  const Highlight = ({ text }: { text?: string }) => (
    <Highlighter
      highlightClassName={style.highlight}
      highlightTag="span"
      searchWords={[search!]}
      autoEscape={true}
      textToHighlight={text ?? ''}
    />
  );

  return (
    <WithModal>
      {modal => (
        <OverlayLoadingComponent loading={loading}>
          <Table<PurchaseOrderImageSet>
            title={() => (
              <Row>
                <Col style={{ marginRight: 16 }}>
                  <Row style={{ marginBottom: 8 }}>
                    <Col>
                      <span>Search</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Search value={search} onChange={e => setSearch(e.target.value)} />
                    </Col>
                  </Row>
                </Col>
                <Col style={{ marginRight: 16 }}>
                  <Row style={{ marginBottom: 8 }}>
                    <Col>
                      <span>Filter status</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Select
                        mode="multiple"
                        style={{ width: '200px' }}
                        placeholder="Please select"
                        value={statuses}
                        onChange={value => setStatuses(value as WarehouseStatus[])}
                      >
                        {statusDefinitions.map(x => (
                          <Select.Option key={x} value={x}>
                            {x.replace('Purch_Inv_Header', 'Bogført').replace(/_/g, ' ')}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row style={{ marginBottom: 8 }}>
                    <span>Only with images</span>
                  </Row>
                  <Row>
                    <Switch style={{ display: 'block' }} onChange={() => setOnlyImages(!onlyImages)} />
                  </Row>
                </Col>
              </Row>
            )}
            dataSource={data?.filter(
              x => (x.id.includes(search) || x.vendorName.includes(search)) && (onlyImages ? x.images.length > 0 : x)
            )}
            rowKey={row => row.id}
            pagination={{
              position: ['bottomRight', 'topRight'],
              pageSize: 10,
            }}
          >
            <Column<PurchaseOrderImageSet> key="id" dataIndex="id" title="Id" render={(_, row) => <Highlight text={row.id} />} />
            <Column<PurchaseOrderImageSet>
              key="status"
              dataIndex="status"
              title="Status"
              render={x => x?.replace('Purch_Inv_Header', 'Bogført').replace(/_/g, ' ') || ''}
            />
            <Column<PurchaseOrderImageSet> key="vendorName" dataIndex="vendorName" title="Vendor" />
            <Column<PurchaseOrderImageSet>
              key="imageCaseId"
              dataIndex="imageCaseId"
              title="Images"
              render={(_, data) => {
                if (data.images?.length > 0) {
                  return (
                    <>
                      <Button
                        type="link"
                        onClick={() => {
                          modal.info({
                            title: (
                              <>
                                <Row>
                                  <Col>
                                    <span>
                                      {data.id} - {data?.status.replace('Purch_Inv_Header', 'Bogført').replace(/_/g, ' ')}
                                    </span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <span>{data.vendorName}</span>
                                  </Col>
                                </Row>
                              </>
                            ),
                            desktopWidth: '50%',
                            maskClosable: true,
                            content: (
                              <>
                                <PurchaseOrderImageCarousel purchaseOrderHeader={data} width={700} />
                              </>
                            ),
                          });
                        }}
                      >
                        Show images
                      </Button>
                    </>
                  );
                }

                return '-';
              }}
            />
          </Table>
        </OverlayLoadingComponent>
      )}
    </WithModal>
  );
}
