import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import getData from '../general/useGetApolloData';

const mutation = gql`
  mutation DisableCase($input: DisableCaseArgs!) {
    disableCase(input: $input) @rest(type: "DisableCase", path: "warehouse/disableCase", method: "POST") {
      success
    }
  }
`;

export function useDisableCase() {
  const [disable, { loading, error, data }] = useMutation<
    ApolloDataWrapper<{ success: boolean }>,
    ApolloInput<{ imageRequestId: string[] }>
  >(mutation);

  return { disable, loading, error, data: getData(data) };
}
