import { EyeOutlined, EyeTwoTone } from '@ant-design/icons';
import { Avatar, Button, List } from 'antd';
import React, { useState } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

import style from '../notifications.module.scss';

type Params = {
  agentId: string;
  agentName: string;
  offerlineId: string;
  offerlineOfferId: string;
  offerlineOriginalOfferText: string;
  offerlineSupplierName: string;
  offerlineSupplierEmail: string;
  offerlineSupplierId: string;
  offerlineLinkedWine: string;
  offerlineLinkedWineLwin: string;
  offerlineProducer: string;
  offerlineWineName: string;
  offerlineYear: number;
  offerlineRegion: string;
  offerlineSubRegion: string;
  offerlineColour: string;
  offerlineType: string;
  offerlineBottleSize: number;
  offerlinePrice: number;
  offerlineCurrency: string;
  offerlineQuantity: number;
  offerlineBottlesPerCase: number;
  offerlinePackaging: string;
};

export default function AgentMatchNotification(props: { notification: RWNotification }) {
  const [moused, setMoused] = useState(false);
  const params = props.notification.parameters as Params;

  return (
    <div onMouseEnter={x => !moused && setMoused(true)} onMouseLeave={x => moused && setMoused(false)}>
      <List.Item.Meta
        className={`${props.notification.isRead ? '' : style.unread} ${style.notificationItem}`}
        avatar={
          <Avatar
            style={moused ? { backgroundColor: 'green' } : {}}
            icon={moused ? <EyeTwoTone twoToneColor="lightgreen" /> : <EyeOutlined />}
          />
        }
        title={<Button type="link">{params.agentName}</Button>}
        description={
          <>
            {params.offerlineSupplierName} ({params.offerlineSupplierEmail})
            <br />
            {params.offerlineSubRegion} {params.offerlineProducer} {params.offerlineWineName} {params.offerlineColour}{' '}
            {params.offerlineType} {params.offerlineYear} {params.offerlinePackaging}
            <br />
            {Math.floor(params.offerlineQuantity / params.offerlineBottlesPerCase)}x{params.offerlineBottlesPerCase}{' '}
            {params.offerlineQuantity % params.offerlineBottlesPerCase > 0
              ? ` (+${params.offerlineQuantity % params.offerlineBottlesPerCase}) `
              : ''}
            {params.offerlineBottleSize}ml @ {`${params.offerlineCurrency}${params.offerlinePrice}`}
            <span className={style.date}>
              <i>
                <FormattedDate value={props.notification.createdAt.date} /> <FormattedTime value={props.notification.createdAt.date} />
              </i>
            </span>
          </>
        }
      />
    </div>
  );
}
