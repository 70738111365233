import { Card, Col, Row, Typography } from 'antd';
import React from 'react';

import { OverlayLoadingComponent } from '../../../../components/overlay-loading/overlay-loading';
import UserAvatar from '../../../../components/user-avatar/user-avatar';
import useUser from '../../../trading-app/hooks/user/useUser';

const { Text } = Typography;

export default function AccountOverview() {
  const { data, loading } = useUser();

  return (
    <OverlayLoadingComponent loading={loading}>
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col xs={24} lg={8}>
          <Row justify="center">
            <UserAvatar email={data?.email} size={180} />
          </Row>
        </Col>
        <Col xs={24} lg={16}>
          <Card>
            {Object.entries(data!)
              .filter(userProp => !userProp[0].startsWith('__'))
              .map(prop => (
                <Row key={prop[0]} justify="start">
                  <Col span={4}>
                    <Text strong>{makeReadable(prop[0])}</Text>
                  </Col>
                  <Col>
                    <Text>{prop[1]}</Text>
                  </Col>
                </Row>
              ))}
          </Card>
        </Col>
      </Row>
    </OverlayLoadingComponent>
  );
}

function makeReadable(propertyName: string) {
  const upperFirst = propertyName.charAt(0).toUpperCase() + propertyName.slice(1);
  return upperFirst.replace(/([a-z])([A-Z])/g, '$1 $2');
}
