import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, notification, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import style from './refresh-reminder.module.scss';

const WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000;
const TEN_MINUTES_IN_MS = 10 * 60 * 1000;

export function RefreshReminder() {
  const [show, setShow] = useState(false);
  const [isTimerStarted, setIsTimerStarted] = useState(false);
  const isDesktop = useBreakpoint().lg;

  useEffect(() => {
    const NOTIFICATION_KEY = 'refresh-reminder';
    const TIMESTAMP_FOR_SHOW_REMINDER_IN_MS = dayjs().add(WEEK_IN_MS, 'millisecond');

    if (!isTimerStarted) {
      setIsTimerStarted(true);
      setInterval(() => {
        if (dayjs().isAfter(TIMESTAMP_FOR_SHOW_REMINDER_IN_MS.add(-100))) {
          setShow(true);
        }
      }, TEN_MINUTES_IN_MS);
    }

    if (show && isDesktop) {
      notification.open({
        closeIcon: <></>,
        key: NOTIFICATION_KEY,
        className: style.refreshReminder,
        message: (
          <Row className={style.container}>
            <Col span={2}>
              <ExclamationCircleOutlined className={style.exclamationCircle} />
            </Col>
            <Col span={19}>
              <Row className={style.header}>Please remember to refresh your browser</Row>
              <Row className={style.description}>
                To make sure you can see the continous updates to our system (Mac: ⌘ + R, Windows: F5).
              </Row>
            </Col>
            <Col span={3}>
              <Button
                className={style.refreshButton}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh
              </Button>
            </Col>
            <Col span={2}>
              <CloseOutlined
                style={{ color: '#000000' }}
                onClick={() => {
                  notification.close(NOTIFICATION_KEY);
                }}
              />
            </Col>
          </Row>
        ),
        duration: 0,
        placement: 'bottomRight',
      });
    }
  }, [isDesktop, show, isTimerStarted]);
  return null;
}
