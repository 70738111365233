import { DeleteOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Button, Col, Divider, Form, Input, Modal, Row, Select, Upload } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import TextArea from 'antd/lib/input/TextArea';
import React, { CSSProperties, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import Countries from '../../helpers/CountryHelper';
import Css from '../../helpers/css/Css';
import { isEmail } from '../../helpers/validation';
import '../../rarewine-library-frontend/extensions/ArrayExtensions';
import useZip from '../hooks/useZip';
import PictureViewer from './components/pictureViewer';
import RequestWineForm from './components/requestWineForm';
import useFetchNoAuth from './hooks/useFetchNoAuth';
import { StockUser, StockWine } from './types';

import style from './stock-list.module.scss';

const STOCKLIST_BLOBSTORAGE_URL = `${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-public/stocklist`;
const DEFAULT_MODAL_WIDTH = 1000;

export function WineImageModal(props: {
  wine: StockWine | undefined;
  onClose: () => void;
  visible: boolean;
  setUser: Dispatch<SetStateAction<StockUser>>;
  applicationInsights: ApplicationInsights | null;
  user: StockUser;
}) {
  const { wine, visible, onClose, applicationInsights, user } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const zip = useZip();

  async function downloadPictures() {
    for (let index = 0; index < wine!.Pictures.length; index++) {
      const element = wine!.Pictures[index];
      const response = await fetch(
        `${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-warehouse/image-product-maps/${wine!.FolderId}/${element}.jpg`
      );
      const blob = await response.blob();

      //No reason to send GUID, so we rename the pictures
      zip.add(`Picture-${index + 1}.jpg`, blob);
    }
    zip.download(`${wine!.Producer} ${wine!.Wine} ${wine!.Year}`);
    applicationInsights?.trackEvent(
      { name: 'Stocklist download-picture' },
      { user: user, wine: { wineName: wine!.Name, volume: wine!.Volume, year: wine!.Year, variant: wine!.Variant, id: wine!.Id } }
    );
  }

  return (
    <Modal
      title={`Images for ${wine?.Producer} ${wine?.Wine} ${wine?.Year}`}
      visible={visible}
      onOk={onClose}
      okText="Close"
      footer={[
        <Button
          loading={loading}
          icon={<DownloadOutlined />}
          onClick={() => {
            setLoading(true);
            downloadPictures().finally(() => setLoading(false));
          }}
        >
          Download all pictures
        </Button>,
        <Button onClick={onClose}>Close</Button>,
      ]}
      onCancel={onClose}
      width={900}
    >
      <PictureViewer row={wine!} />
    </Modal>
  );
}

export function InformationGateModal(props: {
  visible: boolean;
  countryString: string;
  setCountryString: (s: string) => void;
  onClose: () => void;
  onOk: () => void;
  setUser: Dispatch<SetStateAction<StockUser>>;
  applicationInsights: ApplicationInsights | null;
}) {
  const { countryString, setCountryString, onClose, onOk, setUser, applicationInsights } = props;
  const [formInformationGate] = Form.useForm();
  const { fetch: fetchInformationGate } = useFetchNoAuth('nav/upsertPictureLurker');

  const fillInformationGateForm = (values: Store) => {
    const Offset = new Date().getTimezoneOffset();
    const returnObject = Object.assign({}, values, { CountryCode: Countries.lookup(countryString).CountryCodes[0], Offset: Offset });
    fetchInformationGate({ method: 'POST', body: JSON.stringify(returnObject) });
    const formAutoFill = Object.assign(
      {},
      { Email: values['Email'], PersonName: values['PersonName'], Nationality: countryString, PhoneNumber: values['PhoneNumber'] }
    );
    setUser(values as StockUser);
    localStorage.setItem('form', JSON.stringify(formAutoFill));

    //Tracks when a new user is entered into the system
    applicationInsights?.trackEvent({ name: 'Stocklist user' }, { user: values });
  };

  return (
    <StockListModal defaultWidth={DEFAULT_MODAL_WIDTH} media={`${STOCKLIST_BLOBSTORAGE_URL}/BottlePicture.jpg`} {...props}>
      <Col flex={1}>
        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
          <h3 style={{ textAlign: 'center', paddingTop: '10%' }}>
            Please enter some information about yourself to browse our entire photo archive
          </h3>
          <Divider></Divider>
          <div style={{ paddingTop: '5%' }}>
            <Form
              name="Basic"
              form={formInformationGate}
              labelAlign="left"
              layout="horizontal"
              onFinish={values => {
                formInformationGate.validateFields().then(values => {
                  fillInformationGateForm(values);
                  onClose();
                  onOk();
                });
              }}
            >
              <Form.Item name="PersonName" rules={[{ required: true, message: 'Please enter a valid name' }]}>
                <Input placeholder="Name" />
              </Form.Item>

              <Form.Item name="Email" rules={[{ required: true, message: 'Please enter a valid email', type: 'email' }]}>
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item name="Nationality" rules={[{ required: true }]}>
                <Select
                  showSearch
                  filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  dropdownMatchSelectWidth={300}
                  placeholder="Select nationality"
                  onChange={setCountryString}
                >
                  {Countries.array.map(c => (
                    <Select.Option key={c.CountryValue} value={c.CountryValue}>
                      {c.Name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="PhoneNumber" rules={[{ required: true, message: 'This is not a valid phone number' }]}>
                <Input placeholder="Phone number" addonBefore={<>{Countries.lookup(countryString).CountryCodes}</>} size="middle" />
              </Form.Item>

              <div style={{ paddingTop: '5%' }}>
                <Button htmlType="submit" block type="primary">
                  Submit!
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Col>
    </StockListModal>
  );
}

interface StocklistExample {
  companyName: string;
  expectedVolume: string;
  stocklistRow: string;
  stocklistFile: File;
}

export function ProvideStockList(props: { onClose: () => void; visible: boolean; applicationInsights: ApplicationInsights | null }) {
  const [email, setEmail] = useState<string>('');
  const [isInterested, setIsInterested] = useState<boolean>(false);
  const { fetch: fetchWithEmail } = useFetchNoAuth('stocklist/notify/stocklist-provider');
  const [stocklistFile, setStocklistFile] = useState<File>();
  const [stocklistRow, setStocklistRow] = useState<string>('');
  const [isStocklist, setIsStocklist] = useState<boolean>(false);

  const sendEmail = useCallback(() => {
    props.applicationInsights?.trackEvent({ name: 'Stocklist provider' }, { email: email });
    localStorage.setItem('stocklist-provider', JSON.stringify(email));
    fetchWithEmail({ method: 'POST', body: JSON.stringify(email) });
  }, [email]);

  const sendStocklistProvided = useCallback(
    (form: Store) => {
      props.applicationInsights?.trackEvent({ name: 'Stocklist sent' }, { email: email });

      var returnObject = form as StocklistExample;

      var request = new XMLHttpRequest();
      var formData = new FormData();

      formData.append('companyName', returnObject.companyName);
      formData.append('expectedVolume', returnObject.expectedVolume);
      formData.append('email', email);

      if (stocklistFile) {
        formData.append('stocklistFile', stocklistFile);
      } else if (stocklistRow) {
        formData.append('stocklistRow', stocklistRow);
      }
      request.open('POST', `${window.ENVARGS.REACT_APP_RAREWINE_API_URL}stocklist/upload/stocklist-example`);
      request.send(formData);

      props.onClose();
    },
    [email, props.applicationInsights, stocklistFile, stocklistRow]
  );

  const [formStocklistProvider] = Form.useForm();

  const isDesktop = useBreakpoint().md;
  const textPadding = isDesktop ? 32 : 16;

  if (!isInterested) {
    return (
      <StockListModal
        defaultWidth={DEFAULT_MODAL_WIDTH}
        media={`${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-public/stocklist/FactoryPicture.jpg`}
        modalWindowsStyle={{ background: '#68140f' }}
        {...props}
      >
        <Col flex={1}>
          <Row>
            <img
              src={`${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-public/stocklist/RareWineTrading_Logo_Cirkel_300x300.png`}
              alt="RareWine Logo"
              className={style.logomodal}
              style={{ height: isDesktop ? '70%' : '150px', marginBottom: 16 }}
            ></img>
          </Row>
          <Row>
            <div style={{ textAlign: 'center', paddingLeft: textPadding, paddingRight: textPadding, color: 'white' }}>
              <h3 style={{ color: 'white' }}>RareWine is constantly looking for new suppliers</h3>
              If you are interested in supplying your stocklist directly to us or automate it without the hassle of sending emails, please
              get in touch with us below
            </div>

            <div style={{ width: '100%', paddingRight: textPadding, paddingLeft: textPadding, paddingTop: '32px' }}>
              <Input
                placeholder="Email"
                onChange={e => setEmail(e.target.value)}
                addonBefore={<div>Email</div>}
                style={{ width: '100%' }}
              ></Input>
              <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '8px' }}>
                <Button
                  onClick={_ => {
                    setIsInterested(true);
                  }}
                  disabled={!isEmail(email)}
                  style={{ width: '100%', marginBottom: textPadding }}
                >
                  Get in touch!
                </Button>
              </div>
            </div>
          </Row>
        </Col>
      </StockListModal>
    );
  } else {
    return (
      <StockListModal
        defaultWidth={DEFAULT_MODAL_WIDTH}
        media={`${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-public/stocklist/FactoryPicture.jpg`}
        textColStyle={{ height: '100%' }}
        modalWindowsStyle={{ background: '#f8f8f8' }}
        afterClose={() => {
          if (!isStocklist) {
            sendEmail();
          }
        }}
        {...props}
      >
        <StocklistProvider
          textPadding={textPadding}
          stocklistRow={stocklistRow}
          stocklistFile={stocklistFile}
          setStocklistRow={setStocklistRow}
          setStocklistFile={setStocklistFile}
          setIsStocklist={setIsStocklist}
          formStocklistProvider={formStocklistProvider}
          sendStocklistProvided={sendStocklistProvided}
          onClose={props.onClose}
        />
      </StockListModal>
    );
  }
}

export function StocklistProvider(props: {
  textPadding: number;
  stocklistRow: string;
  stocklistFile: File | undefined;
  setStocklistRow: React.Dispatch<React.SetStateAction<string>>;
  setStocklistFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  setIsStocklist: React.Dispatch<React.SetStateAction<boolean>>;
  formStocklistProvider: FormInstance;
  sendStocklistProvided: (form: Store) => void;
  onClose: () => void;
}) {
  return (
    <Col flex={1} style={{ height: '100%' }}>
      <div style={{ width: '100%', paddingRight: props.textPadding, paddingLeft: props.textPadding, paddingTop: '32px', height: '100%' }}>
        <Form
          layout="vertical"
          style={{ height: '100%' }}
          onFinish={e => {
            props.formStocklistProvider.validateFields().then(_ => props.sendStocklistProvided(e));
          }}
          form={props.formStocklistProvider}
        >
          <h2 style={{ textAlign: 'center', paddingBottom: props.textPadding }}>We are excited to work with you!</h2>
          <Form.Item
            name={['companyName']}
            required={true}
            rules={[{ required: true, message: 'Please enter the name of your company' }]}
            label="Company name"
          >
            <Input />
          </Form.Item>
          <div style={{ paddingTop: props.textPadding, paddingBottom: props.textPadding }}>
            <div>
              <p>We would like to see an example of your stocklist, you can either provide it in a file</p>
            </div>
            <Form.Item
              required={true}
              name="stocklist"
              rules={[
                _ => ({
                  validator(_, e) {
                    if (props.stocklistRow !== '' && props.stocklistFile)
                      return Promise.reject(new Error('Please only upload a file or enter a row'));
                    if (props.stocklistRow || props.stocklistFile) return Promise.resolve();
                    return Promise.reject(new Error('Please upload either a file or enter a row'));
                  },
                }),
              ]}
              label="Example stocklist"
            >
              <>
                <Upload
                  multiple={false}
                  beforeUpload={file => {
                    props.setStocklistFile(file);
                    return false;
                  }}
                  showUploadList={false}
                >
                  <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
                </Upload>
                {props.stocklistFile?.name && (
                  <div style={{ paddingBottom: props.textPadding }}>
                    {props.stocklistFile?.name}{' '}
                    <DeleteOutlined className={Css.Packs.Clickable.Success} onClick={() => props.setStocklistFile(undefined)} />
                  </div>
                )}
                <div>
                  <p>Or enter one row of your example stocklist in the box below</p>
                </div>

                <TextArea onChange={e => props.setStocklistRow(e.target.value)} autoSize={{ minRows: 3, maxRows: 5 }} />
              </>
            </Form.Item>
          </div>
          <Form.Item
            initialValue="<€10.000"
            required
            name={['expectedVolume']}
            rules={[{ required: true }]}
            label="Trading volume per month"
          >
            <Select>
              <Select.Option value="<€10.000">{'<€10.000'}</Select.Option>
              <Select.Option value="€10.000 - €30.000">{'€10.000 - €30.000'}</Select.Option>
              <Select.Option value="€50.000 - €100.000">{'€50.000 - €100.000'}</Select.Option>
              <Select.Option value=">€100.000">{'>€100.000'}</Select.Option>
            </Select>
          </Form.Item>
          <div>
            <Button onClick={() => props.setIsStocklist(true)} htmlType="submit" type="primary" style={{ width: '50%' }}>
              Send!
            </Button>
            <Button style={{ width: '50%' }} onClick={props.onClose}>
              Not right now
            </Button>
          </div>
        </Form>
      </div>
    </Col>
  );
}

export function StockListModal(props: {
  media: string;
  children: JSX.Element;
  defaultWidth: number;
  onClose: () => void;
  visible: boolean;
  applicationInsights: ApplicationInsights | null;
  textColStyle?: CSSProperties;
  modalWindowsStyle?: CSSProperties;
  afterClose?: () => void;
  destroyOnClose?: boolean;
}) {
  const isDesktop = useBreakpoint().md;

  return (
    <Modal
      width={getModalWidth(props.defaultWidth)}
      visible={props.visible}
      onCancel={props.onClose}
      onOk={props.onClose}
      footer={null}
      afterClose={props.afterClose}
      bodyStyle={{ padding: 0 }}
    >
      <Row style={props.modalWindowsStyle}>
        <Col span={isDesktop ? 12 : 0}>
          <div style={{ height: '100%' }}>
            <img style={{ width: '100%', height: '750px', objectFit: 'cover' }} src={props.media} alt="Modal media"></img>
          </div>
        </Col>
        <Col span={isDesktop ? 12 : 24} style={props.textColStyle}>
          <Row align="middle" style={{ height: '750px' }}>
            {props.children}
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}

export function RequestNewWineModal(props: {
  onClose: () => void;
  visible: boolean;
  countryString: string;
  setCountryString: (s: string) => void;
  setUser: Dispatch<SetStateAction<StockUser>>;
  applicationInsights: ApplicationInsights | null;
}) {
  const { visible, onClose, countryString, setCountryString, setUser, applicationInsights } = props;
  const { fetch: fetchWine } = useFetchNoAuth('nav/upsertWineRequest');
  const [formRequestWine] = Form.useForm();
  useEffect(() => {
    const existingForm = localStorage.getItem('form');
    if (existingForm) {
      formRequestWine.setFieldsValue(JSON.parse(existingForm));
    }
  }, [formRequestWine]);

  const fillRequestWineForm = (values: Store) => {
    const Offset = new Date().getTimezoneOffset();
    const returnObject = Object.assign({}, values, { CountryCode: Countries.lookup(countryString).CountryCodes[0], Offset: Offset });
    fetchWine({ method: 'POST', body: JSON.stringify(returnObject) });
    const formAutoFill = Object.assign(
      {},
      { Email: values['Email'], PersonName: values['PersonName'], Nationality: countryString, PhoneNumber: values['PhoneNumber'] }
    );
    localStorage.setItem('form', JSON.stringify(formAutoFill));
    setUser(values as StockUser);
    //Tracks when a new wineRequest is entered into the system
    applicationInsights?.trackEvent({ name: 'Stocklist wine-request' }, { user: formAutoFill, request: returnObject });

    onClose();
  };

  const modalWidth = 1200;

  return (
    <Modal
      title="Request a new wine"
      visible={visible}
      onCancel={onClose}
      onOk={() => {
        formRequestWine.validateFields().then(values => {
          formRequestWine.resetFields(['WineDescription']);
          fillRequestWineForm(values);
        });
      }}
      width={modalWidth}
    >
      <RequestWineForm form={formRequestWine} countryString={countryString} setCountryString={setCountryString} />
    </Modal>
  );
}

export function RequestWineImageModal(props: {
  wine?: StockWine;
  visible: boolean;
  onClose: () => void;
  countryString: string;
  setCountryString: (s: string) => void;
  setUser: Dispatch<SetStateAction<StockUser>>;
}) {
  const { wine, visible, onClose, countryString, setCountryString, setUser } = props;
  const [formRequestPicture] = Form.useForm();
  const { fetch: fetchImage } = useFetchNoAuth('nav/upsertImageRequest');

  useEffect(() => {
    const existingForm = localStorage.getItem('form');
    if (existingForm) {
      formRequestPicture.setFieldsValue(JSON.parse(existingForm));
    }
  }, [formRequestPicture]);

  const fillRequestImageForm = (values: Store) => {
    const Offset = new Date().getTimezoneOffset();
    const imageRequestInfo = Object.assign({}, values, {
      wineID: wine?.Id,
      Variant: wine?.Variant,
      Offset: Offset,
      CountryCode: Countries.lookup(countryString).CountryCodes[0],
    });
    fetchImage({ method: 'POST', body: JSON.stringify(imageRequestInfo) });
    localStorage.setItem('form', JSON.stringify(values));
    setUser(values as StockUser);
    onClose();
  };

  return (
    <Modal
      title={`Requesting image for ${wine?.Producer} ${wine?.Wine} ${wine?.Year}`}
      visible={visible}
      okText="Send request"
      width={900}
      onOk={() => {
        formRequestPicture.validateFields().then(values => {
          formRequestPicture.resetFields();
          fillRequestImageForm(values);
        });
      }}
      onCancel={onClose}
    >
      <Form name="basic" form={formRequestPicture} labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} layout="horizontal">
        <Form.Item label="Name" name="PersonName" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Email" name="Email" rules={[{ required: true, message: 'Please enter a valid email', type: 'email' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Nationality" name="Nationality" rules={[{ required: true }]}>
          <Select
            showSearch
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            style={{ width: 207 }}
            dropdownMatchSelectWidth={300}
            placeholder="Select nationality"
            onChange={setCountryString}
          >
            {Countries.array.map(c => (
              <Select.Option key={c.CountryValue} value={c.CountryValue}>
                {c.Name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Phone number" name="PhoneNumber" rules={[{ required: true, message: 'This is not a valid phone number' }]}>
          <Input addonBefore={<>{Countries.lookup(countryString).CountryCodes}</>} size="middle" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

const getModalWidth = (defaultSize: number, cropMargin: number = 16) =>
  window.screen.width < defaultSize ? window.screen.width - cropMargin : defaultSize;
