import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Select } from 'antd';
import Button from 'antd/lib/button';
import { FormInstance } from 'antd/lib/form';
import { Col, Row } from 'antd/lib/grid';
import React, { useMemo, useState } from 'react';

import useDebounce from '../../../apps/trading-app/hooks/general/useDebounce';
import Countries from '../../../helpers/CountryHelper';
import useBlobFetch from '../hooks/useBlobFetch';

type LwinWine = {
  Lwin: string;
  DisplayName: string;
};

export default function RequestWineForm(props: { form: FormInstance; countryString: string; setCountryString: (s: string) => void }) {
  const { countryString, setCountryString } = props;
  const [searchString, setSearchString] = useState<string>('');
  const { data: wineData } = useBlobFetch<LwinWine[]>('rare-wine-public/stocklist/wines.json');
  const debouncedSearchString = useDebounce(searchString, 500);

  const wineOptions = useMemo(() => {
    return (
      wineData?.map(e => {
        return {
          label: e.DisplayName.trim(),
          value: [e?.DisplayName ?? 'no value', e.Lwin],
        } as any;
      }) ?? []
    );
  }, [wineData]);

  const filterWineOptions = useMemo(() => {
    const filteredData = wineOptions.filter(e => {
      const serialized = e.label.toLowerCase();
      const terms = debouncedSearchString.split(' ');
      return terms.every(t => serialized.includes(t.toLowerCase()));
    });
    return filteredData;
  }, [debouncedSearchString, wineOptions]);

  return (
    <Form name="Basic" form={props.form} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} layout="horizontal">
      <Form.List
        name="WineInformation"
        rules={[
          {
            validator: async (_, wines) => {
              if (!wines || wines.length < 1) {
                return Promise.reject(new Error('Add atleast 1 wine'));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            <Row style={{ marginBottom: 10 }}>
              <Col offset={4} span={18}>
                <Button
                  style={{ width: '100%' }}
                  type="primary"
                  onClick={() => {
                    add();
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Add wine
                </Button>
              </Col>
            </Row>
            {fields.map((field, index) => (
              <Form.Item key={field.key} label="Wine" required style={{ marginBottom: '0px' }}>
                <Form.Item
                  fieldKey={[field.fieldKey, 'amount']}
                  name={[field.name, 'Amount']}
                  required
                  style={{ display: 'inline-block', width: '10%', marginRight: '10px' }}
                  rules={[{ required: true, message: 'required' }]}
                >
                  <InputNumber placeholder="Amount" />
                </Form.Item>
                <Form.Item
                  required
                  style={{ display: 'inline-block', width: '65%', marginRight: '10px' }}
                  fieldKey={[field.fieldKey, 'wineName']}
                  name={[field.name, 'WineName']}
                  rules={[{ required: true, message: 'required' }]}
                >
                  <Select
                    options={filterWineOptions}
                    filterOption={false}
                    onSearch={e => {
                      setSearchString(e);
                    }}
                    showSearch={true}
                    placeholder="Wine name"
                  />
                </Form.Item>

                <Form.Item
                  required
                  rules={[{ required: true, message: 'required' }]}
                  style={{ display: 'inline-block', width: '10%' }}
                  name={[field.name, 'Vintage']}
                  fieldKey={[field.fieldKey, 'Vintage']}
                >
                  <Input placeholder="Vintage" />
                </Form.Item>

                <Button
                  style={{ justifyContent: 'center', marginLeft: '10px' }}
                  shape="circle"
                  icon={<MinusCircleOutlined />}
                  onClick={() => remove(field.name)}
                ></Button>
              </Form.Item>
            ))}
            <Row style={{ width: '100%' }} justify="center">
              <Form.ErrorList errors={errors} />
            </Row>
          </>
        )}
      </Form.List>

      <Form.Item label="Name" name="PersonName" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Email" name="Email" rules={[{ required: true, message: 'Please enter a valid email', type: 'email' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Nationality" name="Nationality" rules={[{ required: true }]}>
        <Select
          showSearch
          filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          style={{ width: 207 }}
          dropdownMatchSelectWidth={300}
          placeholder="Select nationality"
          onChange={setCountryString}
        >
          {Countries.array.map(c => (
            <Select.Option key={c.CountryValue} value={c.CountryValue}>
              {c.Name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Phone number" name="PhoneNumber" rules={[{ required: true, message: 'This is not a valid phone number' }]}>
        <Input addonBefore={<>{Countries.lookup(countryString).CountryCodes}</>} size="middle" />
      </Form.Item>
    </Form>
  );
}
