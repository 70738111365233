import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import React, { ComponentType, useEffect, useState } from 'react';

import useApps from '../../../apps';
import useUserAuth from '../../hooks/user/useUserAuth';

type AIContextArgs = {
  applicationInsights: ApplicationInsights | null;
};

export const ApplicationInsightsContext = React.createContext<AIContextArgs>({
  applicationInsights: null,
});

const reactPlugin = new ReactPlugin();

const ApplicationInsightsProvider: React.FunctionComponent = props => {
  const { currentRoute } = useApps();

  const [applicationInsights] = useState<ApplicationInsights>(
    (() => {
      let ai = new ApplicationInsights({
        config: {
          instrumentationKey: window.ENVARGS.REACT_APP_AI_INSTRUMENTATION_KEY,
          autoTrackPageVisitTime: true,
        },
      });

      return ai;
    })()
  );

  const user = useUserAuth();

  useEffect(() => {
    applicationInsights.loadAppInsights();

    // discard telemetry from before login
    applicationInsights.addTelemetryInitializer(item => !!user);

    applicationInsights.addTelemetryInitializer(item => {
      let editableItem = item as any;

      let path = '',
        name = '';
      switch (item.baseType) {
        case 'MetricData':
          if (editableItem.data.PageUrl) {
            path = editableItem.data.PageUrl;

            if (path.startsWith('http')) {
              // uri is full, remove the https:// stuff and extract only the path
              path = path.slice(path.indexOf('/', 8));
            }

            name = path.endsWith('/callback') ? 'Rare Wine - Login' : `Rare Wine - ${currentRoute?.text}`;
            editableItem.data.PageName = name;
          }
          break;

        case 'PageviewData':
        case 'PageviewPerformanceData':
          if (editableItem.baseData.uri) {
            path = editableItem.baseData.uri;

            if (path.startsWith('http')) {
              // uri is full, remove the https:// stuff and extract only the path
              path = path.slice(path.indexOf('/', 8));
            }

            name = path.endsWith('/callback') ? 'Rare Wine - Login' : `Rare Wine - ${currentRoute?.text}`;
            editableItem.baseData.name = name;
          }
          break;
      }
    });

    applicationInsights.addTelemetryInitializer(item => {
      if (process.env.NODE_ENV !== 'production') {
        // if we're not in production, log to console instead of to App Insights
        // eslint-disable-next-line
        console.debug('AppInsights.' + item.baseType, item);

        return false;
      }

      return true;
    });
  }, []);

  useEffect(() => {
    if (user) {
      applicationInsights.setAuthenticatedUserContext(user.email, user.email, true);
    } else {
      applicationInsights.clearAuthenticatedUserContext();
    }
  }, [user]);

  return (
    <ApplicationInsightsContext.Provider value={{ applicationInsights: applicationInsights }}>
      {props.children}
    </ApplicationInsightsContext.Provider>
  );
};

export const trackComponent = (component: ComponentType<unknown>, name: string) => withAITracking(reactPlugin, component, name);
export default ApplicationInsightsProvider;
