import { Button, Checkbox, Col, Input, Popconfirm, Row } from 'antd';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import React, { useState } from 'react';

import useUser from '../../../hooks/user/useUser';
import { useRequestNewWineImages } from '../../../hooks/wine/useStockWineImages';

import style from './request-pictures.module.scss';

const { TextArea } = Input;

export default function RequestPictures(props: {
  availableSalesOrders: number;
  barcodes: Barcode[];
  variants: string[];
  wineName: string;
  wineVintage: number;
  onSentRequest?: () => void;
}) {
  const [message, setMessage] = useState<string>('');
  const [includeImagesInEmail, setIncludeImagesInEmail] = useState(false);

  const [checkedList, setCheckedList] = useState<string[]>([...props.barcodes.map(x => x.original)].filter(x => !!x));

  const [variantCheckedList, setVariantCheckedList] = useState<string[]>([props.barcodes[0].variantNumber!]);

  const { data } = useUser();
  const { mutate, loading } = useRequestNewWineImages({});

  const toggleVariantCheckbox = (variant: string) => {
    if (variantCheckedList.find(entry => entry === variant) !== undefined) {
      setVariantCheckedList(variantCheckedList.filter(x => x !== variant));
    } else {
      setVariantCheckedList([...variantCheckedList, variant]);
    }
  };

  const canSend = props.barcodes.length > 1 ? checkedList.length === 0 && message.length === 0 : message.length === 0;

  const requestPicture = () => {
    mutate({
      variables: {
        input: {
          author: `${data?.firstName} ${data?.lastName}`,
          authorId: `${data?.id}`,
          message: `${props.wineName} ${props.wineVintage}: ${message}`,
          includeImagesInEmail: includeImagesInEmail,
          disabled: false,
          productIds: [...checkedList, ...variantCheckedList.map(x => `91${props.barcodes[0].itemNumber}${x}`)].unique(), // The variant selected and the original barcode may be duplicates, by using new Set we remove those.
        },
      },
    }).then(() => props.onSentRequest?.());
  };

  return (
    <>
      <Row align="middle" justify="start">
        <Col>Message: </Col>
      </Row>
      <Row className={style.row}>
        <Col flex={1} className={style.textArea}>
          <TextArea onChange={val => setMessage(val.target.value)} allowClear autoSize={{ minRows: 6, maxRows: 6 }} />
        </Col>
      </Row>
      {props.barcodes.length > 1 ? (
        <BarcodeCheckboxes barcodes={props.barcodes} variants={props.variants} onChange={setCheckedList} />
      ) : (
        <VariantCheckboxes
          variants={props.variants}
          variantCheckedList={variantCheckedList}
          onChange={toggleVariantCheckbox}
          barcodes={props.barcodes}
        />
      )}
      <Row>Images in email:</Row>
      <Row>
        <Checkbox key={`includeImagesInEmail`} checked={includeImagesInEmail} onChange={e => setIncludeImagesInEmail(e.target.checked)}>
          Include images in email notification
        </Checkbox>
      </Row>
      <Row className={style.row} justify="end">
        {props.availableSalesOrders <= 0 ? (
          <Button disabled={canSend} loading={loading} type="primary" onClick={requestPicture}>
            Send request
          </Button>
        ) : (
          <Popconfirm title={'This wine has sales orders. Do you still want to request a picture?'} onConfirm={requestPicture}>
            <Button disabled={canSend} loading={loading} type="primary">
              Send request
            </Button>
          </Popconfirm>
        )}
      </Row>
    </>
  );
}

function BarcodeCheckboxes(props: { barcodes: Barcode[]; variants: string[]; onChange: (value: any) => void }) {
  return (
    <>
      <Row gutter={[12, 12]}>
        <Col>Select placements to take pictures of:</Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col>
          <CheckboxGroup
            options={props.barcodes.map((code, idx) => {
              return { label: `Pos. ${idx + 1}`, value: code.original };
            })}
            value={props.variants}
            onChange={e => props.onChange(e as string[])}
          />
        </Col>
      </Row>
    </>
  );
}

function VariantCheckboxes(props: {
  variants: string[];
  variantCheckedList: string[];
  barcodes: Barcode[];
  onChange: (value: any) => void;
}) {
  return (
    <>
      Include the following variants:
      <br />
      {props.variants.map(x => (
        <Checkbox
          key={`variant_${x}`}
          checked={!!props.variantCheckedList.find(variant => x === variant)}
          onChange={e => props.onChange(x)}
          disabled={props.barcodes[0].variantNumber === x}
        >
          {x}
        </Checkbox>
      ))}
    </>
  );
}
