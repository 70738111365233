import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import getData from '../general/useGetApolloData';

export const GetActiveUsers = gql`
  query GetActiveUsers {
    activeUsers @client {
      email
      location
      lastUpdated
      firstName
      lastName
    }
  }
`;

const useActiveUsers = () => {
  const { data, loading, error } = useQuery<ApolloDataWrapper<ActiveUser[]>>(GetActiveUsers);
  return { data: getData(data), loading, error };
};

export default useActiveUsers;
