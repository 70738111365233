import { CheckCircleTwoTone } from '@ant-design/icons';
import { Alert, Button, Col, Row } from 'antd';
import React, { useState } from 'react';

import useZip from '../../../../components/hooks/useZip';

import style from './download-warehouse-images.module.scss';

export default function DownloadWarehouseImages(props: { images: string[]; poNumber: string; vendorName: string }) {
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const zip = useZip();

  async function downloadImages() {
    for (let i = 0; i < selectedImages.length; i++) {
      const image = selectedImages[i];
      const response = await fetch(`${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-warehouse/${image}`);
      const blob = await response.blob();
      zip.add(image, blob);
    }

    zip.download(`${props.poNumber}_${props.vendorName.replace(' ', '_')}`);
  }

  return (
    <>
      <Row>
        <Col style={{ width: '100%', marginBottom: 16 }}>
          <span>
            <Alert message="Click the images you want to download, then click the download button" />
          </span>
        </Col>
      </Row>
      <Row justify="center">
        {props.images.map(image => {
          const active = selectedImages.includes(image) ? <CheckCircleTwoTone className={style.icon} /> : <></>;

          return (
            <Col key={image}>
              <div
                className={`${style.image}`}
                onClick={() =>
                  setSelectedImages(selectedImages.includes(image) ? selectedImages.filter(x => x !== image) : [...selectedImages, image])
                }
              >
                {active}
                <img alt="" src={`${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-warehouse/${image}`} style={{ width: '125px' }} />
              </div>
            </Col>
          );
        })}
      </Row>
      <Row justify="center" style={{ marginTop: 16 }}>
        <Button type="primary" onClick={downloadImages} disabled={selectedImages.length === 0}>
          Download selected images
        </Button>
      </Row>
      ;
    </>
  );
}
