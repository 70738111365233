import { useCallback } from 'react';

import { createEvent, useForceUpdateEvent } from '../../apps/trading-app/hooks/general/useForceUpdate';

let fullScreen = false;

const updateFullScreenEvent = createEvent();

export default function useFullScreenMode(): [boolean, (newValue: boolean) => void] {
  const forceUpdate = useForceUpdateEvent(updateFullScreenEvent);

  const setFullScreen = useCallback((newFullScreen: boolean) => {
    fullScreen = newFullScreen;
    forceUpdate();
  }, []);

  return [fullScreen, setFullScreen];
}
