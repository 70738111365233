import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import getData from '../general/useGetApolloData';

interface UpsertUserArgs {
  input: {
    email: string;
    auth0id: string;
    firstName: string;
    lastName: string;
  };
}

const mutation = gql`
  mutation UpdateUserMutation($input: UpsertUserArgs!) {
    updateUser(input: $input) @rest(type: "UpdateUser", path: "user/upsert", method: "POST") {
      id
      auth0id
      email
      firstName
      lastName
    }
  }
`;

const useUpsertUser = (options?: MutationHookOptions<ApolloDataWrapper<User>, UpsertUserArgs>) => {
  const [mutate, { loading, error, data }] = useMutation<ApolloDataWrapper<User>, UpsertUserArgs>(mutation, options);

  return { mutate, loading, error, data: getData(data) };
};

export default useUpsertUser;
