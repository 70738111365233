import { useReducer } from 'react';

// https://reactjs.org/docs/hooks-reference.html#usereducer

type Action<T> = (currentState: T[]) => T[];

function reducer<T>(state: T[], action: Action<T>): T[] {
  return action([...state]);
}

/**
 * Handles state for an array of T and provides methods for mutating it
 *
 * @param initialData
 */
function useStateArray<T>(initialData?: T[], comparer: (a: T, b: T) => boolean = (a, b) => a === b) {
  const [state, transform] = useReducer(reducer as (state: T[], action: Action<T>) => T[], initialData || ([] as T[]));

  const api = {
    clear: () => transform(currentState => []),
    contains: (data: T) => state.some(x => comparer(x, data)),
    add: (data: T) => transform(currentState => [...currentState, data]),
    addUnique: (data: T) => transform(currentState => [...currentState, ...[data].filter(x => !currentState.some(y => comparer(x, y)))]),
    addMany: (data: T[]) => transform(currentState => [...currentState, ...data]),
    addManyUnique: (data: T[]) =>
      transform(currentState => [...currentState, ...data.filter(x => !currentState.some(y => comparer(x, y)))]),
    remove: (data: T) => transform(currentState => [...currentState.filter(x => !comparer(x, data))]),
    removeMany: (data: T[]) => transform(currentState => [...currentState.filter(x => !data.some(y => comparer(x, y)))]),
    removeByIndex: (index: number) => transform(currentState => [...currentState.filter((_, valueIndex) => valueIndex !== index)]),
    filter: (predicate: (data: T) => boolean) => transform(currentState => [...currentState.filter(x => predicate(x))]),
    set: (data: T[]) => transform(currentState => data),
    update: (data: T) =>
      transform(currentState => {
        const existing = currentState.find(x => comparer(x, data));
        if (existing) {
          return [...currentState.filter(x => !comparer(x, data)), data];
        }

        return currentState;
      }),
    updateMany: (data: T[]) =>
      transform(currentState => {
        const existing = currentState.filter(x => data.some(y => comparer(x, y)));

        if (existing.length > 0) {
          return [...currentState.filter(x => !data.some(y => comparer(x, y))), ...data];
        }

        return currentState;
      }),
    updateWhere: (predicate: (data: T) => boolean, update: (data: T) => T) => {
      const toUpdate = state.filter(predicate).map(x => {
        return { ...x };
      });
      const updated = toUpdate.map(update);

      return [...state.filter(x => !predicate(x)), ...updated];
    },
  };

  return { array: state, api };
}

export default useStateArray;
