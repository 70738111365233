import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React from 'react';

const UserAvatar = (props: {
  email?: string;
  firstName?: string;
  lastName?: string;
  size?: 'stretch' | 'large' | 'small' | 'default' | number;
}) => {
  if (props.email) {
    const avatar = props.email.split('@')[0];
    return (
      <>
        <Avatar
          src={`${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-assets/${avatar}.png`}
          style={(props.size === 'stretch' && { height: '100%', width: '100%' }) || undefined}
          size={(props.size !== 'stretch' && props.size) || 'small'}
        />
      </>
    );
  } else if (props.firstName || props.lastName) {
    return (
      <>
        <Avatar size="small">{`${props.firstName?.substr(0, 1) || ''}${props.lastName?.substr(0, 1) || ''}`}</Avatar>
      </>
    );
  } else {
    return (
      <>
        <Avatar icon={<UserOutlined />} size="small" />
      </>
    );
  }
};

export default UserAvatar;
