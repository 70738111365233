import { useEffect, useReducer } from 'react';

export default function useForceUpdate() {
  const forceUpdate = useReducer(x => x + 1, 0)[1];

  return () => forceUpdate();
}

export function useForceUpdateEvent(event: (() => void)[]) {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    event.push(forceUpdate);

    return () => {
      const idx = event.indexOf(forceUpdate);
      event.splice(idx, 1);
    };
  }, []);

  return () => event.forEach(x => x());
}

export function createEvent(): (() => void)[] {
  return [];
}

export function useForceUpdateEventNamed(name: string, event: { [key: string]: (() => void)[] }) {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (!event[name]) event[name] = [];

    event[name].push(forceUpdate);

    return () => {
      const idx = event[name].indexOf(forceUpdate);
      event[name].splice(idx, 1);
    };
  }, []);

  return () => event[name].forEach(x => x());
}

export function createEventNamed(): { [key: string]: (() => void)[] } {
  return {};
}
