import { List } from 'antd';
import React, { useState } from 'react';
import Viewer from 'react-viewer';

import CSS from '../../../helpers/css/Css';

type StockWine = {
  Producer: string;
  Wine: string;
  Region: string;
  Quantity: string;
  Packaging: string;
  Price: number;
  Id: string;
  Volume: string;
  Condition: string;
  Year: number;
  Box: string;
  Colour: string;
  Pictures: string[];
  FolderId: string;
};

export default function PictureViewer(props: { row: StockWine }) {
  const fetchURL = `${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-warehouse/image-product-maps/${props.row.FolderId}/`;
  const [visible, setVisible] = useState<boolean>(false);
  const [source, setSource] = useState<string>();
  return (
    <React.Fragment>
      <Viewer
        visible={visible}
        onClose={() => setVisible(false)}
        onMaskClick={() => setVisible(false)}
        images={[{ src: `${fetchURL}${source}.jpg` }]}
      />
      <List<string>
        grid={{
          gutter: 4,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 2,
        }}
        rowKey={e => e}
        dataSource={props.row.Pictures}
        pagination={{ pageSize: 2, hideOnSinglePage: true }}
        renderItem={picture => (
          <List.Item style={{ margin: '0px' }}>
            <div className={CSS.Effects.Hover.Opacity.Opacity70} style={{ margin: '0px' }}>
              <img
                onClick={() => {
                  setSource(picture);
                  setVisible(true);
                }}
                src={`${fetchURL}${picture}.jpg`}
                style={{ width: '370px' }}
                alt=""
              ></img>
            </div>
          </List.Item>
        )}
      ></List>
    </React.Fragment>
  );
}
