export default function useQueryParams(params: string[]): { [key: string]: string } {
  const locationParams = new URLSearchParams(window.location.search);

  let result: { [key: string]: string } = {};
  params.forEach(p => {
    const value = locationParams.get(p);
    if (value) result[p] = value;
  });

  return result;
}
