import { Button, Card, Row, Space, Tag, message, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useGuide from '../../../../sharedHooks/useGuide';
import useAuthToken from '../../../hooks/meta/useAuthToken';
import useCreateDraftEmail from '../../../hooks/warehouse/useCreateDraftEmail';
import { PictureViewerModal } from '../picture-viewer-modal';

export default function PictureCard(props: { data: StockWineImagePayload; activePictureID: string | undefined }) {
  const { resetGuide } = useGuide('Create image email draft');
  const history = useHistory();
  const authToken = useAuthToken();

  const handleClick = () => {
    const redirectLink = `/trading/instock/${props.data.imageResult.productIds[0].itemNumber}/${props.data.imageResult.productIds[0].variantNumber}/yes`;
    history.push(redirectLink);
  };
  const [modalVisible, setModalVisible] = useState(false);
  const { createEmailDraft, loading } = useCreateDraftEmail(authToken!);

  const createDraft = () =>
    createEmailDraft(props.data.imageResult.id, `${props.data.poResult.item.name} ${props.data.poResult.item.year}`)
      .then(() => message.success('Draft email was created'))
      .catch(e => {
        notification.error({
          message: 'Draft email could not be created',
          description: 'The combined size of the images may exceed the maximum allowed.',
          duration: 0,
        });
        resetGuide();
        const redirectLink = `/trading/instock/${props.data.imageResult.productIds[0].itemNumber}/${props.data.imageResult.productIds[0].variantNumber}/yes`;
        history.push(redirectLink);
      });

  const fetchURL = `${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-warehouse/image-product-maps/${props.data.imageResult.id}/${props.data.imageResult.images[0]}.jpg`;

  useEffect(() => {
    if (props.activePictureID === props.data.imageResult.id) {
      setModalVisible(true);
    }
  }, []);
  if (props.data) {
    let date = new Date(props.data.imageResult.modifiedAt.date);
    return (
      <>
        <Card
          hoverable
          style={{ width: '300px', flexDirection: 'row' }}
          cover={<img style={{ height: 350, objectFit: 'cover' }} src={fetchURL} alt={''} onClick={handleClick} />}
        >
          <div onClick={handleClick}>
            <div style={{ fontWeight: 'bold' }}>
              {props.data.poResult.item?.name} {props.data.poResult.item?.year}
            </div>
            <div>
              Time of upload: {date.getDate()}/{date.getMonth()}-{date.getFullYear()} {date.getHours()}:{date.getMinutes()}
            </div>
            <div>
              <Tag style={{ margin: '2px' }}>ItemNumber: {props.data.imageResult.productIds[0].itemNumber}</Tag>
            </div>
            <div>
              <Tag style={{ margin: '2px' }}>Variant: {props.data.imageResult.productIds[0].variantNumber}</Tag>
            </div>
            <div>
              <Tag style={{ margin: '2px' }}>{`Total pictures: ${props.data.imageResult.images.length}`}</Tag>
            </div>
          </div>
          <Row justify="center">
            <Space>
              <Button
                type="primary"
                size="small"
                style={{ marginTop: '10px' }}
                onClick={() => {
                  setModalVisible(!modalVisible);
                }}
              >
                Show pictures
              </Button>
              <Button type="primary" size="small" loading={loading} disabled={loading} style={{ marginTop: '10px' }} onClick={createDraft}>
                Create draft mail
              </Button>
            </Space>
          </Row>
        </Card>
        <PictureViewerModal visible={modalVisible} setVisible={setModalVisible} data={props.data}></PictureViewerModal>
      </>
    );
  }

  return <div>Something went wrong</div>;
}
