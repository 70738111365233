import { create } from 'canvas-confetti';

const canvas = document.getElementById('canvas-for-confetti')! as HTMLCanvasElement;

const Confetti = {
  Create: create(canvas, {
    resize: true,
    useWorker: true,
  }),
};

export default Confetti;
