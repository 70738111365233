import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import getData from '../general/useGetApolloData';

export type LwinSyncResult = {
  started: boolean;
};

const Mutation = gql`
  mutation SynchronizeLwin {
    data(input: {}) @rest(type: "LwinSyncResult", path: "wine/sync", method: "POST") {
      started
    }
  }
`;

export default () => {
  const [mutate, { loading, data }] = useMutation<ApolloDataWrapper<LwinSyncResult>>(Mutation);

  return { mutate, loading, data: getData(data) };
};
