import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import getData from '../general/useGetApolloData';

const query = gql`
  query getExchangeRages {
    GetExchangeRates @rest(type: "GetExchangeRates", path: "exchangeRates", method: "GET") {
      base
      rates @type(name: "ExchangeRates") {
        BGN
        NZD
        ILS
        RUB
        CAD
        USD
        PHP
        CHF
        ZAR
        AUD
        JPY
        TRY
        HKD
        MYR
        THB
        HRK
        NOK
        IDR
        DKK
        CZK
        HUF
        GBP
        MXN
        KRW
        ISK
        SGD
        BRL
        PLN
        INR
        RON
        CNY
        SEK
      }
      date
    }
  }
`;

export default () => {
  const { data, loading, error } = useQuery<ApolloDataWrapper<ExchangeRates>>(query, { skip: false });

  return { data: getData(data), loading, error };
};
