import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import React, { ComponentType, useEffect, useState } from 'react';

type AIContextArgs = {
  applicationInsights: ApplicationInsights | null;
};

export const ApplicationInsightsContext = React.createContext<AIContextArgs>({
  applicationInsights: null,
});

const reactPlugin = new ReactPlugin();

const ApplicationInsightsProviderStockList: React.FunctionComponent = props => {
  const [applicationInsights] = useState<ApplicationInsights>(
    (() => {
      let ai = new ApplicationInsights({
        config: {
          instrumentationKey: 'afff2d4a-f73c-47ed-8465-61bd84c8e0f9',
          autoTrackPageVisitTime: true,
        },
      });

      return ai;
    })()
  );

  useEffect(() => {
    applicationInsights.loadAppInsights();

    applicationInsights.addTelemetryInitializer(item => {
      if (process.env.NODE_ENV !== 'production') {
        // if we're not in production, log to console instead of to App Insights
        // eslint-disable-next-line
        console.debug('AppInsights.' + item.baseType, item);

        return false;
      }

      return true;
    });
  }, []);

  return (
    <ApplicationInsightsContext.Provider value={{ applicationInsights: applicationInsights }}>
      {props.children}
    </ApplicationInsightsContext.Provider>
  );
};

export const trackComponent = (component: ComponentType<unknown>, name: string) => withAITracking(reactPlugin, component, name);
export default ApplicationInsightsProviderStockList;
