import { Divider, Input, InputProps } from 'antd';
import React, { ReactNode, useEffect, useRef } from 'react';

import useQueryParams from '../../hooks/useQueryParams';
import useStateDebounced from '../../hooks/useStateDebounced';

/**
 * Props for the search component.
 */
export type SearcherProps = {
  delay?: number;
  queryParameterName?: string;
  disableAutoFocusOnWriting?: boolean;
  preserveCase?: boolean;
  onSearch?: (searchString: string) => void;
  onSearchTerms?: (searchTerms: string[]) => void;
  buttonsLeft?: React.ReactNode[];
  buttonsRight?: React.ReactNode[];
} & Pick<InputProps, 'size' | 'className' | 'autoFocus' | 'placeholder'>;

function RenderButtons(buttons?: ReactNode[]) {
  return buttons?.filter(b => !!b).length ? (
    <>
      {buttons
        .filter(b => !!b)
        .flatMap((button, idx, all) => {
          if (!button) return undefined;

          return idx !== all.length - 1
            ? [
                <div style={{ display: 'inline-block' }} key={idx}>
                  {button}
                </div>,
                <Divider key={'div' + idx} type="vertical" />,
              ]
            : [
                <div style={{ display: 'inline-block' }} key={idx}>
                  {button}
                </div>,
              ];
        })}
    </>
  ) : undefined;
}

/**
 * A searcher component which defines the styling for search fields.
 * @export
 * @param delay {number} The delay between each run of the search algorithm.
 * @param queryParameterName {string} The name of a URL query parameter that forms the default search. Defaults to 'search'.
 * @param disableAutoFocusOnWriting {boolean} A flag to indicate whether focus should be addressed to the component when the user presses a key.
 * @param preserveCase {boolean} A flag to indicate whether the search should be case sensitive.
 * @param onSearch {(s: string) => void} A callback function, which is called when the user searches for something.
 * @param onSearchTerms {(s: string[]) => void} A callback function, which is called with the search query, split on space.
 * @param buttonsLeft {React.ReactNode[]} Buttons to place on the left-side of the searcher.
 * @param buttonsRight {React.ReactNode[]} Buttons to place on the right-side of the searcher.
 * @return {React.ReactNode} The react component.
 * @see SearcherProps.
 */
export default function Searcher({
  queryParameterName = 'search',
  delay = 500,
  disableAutoFocusOnWriting,
  onSearch,
  onSearchTerms,
  buttonsLeft,
  buttonsRight,
  preserveCase,
  // The rest of the props are forwarded to the underlying antd Search component
  ...searchProps
}: SearcherProps) {
  searchProps.placeholder = searchProps.placeholder ? searchProps.placeholder : 'Search';
  const queryParams = useQueryParams([queryParameterName]);
  const queryParamValue = queryParams[queryParameterName] ?? '';
  const [search, setSearch, loading] = useStateDebounced(queryParamValue, delay);
  const searchRef = useRef<Input>();

  const focusSearch = (evt: any) => {
    const keyEvent = evt as KeyboardEvent;

    if (keyEvent.ctrlKey || keyEvent.altKey || keyEvent.shiftKey) return;

    if (searchRef.current && evt.path && evt.path[0].__proto__ !== HTMLInputElement.prototype) {
      searchRef.current.focus();
    }
  };

  useEffect(() => {
    if (!disableAutoFocusOnWriting) {
      window.addEventListener('keydown', focusSearch);

      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener('keydown', focusSearch);
      };
    }
  }, [disableAutoFocusOnWriting]);

  useEffect(() => {
    const terms = search.split(' ');
    onSearch?.(search);
    onSearchTerms?.(terms);
    // Only include search as a dependency, otherwise the useEffect result in an inifinite loop
    // eslint-disable-next-line
  }, [search]);

  const LeftButtons = RenderButtons(buttonsLeft);
  const RightButtons = RenderButtons(buttonsRight);

  return (
    <Input.Search
      addonBefore={LeftButtons}
      addonAfter={RightButtons}
      ref={r => (searchRef.current = r || undefined)}
      defaultValue={queryParamValue ?? ''}
      onChange={evt => setSearch(preserveCase ? evt.currentTarget.value : evt.currentTarget.value.toLowerCase())}
      {...searchProps}
      loading={loading}
    />
  );
}
