import { ClusterOutlined, SettingOutlined } from '@ant-design/icons';
import React from 'react';

import ImageBrowser from './pages/ImagesBrowser/ImageBrowser';
import PurchaseOrders from './pages/purchase-orders/purchase-orders';

const WarehouseApp: AppInfo = {
  name: 'Warehouse',
  baseRoute: 'warehouse',
  requiredRoles: 'Warehouse',
  routes: [
    {
      path: 'purchaseOrders',
      text: 'Purchase Orders',
      render: (props: any) => <PurchaseOrders {...props} />,
    },
    {
      path: 'developer',
      text: 'Dev tools',
      icon: <SettingOutlined />,
      requiredRoles: 'Developer',
      children: [
        {
          path: 'imageSets',
          text: 'Image Sets',
          icon: <ClusterOutlined />,
          render: (props: any) => <ImageBrowser {...props} />,
        },
      ],
    },
  ],
};

export default WarehouseApp;
