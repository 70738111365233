import { Col, List, Modal, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';

export const PictureViewerModal = (props: { visible: boolean; setVisible: (visible: boolean) => void; data: StockWineImagePayload }) => {
  const isDekstop = useBreakpoint().lg;

  const images = props.data.imageResult.images.map(image => {
    return `${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-warehouse/image-product-maps/${props.data.imageResult.id}/${image}.jpg`;
  });

  return (
    <Modal
      title={null}
      visible={props.visible}
      onCancel={() => {
        props.setVisible(false);
      }}
      width="95%"
      footer={null}
    >
      <Row justify="start">
        {isDekstop ? (
          images.map(image => {
            return (
              <Col style={{ padding: 24 }} span={6} key={image}>
                <img src={image} alt={''} width="90%"></img>
              </Col>
            );
          })
        ) : (
          <div>
            <List
              pagination={{ pageSize: 1, responsive: true }}
              dataSource={images}
              renderItem={(item, _) => <img src={item} alt={''} width="90%"></img>}
            ></List>
          </div>
        )}
      </Row>
    </Modal>
  );
};
