import { CloseOutlined } from '@ant-design/icons';
import { Avatar, Button, List } from 'antd';
import React from 'react';

import style from '../notifications.module.scss';

interface props {
  notification: RWNotification;
}

const NotFoundNotification = (props: props) => {
  return (
    <>
      <List.Item.Meta
        className={`${props.notification.isRead ? '' : style.unread} ${style.notificationItem}`}
        avatar={<Avatar icon={<CloseOutlined />} />}
        title={
          <Button type="link">
            {props.notification.context}.{props.notification.event}
          </Button>
        }
        description={'Error: A notification was received that could not be read.'}
      />
    </>
  );
};

export default NotFoundNotification;
