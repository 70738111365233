import React from 'react';

import useFullScreenMode from '../hooks/useFullScreenMode';

import style from './content-area.module.scss';

interface ContentProps {
  children: React.ReactNode;
  showBackground?: boolean;
  style?: React.CSSProperties;
}

export default function ContentArea({ children, showBackground, style: propStyle }: ContentProps) {
  const [fullScreen] = useFullScreenMode();

  return (
    <div className={`${showBackground && style.background} ${fullScreen && style.fullScreen}`} style={propStyle}>
      {children}
    </div>
  );
}
