import { Alert, Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';

import useUpsertUser from '../../apps/trading-app/hooks/user/UpdateUserMutation';
//import useUser from '../../apps/trading-app/hooks/user/useUser';
import { useAuth0 } from '../../helpers/useAuth0';
import crate1 from '../../images/splash/crate_01.jpg';
import OverlayLoading from '../overlay-loading/overlay-loading';

import style from '../splash-screen/splash-screen.module.scss';

const SetupAccount = () => {
  const { mutate: upsertUser, loading } = useUpsertUser({ refetchQueries: ['GetUser'] });
  const { user: auth0User, loading: loadingAuth } = useAuth0();
  const [allowSend, setAllowSend] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);
  const [form] = useForm();

  return (
    <>
      <img alt="" className={style.bg} src={crate1} />
      {loading || loadingAuth ? (
        <OverlayLoading />
      ) : (
        <>
          <div className={style.container}>
            {success ? (
              <Alert
                className={[style.fitToWidth, style.loginAlert].join(' ')}
                type="success"
                message="The account was added successfully."
              />
            ) : (
              <Alert
                className={[style.fitToWidth, style.loginAlert].join(' ')}
                type="info"
                message="It is required to fill out an account to use the Rare Wine Platform"
              />
            )}
            <div className={[style.login, style.withAlert, style.fitToWidth].join(' ')}>
              <Form
                form={form}
                onValuesChange={(e, allValues) => {
                  setAllowSend(allValues.firstName?.length > 0 && allValues.lastName?.length > 0);
                }}
                onFinish={e =>
                  upsertUser({
                    variables: {
                      input: {
                        email: auth0User!.email,
                        auth0id: auth0User!.sub,
                        firstName: e.firstName,
                        lastName: e.lastName,
                      },
                    },
                  }).then(response => setSuccess(response.errors === undefined || !response.errors.length))
                }
              >
                <Form.Item name="email" label="Email">
                  <Input placeholder={auth0User!.email} disabled />
                </Form.Item>
                <Form.Item label="First Name" name="firstName" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Last Name" name="lastName" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Button key="submit" type="primary" htmlType="submit" loading={loading} disabled={!allowSend}>
                  Save information
                </Button>
              </Form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SetupAccount;
