import { useEffect, useRef, useState } from 'react';

import { useAuth0 } from '../../../../helpers/useAuth0';

export default function useAuthToken() {
  const { loading, getTokenSilently } = useAuth0();
  const [token, setToken] = useState<string | null>(null);
  const tokenProvider = useRef(getTokenSilently);

  useEffect(() => {
    if (!loading) {
      tokenProvider.current = getTokenSilently;
      tokenProvider.current().then(token => setToken(token));
    }
  }, [loading, getTokenSilently]);

  return token;
}
