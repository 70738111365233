import { BackTop, Button, Col, Row, Table } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { FilterValue } from 'antd/lib/table/interface';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Searcher from '../../rarewine-library-frontend/components/searcher/seacher';
import '../../rarewine-library-frontend/extensions/ArrayExtensions';
import useFilterer from '../../rarewine-library-frontend/hooks/useFilterer';
import { AgeCheckModal } from './components/age-check-modal';
import { ApplicationInsightsContext } from './components/applicationinsights';
import useBlobFetch from './hooks/useBlobFetch';
import RareWineLogo from './pictures/RareWineTrans.png';
import { GetColumns, useColumnSplitQueryParams } from './stock-list-columns';
import { InformationGateModal, ProvideStockList, RequestNewWineModal, RequestWineImageModal, WineImageModal } from './stock-list-modals';
import { StockUser, StockWine } from './types';
import WineSorter from './wine-sorter';

import style from './stock-list.module.scss';

const daysBeforeNewEpoch = new Date().getTime() - 86400000 * 14;

function SanitiseNA(str?: string) {
  return str === 'NA' ? '' : str;
}

const patchShittyNavisionData = (e: StockWine) => {
  // Fix a problem where USA and United States are used interchangably
  if (e.Country.toLowerCase() === 'united states') {
    e.Country = 'USA';
  }
  // Fix a problem where scottish whiskies are listed as a region of UK
  else if (e.Country.toLowerCase() === 'united kingdom' && e.Region.toLowerCase() === 'scotland') {
    e.Country = 'Scotland';
  }
  // Fix a problem where some wines/spirits have no or NA as region
  else if (e.Region.length === 0 || e.Region.toLowerCase() === 'na') {
    e.Region = e.Country;
  } else if (e.Country === 'Burgundy') {
    e.Country = 'France';
    e.Region = 'Burgundy';
  }
};

const createNamesForWines = (wine: StockWine) => {
  if (wine.Region === wine.SubRegion) {
    return `${SanitiseNA(wine.Producer)} ${SanitiseNA(wine.Wine)}`.trim();
  }
  return `${SanitiseNA(wine.Producer)} ${SanitiseNA(wine.Wine)} ${SanitiseNA(wine.SubRegion)}`.trim();
};

const getConditionLabel = (cond: string) => {
  switch (cond) {
    case 'A':
      return 'Pristine condition';
    case 'B':
      return 'Great condition with minor imperfections';
    case 'C':
      return 'Decent condition with noteworthy imperfections';
    case 'D':
      return 'Significant imperfections';
    default:
      return '';
  }
};

export default function StockList() {
  const { data, loading, lastModified } = useBlobFetch<StockWine[]>('rare-wine-public/stocklist/list.json');

  const isDekstop = useBreakpoint().lg;
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [sortedData, setSortedData] = useState<StockWine[]>();
  const [visibleImage, setVisibleImage] = useState(false);
  const [countryString, setCountryString] = useState('Denmark');
  const [picturesLoaded, incrementPicturesLoaded] = useReducer(x => x + 1, 0);
  const [visibleRequestWine, setVisibleRequestWine] = useState(false);
  const [visibleRequestPicture, setVisibleRequestPicture] = useState(false);
  const [visibleInformationGate, setVisibleInformationGate] = useState(false);
  const [visibleProvideStocklist, setVisibileProvideStocklist] = useState(false);
  const [wine, setWine] = useState<StockWine>();
  const [user, setUser] = useState<StockUser>(JSON.parse(localStorage.getItem('form') || '{"email": "undefined"}'));

  const pictures_to_load = 2;
  //Add applicationInsights tracking
  const { applicationInsights } = useContext(ApplicationInsightsContext);

  //Workaround that normal useEffect does not work (We think applicationInsight needs time to load so small wait to finish loading)
  useEffect(() => {
    const timer = setTimeout(() => {
      if (user.email === 'undefined') {
        applicationInsights?.trackEvent({ name: 'StockList visted-site' });
      } else {
        applicationInsights?.trackEvent({ name: 'Stocklist visted-site' }, { user: user });
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  // Add sleeknote tracking
  useSleeknote();

  // add google tracking
  useEffect(() => {
    const children: HTMLScriptElement[] = [];

    if (window.ENVARGS.REACT_APP_HOST_ENVIRONMENT === 'Production') {
      const googleScript1 = document.createElement('script');
      googleScript1.src = 'https://www.googletagmanager.com/gtag/js?id=G-H61GDJRFHM';
      googleScript1.async = true;

      const googleScript2 = document.createElement('script');
      googleScript2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      
      function gtag(){dataLayer.push(arguments);}
      
      gtag('js', new Date());
      gtag('config', 'G-H61GDJRFHM');
    `;

      children.push(googleScript1);
      children.push(googleScript2);
    }

    for (const child of children) {
      document.head.appendChild(child);
    }

    return () => {
      for (const child of children) {
        document.head.removeChild(child);
      }
    };
  }, []);

  const [visibleProvideStocklistTimerBegun, setVisibleProvideStocklistTimerBegun] = useState(false);
  //Used to only display popup first time user enters site
  useEffect(() => {
    var visted = localStorage.getItem('stocklist-visted');
    if (!visted && picturesLoaded === pictures_to_load) {
      if (!visibleProvideStocklistTimerBegun) {
        setVisibleProvideStocklistTimerBegun(true);
        setTimeout(() => {
          localStorage.setItem('stocklist-visted', '1');
          setVisibileProvideStocklist(true);
        }, 10000);
      }
    }
  }, [picturesLoaded]);

  const filteredData = useFilterer(
    sortedData,
    searchTerms,
    wine => `${wine.Id} ${wine.Producer} ${wine.Wine} ${wine.Region} ${wine.Quantity} ${wine.Packaging}
  ${wine.Price} ${wine.Volume} ${wine.Condition} ${wine.Year} ${wine.Box} ${wine.Colour}`
  );

  useEffect(() => {
    if (data) {
      preprocessData(data);
    }
  }, [data]);

  const preprocessData = (data: StockWine[]) => {
    //Because names of the wine is tricky, we create a name field and set the names ourself
    data.forEach(e => {
      e.Name = createNamesForWines(e);
      e.LastPoDateEpoch = new Date(e.LastPoDate).getTime();
      e.New = e.LastPoDateEpoch > daysBeforeNewEpoch;

      patchShittyNavisionData(e);
    });

    setSortedData(WineSorter.sort(data));
  };

  const handleInformationGateOK = () => {
    setVisibleInformationGate(false);
    setVisibleImage(true);
  };

  const showRequestWineModal = () => {
    setVisibleRequestWine(true);
  };

  const showRequestImageModal = (w: StockWine) => {
    setWine(w);
    setVisibleRequestPicture(true);
  };

  const routingHistory = useHistory();

  const splitSearchQueryParams = useColumnSplitQueryParams();

  function onFilterChange(changes: Record<string, FilterValue | null>) {
    const filters = Object.entries(changes)
      .map(([key, value]) => {
        return {
          name: GetColumns({ filteredData, searchTerms, showRequestImageModal })[Number.parseInt(key)].title,
          filter: value,
        };
      })
      .filter(({ name, filter }) => !!filter && !!name);

    const searchHist = filters
      .map(({ name, filter }) =>
        filter?.length
          ? name?.toString().toLowerCase() + '=' + filter?.join(',')
          : name?.toString().toLowerCase() + '=' + splitSearchQueryParams[name!.toString().toLowerCase()]
      )
      .join('&');

    routingHistory.replace({ ...routingHistory.location, search: searchHist });
  }

  return (
    <>
      <Row className={style.header} justify={isDekstop ? undefined : 'center'}>
        <Col xs={12} lg={4}>
          <div style={{ display: 'inline-block' }}>
            <img src={RareWineLogo} alt="RareWine Logo" className={style.logo}></img>
          </div>
        </Col>
        <Col xs={{ span: 24, order: 2 }} lg={{ span: 14, order: 1 }} className={style.searchContainer}>
          <Searcher disableAutoFocusOnWriting onSearchTerms={setSearchTerms} className={style.searcher}></Searcher>
        </Col>
        <Col xs={{ span: 10, order: 1 }} lg={{ span: 5, order: 2 }}>
          <Button block onClick={showRequestWineModal} className={style.searcher}>
            Request new wine
          </Button>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24} xl={20}>
          <Table<StockWine>
            columns={GetColumns({ filteredData, searchTerms, showRequestImageModal })}
            loading={loading}
            dataSource={filteredData as StockWine[]}
            rowClassName={style.row}
            size="middle"
            locale={{ emptyText: 'Not finding the wine you are looking for? You can request a wine in the top right corner' }}
            tableLayout={'auto'}
            className="bumbleBeeTable"
            pagination={{ position: ['bottomCenter'], defaultPageSize: 50 }}
            rowKey={e => `${e.Id}+${e.Variant}+${e.Year}+${e.Volume}+${e.LastPoDate}`}
            onChange={(_, changes) => {
              onFilterChange(changes);
            }}
          />

          <BackTop />

          <WineImageModal
            wine={wine}
            onClose={() => setVisibleImage(false)}
            visible={visibleImage}
            setUser={setUser}
            user={user}
            applicationInsights={applicationInsights}
          />

          <InformationGateModal
            visible={visibleInformationGate}
            onClose={() => setVisibleInformationGate(false)}
            onOk={handleInformationGateOK}
            countryString={countryString}
            setCountryString={setCountryString}
            setUser={setUser}
            applicationInsights={applicationInsights}
          />

          <RequestNewWineModal
            visible={visibleRequestWine}
            onClose={() => setVisibleRequestWine(false)}
            countryString={countryString}
            setCountryString={setCountryString}
            setUser={setUser}
            applicationInsights={applicationInsights}
          />

          <RequestWineImageModal
            wine={wine}
            visible={visibleRequestPicture}
            onClose={() => setVisibleRequestPicture(false)}
            countryString={countryString}
            setCountryString={setCountryString}
            setUser={setUser}
          />

          <ProvideStockList
            visible={visibleProvideStocklist}
            onClose={() => {
              setVisibileProvideStocklist(false);
            }}
            applicationInsights={applicationInsights}
          />

          <AgeCheckModal />

          <div style={{ textAlign: 'center', marginTop: '4vh' }}>
            <p>Last updated: {lastModified}</p>
            <p>Conditions {['A', 'B', 'C', 'D'].map(c => `${c}: ${getConditionLabel(c)}`).join(', ')}.</p>
            <p>All prices are stated in euro (€) pr. bottle ex. VAT and Excise </p>
            <p>Wines in OWC are sold in full case only.</p>
            <p>All wines are stored in our bonded warehouse in Denmark.</p>
            <p>All wines are offered subject to final confirmation and our general terms and conditions.</p>
          </div>
        </Col>
        <img
          src={`${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-public/stocklist/FactoryPicture.jpg`}
          style={{ opacity: 0, height: 0, width: 0 }}
          alt=""
          onLoad={incrementPicturesLoaded}
        ></img>
        <img
          src={`${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-public/stocklist/RareWineTrading_Logo_Cirkel_300x300.png`}
          style={{ opacity: 0, height: 0, width: 0 }}
          alt=""
          onLoad={incrementPicturesLoaded}
        ></img>
      </Row>
    </>
  );
}

// Script for embedding sleek note tracking
function useSleeknote() {
  return useEffect(() => {
    if (process.env.REACT_APP_HOST_ENVIRONMENT !== 'Production') return;

    var sleeknoteScriptTag = document.createElement('script');

    sleeknoteScriptTag.type = 'text/javascript';

    sleeknoteScriptTag.charset = 'utf-8';

    sleeknoteScriptTag.src = '//sleeknotecustomerscripts.sleeknote.com/16123.js';

    document.head.appendChild(sleeknoteScriptTag);

    // This script should only be run once.
    // eslint-disable-next-line
  }, []);
}
