import { CloseCircleFilled } from '@ant-design/icons';
import { Alert, Col, message, Row, Typography } from 'antd';
import { ExecutionResult } from 'graphql/execution/execute';
import React from 'react';

const { Text, Title } = Typography;

export default function ApolloResponseHook(response: ExecutionResult) {
  if (response?.errors) {
    response.errors.forEach(error => {
      message.open({
        duration: 10,
        type: 'error',
        icon: <div></div>,
        content: (
          <div
            // this negates the padding on message, so the alert gets the full area
            style={{ margin: '-10px -16px' }}
          >
            <Alert
              style={{ paddingLeft: 15 }}
              type="error"
              message={
                <>
                  <Row key="header" justify="space-between" align="middle">
                    <Col>
                      <CloseCircleFilled color="red" style={{ fontSize: 18 }} />
                    </Col>
                    <Col>
                      <Title level={3}>{error.message}</Title>
                    </Col>
                  </Row>
                  {error.extensions &&
                    Object.entries(error.extensions).map(([key, value]) => {
                      return (
                        <Row key={key} justify="space-between">
                          <Col>
                            <Text>{key}</Text>
                          </Col>
                          <Col>
                            <Text>{value}</Text>
                          </Col>
                        </Row>
                      );
                    })}
                </>
              }
            />
          </div>
        ),
      });
    });
  }
}
