import { Button, Input, Switch } from 'antd';
import React, { useState } from 'react';

import Confetti from '../../../../helpers/confetti';
import WithModal from '../../../../helpers/with-modal';
import Searcher from '../../../../rarewine-library-frontend/components/searcher/seacher';
import useApps from '../../../apps';
import useAlerts from '../../../sharedHooks/useAlerts';

const Test = () => {
  const { addAlert } = useAlerts();
  const [alertId, setAlertId] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [type, setType] = useState('');
  const [closable, setClosable] = useState(false);
  const { currentApp, currentRoute } = useApps();

  const imageDimensions = (url: string) =>
    new Promise((resolve, reject) => {
      const img = new Image();

      // the following handler will fire after the successful parsing of the image
      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img;
        resolve({ width, height });
      };

      // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
      img.onerror = () => {
        reject('There was some problem with the image.');
      };

      img.src = url;
    });

  // eslint-disable-next-line no-console
  const log = console.log;

  imageDimensions(
    'http://127.0.0.1:10000/devstoreaccount1/rare-wine-warehouse/image-product-maps/03ec8f1d-1203-4b7e-967d-faec0a545ccc/322d8af3-5805-4b46-b9e3-960b47e80609.jpg'
  )
    // eslint-disable-next-line no-console
    .then(log);

  return (
    <>
      <Searcher buttonsLeft={['wat', 'bat']} buttonsRight={['wut', 'but']} onSearch={log} onSearchTerms={log} />
      <br></br>
      <br></br>
      <div>
        <WithModal>
          {modal => (
            <Button
              onClick={() =>
                modal.info({
                  title: 'does it work?',
                  content: 'it does',
                })
              }
            >
              Test modal
            </Button>
          )}
        </WithModal>
      </div>
      <Button
        onClick={() => {
          Confetti.Create({
            spread: 20,
            angle: 120,
            origin: {
              x: 1,
              y: 1,
            },
            startVelocity: 130,
          });

          Confetti.Create({
            spread: 20,
            angle: 60,
            origin: {
              x: 0,
              y: 1,
            },
            startVelocity: 130,
          });
        }}
      >
        Confetti
      </Button>
      SingletonKey <Input value={alertId} onChange={e => setAlertId(e.target.value)} />
      Title <Input value={title} onChange={e => setTitle(e.target.value)} />
      Content <Input value={content} onChange={e => setContent(e.target.value)} />
      Type <Input value={type} onChange={e => setType(e.target.value)} />
      Closable <Switch checked={closable} onChange={setClosable} /> <br />
      <button
        onClick={() =>
          addAlert({
            title: title,
            content: content,
            type: type,
            singletonKey: alertId,
            closable: closable,
          })
        }
      >
        Global Alert
      </button>
      <button
        onClick={() =>
          addAlert({
            title: title,
            content: content,
            type: type,
            scopeToApp: currentApp,
            singletonKey: alertId,
            closable: closable,
          })
        }
      >
        Local to app
      </button>
      <button
        onClick={() =>
          addAlert({
            title: title,
            content: content,
            type: type,
            scopeToRoute: currentRoute,
            singletonKey: alertId,
            closable: closable,
          })
        }
      >
        Local to route
      </button>
    </>
  );
};

export default Test;
