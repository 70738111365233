import { useState } from 'react';

export default function useFetchNoAuth<T>(url: string, options: any = {}) {
  const [data, setData] = useState<T | undefined>(undefined);
  const [error, setError] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async (fetchOptions: any = {}) => {
    try {
      const res = await fetch(`${window.ENVARGS.REACT_APP_RAREWINE_API_URL}${url}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        ...fetchOptions,
      });
      const json = (await res.json()) as T;
      setData(json);
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  return { data, error, loading, fetch: fetchData };
}
