import React from 'react';

export default function IconComposition(
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    icon: React.ReactNode;
    subIcon: React.ReactNode;
  }
) {
  const propagateProps = { ...props };
  delete propagateProps.icon;
  delete propagateProps.subIcon;

  return (
    <div {...propagateProps} className={props.className} style={{ ...props.style, position: 'relative', display: 'inline-block' }}>
      {props.icon}
      <div
        style={{
          color: 'white',
          ...props.style,
          display: 'flex',
          position: 'absolute',
          transform: 'scale(0.5)',
          left: '30%',
          top: '45%',
          padding: 2,
        }}
      >
        {props.subIcon}
      </div>
    </div>
  );
}
