import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import getData from '../general/useGetApolloData';

type SetActiveUserArgs = {
  input: {
    email: string;
    location: string;
    lastUpdated: number;
    firstName: string;
    lastName: string;
  };
};

const mutation = gql`
  mutation setActiveUser($input: SetActiveUserArgs!) {
    setActiveUser(input: $input) @client {
      email
      location
      lastUpdated
      firstName
      lastName
    }
  }
`;

const useSetActiveUser = (options?: MutationOptions) => {
  const [mutate, { loading, error, data }] = useMutation<ApolloDataWrapper<ActiveUser>, SetActiveUserArgs>(mutation, options);

  return { mutate, loading, error, data: getData(data) };
};

export default useSetActiveUser;
