import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql.macro';

import useUserAuth from './useUserAuth';

const query = gql`
  query GetUser($id: String!) {
    GetUser(id: $id) @rest(type: "GetUser", path: "user/get/{args.id}", method: "GET") {
      user {
        id
        auth0Id
        email
        firstName
        lastName
      }
      success
    }
  }
`;

export interface GetUser {
  GetUser: {
    user: User;
  };
}

export default () => {
  const { userId, email } = useUserAuth();

  const { data, loading, error } = useQuery<GetUser>(query, {
    variables: { id: userId },
    skip: userId === undefined,
  });

  const newData: User | undefined = data && {
    ...data?.GetUser.user,
    email: data?.GetUser.user.email || email!,
  };

  return { data: newData, error, loading };
};
