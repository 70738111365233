import { CameraOutlined, DeleteOutlined, DownloadOutlined, LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Divider, Empty, Popconfirm, Row, Space, Spin, Tag, Tooltip, Typography, message, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import Viewer from 'react-viewer';

import useZip from '../../../../components/hooks/useZip';
import TriggerableTooltip, { useTriggerableTooltip } from '../../../../components/triggerable-tooltip/triggerable-tooltip';
import Css from '../../../../helpers/css/Css';
import WithModal from '../../../../helpers/with-modal';
import Searcher from '../../../../rarewine-library-frontend/components/searcher/seacher';
import useGuide from '../../../sharedHooks/useGuide';
import useAuthToken from '../../hooks/meta/useAuthToken';
import useCreateDraftEmail from '../../hooks/warehouse/useCreateDraftEmail';
import { useDeleteCase } from '../../hooks/wine/useDeleteImageRequest';
import { useDisableCase } from '../../hooks/wine/useDisableCase';
import { useStockWineImages } from '../../hooks/wine/useStockWineImages';
import RequestPictures from './request-pictures/request-pictures';

import style from './wine-images.module.scss';

const { Panel } = Collapse;
const { Text } = Typography;

enum EmailDraftGuide {
  SelectImages,
  CreateDraft,
}

export default function WineImages(props: Pick<NavItemVariant, 'id' | 'variant' | 'available' | 'availableSalesOrders' | 'name' | 'year'>) {
  const { showGuide, completeStep, resetGuide } = useGuide('Create image email draft');

  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  const pendingTooltip = useTriggerableTooltip();
  const downloadButton = useRef<HTMLDivElement>(null);

  const [galleryVisible, setGalleryVisible] = useState<boolean>(false);
  const [gallery, setGallery] = useState<{ src: string; alt: string }[]>([]);
  const [galleryIndex, setGalleryIndex] = useState(0);

  const zip = useZip();

  const { disable } = useDisableCase();

  const variantNumber = props.variant?.toString() ?? '000';

  const { data, loading, refetch } = useStockWineImages(props.id, variantNumber);
  const [refetching, setRefetching] = useState(false);

  async function DoRefetch() {
    setRefetching(true);

    await refetch?.();

    setRefetching(false);
  }

  function DisableCase(imageData: StockWineImagePayload[]) {
    if (props.available <= 0 && imageData?.length >= 0) {
      let imageId = [];

      for (let i = 0; i < imageData.length; i++) {
        imageId.push(imageData[i].imageResult.id);
      }
      disable({
        variables: {
          input: {
            imageRequestId: imageId,
          },
        },
      }).then(DoRefetch);
    }
  }

  const activeRequests = data?.filter(x => x.imageResult.active === true && x.imageResult.author !== null) ?? [];
  const filteredData = data;
  /* useFilterer(data, searchTerms, x => {
    const date = new Date(x.imageResult.createdAt.date);
    return `${date.toDateString()} ${date.toLocaleTimeString()} ${x.imageResult.productIds.map(x => x.poNumber).join(' ')} ${
      x.imageResult.author
    } ${x.imageResult.message}`;
  }); */

  const showGallery = (po: StockWineImagePayload, image: string) => {
    const idx = po.imageResult.images.indexOf(image);
    if (idx > -1) {
      setGalleryIndex(idx);
    }
    let imageData = po.imageResult.images.map(image => {
      return {
        src: `${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-warehouse/image-product-maps/${po.imageResult.id}/${image}.jpg`,
        alt: '',
      };
    });
    setGallery(imageData);
  };

  async function downloadImages() {
    for (let i = 0; i < selectedImages.length; i++) {
      const image = selectedImages[i];
      const response = await fetch(`${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-warehouse/image-product-maps/${image}`);
      const blob = await response.blob();
      zip.add(`${image}`, blob);
    }

    zip.download('trading_images');
    setSelectedImages([]);
    zip.clear();
  }

  async function downloadAllImages(po: StockWineImagePayload) {
    for (let i = 0; i < po.imageResult.images?.length; i++) {
      const image = po.imageResult.images[i];
      const response = await fetch(
        `${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-warehouse/image-product-maps/${po.imageResult.id}/${image}.jpg`
      );
      const blob = await response.blob();
      zip.add(`Picture-${i + 1}.jpg`, blob);
    }
    zip.download(props.name);
    zip.clear();
  }

  useEffect(() => {
    if (selectedImages.length > 0) {
      downloadButton.current?.animate([{ transform: 'scale(1.5)' }, { transform: 'scale(1.0)' }], {
        duration: 400,
        easing: 'ease-out',
      });
    }
  }, [selectedImages.length]);

  useEffect(() => {
    if (gallery.length > 0) {
      setGalleryVisible(true);
    }
  }, [gallery]);

  if (loading)
    return (
      <Row>
        <Spin />
      </Row>
    );

  const Highlight = ({ text }: { text?: string }) => (
    <Highlighter
      highlightClassName={Css.Effects.Static.Font.Bolder}
      highlightTag="span"
      searchWords={searchTerms}
      autoEscape={true}
      textToHighlight={text ?? ''}
    />
  );

  return (
    <WithModal>
      {modal => {
        const requestBtn = (
          <Tooltip title="Request new pictures">
            <CameraOutlined
              className={activeRequests.length > 0 ? Css.Packs.Clickable.Disabled : Css.Packs.Clickable.Success}
              onMouseEnter={() => {
                if (activeRequests.length > 0) {
                  pendingTooltip.current();
                }
              }}
              onClick={() => {
                if (activeRequests.length === 0) {
                  const modalInstance = modal.info({
                    desktopWidth: '20%',
                    title: 'Request new pictures',
                    okText: 'Save',
                    closable: true,
                    cancelText: 'Close',
                    okButtonProps: { style: { display: 'none' } },
                    cancelButtonProps: { style: { display: 'none' } },
                    icon: <CameraOutlined />,
                    content: (
                      <RequestPictures
                        availableSalesOrders={props.availableSalesOrders}
                        barcodes={[
                          {
                            itemNumber: props.id,
                            variantNumber: variantNumber,
                            poNumber: null,
                            original: `91${props.id}${variantNumber}`,
                            serialNumber: null,
                          },
                        ]}
                        variants={[props.variant]}
                        wineName={props.name}
                        wineVintage={props.year}
                        onSentRequest={() => {
                          DoRefetch();
                          modalInstance.destroy();
                        }}
                      />
                    ),
                  });
                }
              }}
            />
          </Tooltip>
        );

        return (
          <Row>
            <Col span={24}>
              <Row>
                <Text copyable={{ text: `${window.location.origin}/trading/instock/${props.id}/${variantNumber}/yes` }}>
                  Link to this variant
                </Text>
              </Row>
              <Row align="stretch" justify="start" className={style.search}>
                <Col flex={1}>
                  <Searcher
                    onSearchTerms={setSearchTerms}
                    disableAutoFocusOnWriting
                    queryParameterName=""
                    placeholder="Search for a specific date or purchase order"
                    buttonsLeft={[
                      requestBtn,
                      <div
                        ref={downloadButton}
                        className={selectedImages.length ? Css.Packs.Clickable.Primary : Css.Packs.Clickable.Disabled}
                        onClick={downloadImages}
                      >
                        <DownloadOutlined /> {!!selectedImages.length && selectedImages.length}
                      </div>,
                    ]}
                    buttonsRight={[
                      loading || refetching ? (
                        <SyncOutlined style={{ width: 25 }} spin />
                      ) : (
                        <Tooltip title="Refresh list">
                          <SyncOutlined
                            className={Css.Packs.Clickable.Primary}
                            onClick={() => {
                              if (data) {
                                DisableCase(data);
                              }
                              DoRefetch();
                            }}
                          />
                        </Tooltip>
                      ),
                    ]}
                  />
                </Col>
              </Row>
              <Row align="middle" justify="start" className={style.row}>
                <Col flex={1}>
                  {(filteredData?.length === 0 || !filteredData) && <Empty />}
                  {filteredData?.map((po, index) => {
                    const selectedImagesInPo = po.imageResult?.images?.reduce((prev: number, next: string) => {
                      return prev + (selectedImages.some(x => x.includes(next)) ? 1 : 0);
                    }, 0);
                    const opacity = 1 - index * 0.06;

                    return (
                      <Row align="middle" justify="start" key={po.imageResult.id} className={style.row}>
                        <Col flex={1} className={style.row}>
                          <Tooltip placement="leftTop" visible={index === 0 && showGuide(EmailDraftGuide.SelectImages)} title="Fold out">
                            <Collapse className={style.rowHighlight}>
                              <Panel
                                style={{ opacity: opacity < 0.5 ? (0.5).toString() : opacity.toString() }}
                                className={style.row + ' ' + (activeRequests.includes(po) ? style.panelHeaderPending : '')}
                                header={
                                  <>
                                    <Row justify="space-between">
                                      <Col>
                                        <Space>
                                          {activeRequests.includes(po) ? (
                                            <LoadingOutlined />
                                          ) : (
                                            <CameraOutlined
                                              className={Css.Packs.Clickable.Success}
                                              onClick={evt => {
                                                evt.preventDefault();
                                                evt.stopPropagation();
                                                const modalInstance = modal.info({
                                                  desktopWidth: '20%',
                                                  title: 'Request new pictures',
                                                  className: 'modalNoButtons',
                                                  content: (
                                                    <RequestPictures
                                                      availableSalesOrders={props.availableSalesOrders}
                                                      barcodes={po.imageResult.productIds}
                                                      variants={[]}
                                                      wineName={props.name}
                                                      wineVintage={props.year}
                                                      onSentRequest={() => {
                                                        DoRefetch();
                                                        modalInstance.destroy();
                                                      }}
                                                    />
                                                  ),
                                                });
                                              }}
                                            />
                                          )}
                                          <Highlight
                                            text={
                                              new Date(po.imageResult.createdAt.date).toDateString() +
                                              ' ' +
                                              new Date(po.imageResult.createdAt.date).toLocaleTimeString()
                                            }
                                          />
                                        </Space>
                                      </Col>

                                      {!!selectedImagesInPo && <Col>{selectedImagesInPo} selected</Col>}
                                      {!po.imageResult.images?.length && (
                                        <Col>
                                          <TriggerableTooltip
                                            placement="left"
                                            triggerDuration={3000}
                                            triggerFunction={pendingTooltip}
                                            title={`A request for new images has already been made for this wine. It was made by ${
                                              activeRequests[0]?.imageResult.author ?? ''
                                            }`}
                                          >
                                            <Tag color="gold">Pending</Tag>
                                          </TriggerableTooltip>
                                        </Col>
                                      )}
                                      <Col>
                                        <DeleteCaseButton caseId={po.imageResult.id} refresh={DoRefetch} />
                                      </Col>
                                    </Row>
                                  </>
                                }
                                key={po.imageResult.id}
                                forceRender
                              >
                                {(po.imageResult.author || !!po.imageResult.productIds.filter(x => x.poNumber).length) && (
                                  <>
                                    {!!po.imageResult.productIds.filter(x => x.poNumber).length && (
                                      <Row>
                                        <Highlight
                                          text={
                                            'Purchase orders - ' +
                                            po.imageResult.productIds
                                              .filter(x => x.poNumber)
                                              .map(x => x.poNumber)
                                              .join(', ')
                                          }
                                        />
                                      </Row>
                                    )}
                                    {!!po.imageResult.author && (
                                      <Row>
                                        <Highlight text={`${po.imageResult.author} - ${po.imageResult.message}`} />
                                      </Row>
                                    )}
                                    <Divider style={{ margin: '6px 0' }} />
                                  </>
                                )}
                                {!po.imageResult.images?.length ? (
                                  <Row align="middle" justify="center">
                                    <Col>
                                      <Empty description="No images have been taken yet" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </Col>
                                  </Row>
                                ) : (
                                  <>
                                    <Row align="middle" justify="start">
                                      {po.imageResult.images.map((image, imageIdx) => {
                                        const imgKey = `${po.imageResult.id}/${image}.jpg`;
                                        const isSelected = selectedImages.includes(imgKey);

                                        return (
                                          <Col
                                            key={image}
                                            className={
                                              style.imageThumbnailContainer +
                                              ` ${selectedImages.includes(imgKey) && style.selected}` +
                                              Css.Effects.Transition.Normal.AnticipateOvershoot
                                            }
                                          >
                                            <Tooltip
                                              visible={imageIdx === 0 && showGuide(EmailDraftGuide.SelectImages)}
                                              placement="left"
                                              title="Select the images you want in your email draft"
                                            >
                                              <img
                                                alt=""
                                                src={`${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-warehouse/image-product-maps/${imgKey}`}
                                                className={`${style.image}`}
                                              />
                                            </Tooltip>
                                            <Row
                                              style={{
                                                position: 'absolute',
                                                left: 0,
                                                top: 0,
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: '#00000050',
                                              }}
                                              className={
                                                Css.Effects.Static.Opacity.Opacity0 +
                                                Css.Effects.Hover.Opacity.Opacity100 +
                                                Css.Effects.Transition.Quick.Linear
                                              }
                                              align="middle"
                                              justify="center"
                                              onClick={evt => {
                                                if (evt.ctrlKey) {
                                                  if (isSelected) {
                                                    setSelectedImages(selectedImages.filter(x => x !== imgKey));
                                                  } else {
                                                    setSelectedImages([...selectedImages, imgKey]);
                                                    completeStep(EmailDraftGuide.SelectImages);
                                                  }
                                                }
                                              }}
                                            >
                                              <Col>
                                                <Button
                                                  type="link"
                                                  className={
                                                    style.clickableTextInImage +
                                                    Css.Effects.Static.Color.White +
                                                    Css.Packs.Clickable.Subtle +
                                                    Css.Effects.Focus.Color.White +
                                                    Css.Effects.Hover.Color.White
                                                  }
                                                  onClick={evt => {
                                                    if (!evt.ctrlKey) {
                                                      showGallery(po, image);
                                                    }
                                                  }}
                                                >
                                                  View
                                                </Button>
                                                <br />
                                                <br />

                                                <Button
                                                  type="link"
                                                  className={
                                                    style.clickableTextInImage +
                                                    Css.Effects.Static.Color.White +
                                                    Css.Packs.Clickable.Subtle +
                                                    Css.Effects.Focus.Color.White +
                                                    (isSelected ? Css.Effects.Hover.Color.Error : Css.Effects.Hover.Color.Success)
                                                  }
                                                  onClick={evt => {
                                                    if (!evt.ctrlKey) {
                                                      if (isSelected) {
                                                        setSelectedImages(selectedImages.filter(x => x !== imgKey));
                                                      } else {
                                                        setSelectedImages([...selectedImages, imgKey]);
                                                        completeStep(EmailDraftGuide.SelectImages);
                                                      }
                                                    }
                                                  }}
                                                >
                                                  {isSelected ? 'Unselect' : 'Select'}
                                                </Button>
                                              </Col>
                                            </Row>
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                    <Divider style={{ margin: '6px 0' }} />
                                    <div>
                                      <Space align="start">
                                        <Button onClick={() => downloadAllImages(po)}>Download all</Button>
                                        <Tooltip visible={showGuide(EmailDraftGuide.CreateDraft)} title="Click to create a draft">
                                          <CreateDraftEmailButton
                                            payload={po}
                                            selectedImages={selectedImages.map(url => url.split('/')[1].replace(/\.[a-zA-Z0-9]+/g, ''))}
                                            onDraftCreated={() => completeStep(EmailDraftGuide.CreateDraft)}
                                            onDraftCreationFailed={resetGuide}
                                          />
                                        </Tooltip>
                                      </Space>
                                    </div>
                                  </>
                                )}
                              </Panel>
                            </Collapse>
                          </Tooltip>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
              <Row>
                <Viewer
                  visible={galleryVisible}
                  onClose={() => setGalleryVisible(false)}
                  onMaskClick={() => setGalleryVisible(false)}
                  zoomSpeed={0.15}
                  images={gallery}
                  activeIndex={galleryIndex}
                />
              </Row>
            </Col>
          </Row>
        );
      }}
    </WithModal>
  );
}

function DeleteCaseButton(props: { caseId: string; refresh: () => void }) {
  const { mutate } = useDeleteCase();

  async function deleteCaseButtonClick(event: React.MouseEvent<HTMLElement, MouseEvent> | undefined) {
    let loadingIsDone = message.loading('Deleting...', 0);
    event?.stopPropagation();
    try {
      await deleteCase(props.caseId);
      notification.success({ message: 'Succesfully deleted case' });
    } catch (error) {
      notification.error({ message: 'Oh no!', description: error?.message ?? error });
    } finally {
      loadingIsDone();
    }
  }

  async function deleteCase(caseId: string) {
    if (caseId) {
      await mutate({
        variables: {
          input: {
            caseId: caseId,
          },
        },
      }).then(props.refresh);
    }
  }

  return (
    <Tooltip title="Delete images" placement="left">
      <Popconfirm title="Are you sure you wish to delete the image case?" okText="Yes" onConfirm={deleteCaseButtonClick}>
        <DeleteOutlined
          onClick={event => {
            // Used such that the cascade panels does not open and close
            event.stopPropagation();
          }}
        />
      </Popconfirm>
    </Tooltip>
  );
}

function CreateDraftEmailButton(props: {
  payload: StockWineImagePayload;
  selectedImages: string[];
  onDraftCreated: () => void;
  onDraftCreationFailed: () => void;
}) {
  const authToken = useAuthToken();
  const { createEmailDraft, loading: loadingEmail } = useCreateDraftEmail(authToken!);
  const disabled =
    loadingEmail ||
    (props.payload.imageResult.images.length > 10 && (props.selectedImages.length === 0 || props.selectedImages.length > 6));
  const tooltip = loadingEmail
    ? 'Please wait while the draft is being created'
    : 'Draft emails may at most contain 6 images, please select some.';

  return (
    <Tooltip title={tooltip} visible={disabled ? undefined : false}>
      <Button
        loading={loadingEmail}
        disabled={disabled}
        onClick={() =>
          createEmailDraft(
            props.payload.imageResult.id,
            `${props.payload.poResult.item.name} ${props.payload.poResult.item.year}`,
            props.selectedImages.length > 0 ? props.selectedImages : undefined
          )
            .then(() => {
              message.success('Draft email was created');
              props.onDraftCreated();
            })
            .catch(e => {
              notification.error({
                message: 'Draft email could not be created',
                description: 'Please try deselect an image and try again',
              });
              props.onDraftCreationFailed();
            })
        }
      >
        Create draft mail
      </Button>
    </Tooltip>
  );
}
