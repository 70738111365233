import '../../rarewine-library-frontend/extensions/ArrayExtensions';

import { IDictionary, StockWine } from './types';

const spiritTypes = [
  'brandy',
  'cognac',
  'fortified',
  'gin',
  'liqueur',
  'madeira',
  'rom',
  'rum',
  'spirit',
  'tequila',
  'vodka',
  'whiskies',
  'whisky',
];
const isSpirit = (wine: StockWine) => spiritTypes.includes(wine.Type);

export default class WineSorter {
  static _createSortingIndex = (list: string[], defaults: IDictionary<number>) => {
    if (!list) {
      return {};
    }

    let index = { ...defaults };
    let maxIndex = Math.max(...Object.values(defaults));
    if (!isFinite(maxIndex)) maxIndex = 0;

    for (const item of list) {
      if (index[item] === undefined) {
        if (item) {
          maxIndex++;
          index[item] = maxIndex;
        } else {
          // Sort empty strings last
          const EMPTY_STRING_SEARCH_PRIORITY = 1000;
          index[item] = EMPTY_STRING_SEARCH_PRIORITY;
        }
      }
    }

    return index;
  };

  static _getRegionIndex = (): IDictionary<number> => {
    return {
      burgundy: 0,
      bordeaux: 1,
      champagne: 2,
      rhone: 3,
      // italy
      tuscany: 0,
      piedmonte: 1,
      // usa
      california: 0,
      // spain
      'castilla y leon': 0,
      // germany
      mosel: 0,
      rheinhessen: 1,
      // australia
      'south australia': 0,
    };
  };

  static _serialize = (wine: StockWine, countryIndex: IDictionary<number>, regionIndex: IDictionary<number>) =>
    `${isSpirit(wine) ? 1 : 0} ${countryIndex[wine.Country.toLowerCase()]} ${regionIndex[wine.Region.toLowerCase()]} ${wine.Name} ${
      wine.Year
    }`;

  static sort = (wines: StockWine[]) => {
    const sortingIndex = WineSorter._createSortingIndex(
      wines.map(w => w.Country.toLowerCase()).unique(),
      {
        france: 0,
        italy: 1,
        usa: 2,
        spain: 3,
        germany: 4,
        australia: 5,
      }
    );
    const regionIndex = WineSorter._createSortingIndex(
      wines.map(w => w.Region.toLowerCase()).unique(),
      WineSorter._getRegionIndex()
    );

    return wines.sort((a, b) =>
      WineSorter._serialize(a, sortingIndex, regionIndex).localeCompare(WineSorter._serialize(b, sortingIndex, regionIndex))
    );
  };
}
