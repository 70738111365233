import { createEvent, useForceUpdateEvent } from '../trading-app/hooks/general/useForceUpdate';

const stateUpdatedEvent = createEvent();

function getLocalState<TData>(key: string) {
  const data = localStorage.getItem(key);

  return data !== null ? (JSON.parse(data) as TData) : null;
}

function setLocalState<TData>(key: string, data: TData) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function useLocalStorageManager() {
  const forceUpdate = useForceUpdateEvent(stateUpdatedEvent);

  return {
    setLocalState<TData>(key: string, newState: TData): void {
      setLocalState(key, newState);
      forceUpdate();
    },
    getLocalState,
  };
}

export default function useLocalStorage<TData>(key: string, initialState: TData): [TData, (newState: TData) => void] {
  const forceUpdate = useForceUpdateEvent(stateUpdatedEvent);

  function setState(newState: TData): void {
    setLocalState(key, newState);
    forceUpdate();
  }

  let state = getLocalState<TData>(key);

  if (state === null || state === undefined) {
    state = initialState;
    setLocalState(key, initialState);
  }

  return [state, setState];
}
