import { Button, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import React from 'react';

import { OverlayLoadingComponent } from '../../../../components/overlay-loading/overlay-loading';
import useImageProductMaps from '../../hooks/useImageProductMaps';

export default function ImageBrowser() {
  const { data, loading } = useImageProductMaps();

  return (
    <OverlayLoadingComponent loading={loading}>
      <Table<ProductImageMapRequest> dataSource={data} rowKey={row => row.id}>
        <Column<ProductImageMapRequest>
          key="creationDate"
          dataIndex="creationDate"
          title="CreationDate"
          render={x => new Date(x).toLocaleString()}
        />
        <Column<ProductImageMapRequest>
          key="productIds"
          dataIndex="productIds"
          title="ProductIds"
          render={(_, row) => row.productIds.join(', ')}
        />
        <Column<ProductImageMapRequest>
          key="imageIds"
          dataIndex="imageIds"
          title="ImageIds"
          render={(_, row) =>
            row.imageIds?.map((x, idx) => {
              return (
                <Button
                  key={x}
                  type="link"
                  size="small"
                  target="_blank"
                  href={`${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-warehouse/image-product-maps/${row.id}/${x}.jpg`}
                >
                  {idx}
                </Button>
              );
            })
          }
        />
        <Column<ProductImageMapRequest> key="author" dataIndex="author" title="Author" />
        <Column<ProductImageMapRequest> key="message" dataIndex="message" title="Message" />
        <Column<ProductImageMapRequest> key="location" dataIndex="location" title="Location" />
      </Table>
    </OverlayLoadingComponent>
  );
}
