import * as signalR from '@microsoft/signalr';
import { message, notification } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';

import UserAvatar from '../../../../components/user-avatar/user-avatar';
import useInterval from '../../hooks/general/useInterval';
import useRefetchQueries from '../../hooks/general/useRefetchQueries';
import useSetActiveUser from '../../hooks/general/useSetActiveUser';
import useSpeechSynthesis from '../../hooks/general/useSpeech';
import useAddNotification from '../../hooks/notifications/useAddNotification';
import useUser from '../../hooks/user/useUser';
import { ApplicationInsightsContext } from '../application-insights/ApplicationInsights';

type EventContextArgs = {
  connection: null | signalR.HubConnection;
};

export const EventsContext = React.createContext<EventContextArgs>({
  connection: null,
});

const EventsProvider: React.FunctionComponent = props => {
  const [connection] = useState(
    new signalR.HubConnectionBuilder()
      .withUrl(window.ENVARGS.REACT_APP_SIGNALR!)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          return 10000;
        },
      })
      .build()
  );

  const [connected, setConnected] = useState(false);
  const [userRegistered, setUserRegistered] = useState(false);
  const { data: user } = useUser();
  const userData = useRef(user);
  const { mutate } = useAddNotification();
  const { mutate: setActiveUser } = useSetActiveUser();
  const { trigger: triggerRefetch } = useRefetchQueries();
  const { speak } = useSpeechSynthesis();

  const appInsights = useContext(ApplicationInsightsContext);

  useInterval(
    () => {
      if (connected && user && connection.state === 'Connected') {
        connection.send('OnRegisterUser', user.id);
        userData.current = user;
        setUserRegistered(true);
      }
    },
    userRegistered ? null : 500
  );

  useEffect(() => {}, [userRegistered]);

  const handleNotification = (notification: RWNotification) => {
    mutate({
      variables: {
        input: {
          id: notification.id,
          userId: notification.userId,
          createdAt: {
            date: notification.createdAt.date,
            epoch: notification.createdAt.epoch,
          },
          modifiedAt: {
            date: notification.modifiedAt.date,
            epoch: notification.modifiedAt.epoch,
          },
          isRead: notification.isRead,
          context: notification.context,
          event: notification.event,
          parameters: JSON.stringify(notification.parameters),
        },
      },
    });
  };

  if (!connected && connection.state === 'Disconnected') {
    try {
      connection.start().finally(() => {
        message.info('Connected to real-time updates');
        setConnected(true);
      });
      connection.onreconnected(() => message.info('Reconnected to real-time updates'));
      connection.onreconnecting(() => {
        message.info('Reconnecting to real-time updates');
        setUserRegistered(false);
      });
      connection.onclose(() => message.info('Lost connection to real-time updates'));

      //Hook up events
      connection.on('onOfferAdded', (notification: RWNotification) => {
        handleNotification(notification);
      });

      connection.on('offerRejected', (notification: RWNotification) => {
        handleNotification(notification);
      });

      connection.on('refetch', (queries: string[]) => {
        triggerRefetch(queries);
      });

      connection.on('onActiveUserUpdate', (email: string, location: string, firstName: string, lastName: string) => {
        setActiveUser({
          variables: {
            input: {
              email: email,
              location: location,
              lastUpdated: Date.now(),
              firstName: firstName,
              lastName: lastName,
            },
          },
        });
      });

      connection.on('onSendUserMessage', (fromName: string, fromEmail: string, toEmail: string, message: string) => {
        if (userData.current?.email === toEmail) {
          speak({
            text: `${fromName} says ${message}`,
          });

          notification.open({
            message: (
              <>
                <UserAvatar key={fromEmail} email={fromEmail} size={64} />
                <span> {fromName}</span>
              </>
            ),
            description: message,
            duration: 10,
          });
        }
      });
    } catch (err) {
      if (err instanceof Error) {
        appInsights.applicationInsights?.trackException({
          exception: err,
          properties: {
            auth0id: user?.auth0id,
            email: user?.email,
            userRegistered: userRegistered,
          },
        });
      }
      // eslint-disable-next-line
      console.error('SignalR error: ', err);
    }
  }

  return <EventsContext.Provider value={{ connection: connection }}>{props.children}</EventsContext.Provider>;
};

export default EventsProvider;
