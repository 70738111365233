import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import getData from '../general/useGetApolloData';

interface UseNotificationsArgs {
  userId: string | undefined;
}

export const notificationsQuery = gql`
  query GetNotifications($userId: string!) {
    notifications(userId: $userId) @rest(type: "RWNotification", path: "notification/getLatest/{args.userId}", method: "GET") {
      id
      userId
      context
      event
      isRead
      parameters
      createdAt
    }
  }
`;

const useNotifications = (args: UseNotificationsArgs) => {
  const { data, loading, error } = useQuery<ApolloDataWrapper<RWNotification[]>>(notificationsQuery, {
    variables: args,
    skip: args.userId === undefined,
  });

  return { data: getData(data), loading, error };
};

export default useNotifications;
