import { useHistory } from 'react-router-dom';

import useEvents from '../../../apps/trading-app/hooks/general/useEvents';
import useUser from '../../../apps/trading-app/hooks/user/useUser';

const useRWHistory = () => {
  const _history = useHistory();
  const { connection } = useEvents();

  const { data: user } = useUser();

  const history = {
    push: (path: string) => {
      if (connection?.connectionId) connection?.invoke('OnActiveUserUpdate', user?.email, path, user?.firstName, user?.lastName);
      _history.push(path);
    },
    notifyLocation: () => {
      if (connection?.connectionId)
        connection?.invoke('OnActiveUserUpdate', user?.email, _history.location.pathname, user?.firstName, user?.lastName);
    },
    current: _history.location.pathname,
  };

  return history;
};

export default useRWHistory;
