import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import getData from '../general/useGetApolloData';

const Query = gql`
  query GetLwinState {
    data @rest(type: "LwinState", path: "wine/state", method: "GET") {
      lastSynchronizationDate
      syncInProgress
      winesToSync
      winesSynced
      estimatedSyncTimeRemaining
    }
  }
`;

export type LwinState = {
  lastSynchronizationDate: Date;
  syncInProgress: boolean;
  winesToSync: number;
  winesSynced: number;
  estimatedSyncTimeRemaining: string;
};

export default (pollInterval: number = 0) => {
  const { data, loading, error } = useQuery<ApolloDataWrapper<LwinState>>(Query, {
    pollInterval: pollInterval,
  });

  return { data: getData(data), loading, error };
};
