import { Layout, Row, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import useApps from '../../apps/apps';
import getApiVersion from '../../apps/trading-app/hooks/meta/GetApiVersion';
import { useAuth0 } from '../../helpers/useAuth0';
import useFullScreenMode from '../hooks/useFullScreenMode';
import { RefreshReminder } from '../refresh-reminder/refresh-reminder';
import AppHostAlerts from './AppHostAlerts/AppHostAlerts';
import AppHostHeader from './AppHostHeader/AppHostHeader';
import AppHostSideMenu from './AppHostSideMenu/AppHostSideMenu';
import NotFound from './NotFound';
import Unauthorized from './Unauthorized';

const { Content, Footer } = Layout;

export default function AppHost() {
  const isDesktop = useBreakpoint().lg;
  const [siderCollapsed, setSiderCollapsed] = useState(!isDesktop);
  const [fullScreen] = useFullScreenMode();
  const { hasRoles } = useAuth0();
  const apiVersion = getApiVersion();
  const { currentApp, currentRoute } = useApps();

  useEffect(() => setSiderCollapsed(!isDesktop), [isDesktop]);

  return (
    <>
      <Layout hasSider={true} style={{ minHeight: '100%' }} tabIndex={0}>
        <AppHostSideMenu siderCollapsed={siderCollapsed} onSiderToggled={setSiderCollapsed} />
        <Layout>
          <AppHostHeader onHamburgerClicked={() => setSiderCollapsed(false)} />
          <Content
            style={{
              padding: fullScreen || !isDesktop ? undefined : '32px',
              backgroundColor: 'transparent',
            }}
          >
            <AppHostAlerts />
            <RefreshReminder />
            <Switch>
              {currentRoute && (
                <Route
                  key={currentRoute.path}
                  exact={true}
                  path={`/${currentApp?.baseRoute}/${currentRoute.path}`}
                  render={props => {
                    if (currentRoute.requiredRoles && !hasRoles(currentRoute.requiredRoles)) {
                      return <Unauthorized />;
                    }

                    return currentRoute.render!(props);
                  }}
                />
              )}
              <Route exact={true} path={`/${currentApp?.baseRoute}`}>
                <Row justify="center">
                  <Typography.Title level={2}>Welcome to {currentApp?.name}</Typography.Title>
                </Row>
              </Route>
              <Route path="*">
                <NotFound basePath={currentApp.baseRoute} />
              </Route>
            </Switch>
            <div style={{ display: 'none' }}>
              {currentApp.mountedServices?.map((x, index) => (
                <div key={index}>{x}</div>
              ))}
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            Rare Wine &copy; 2019 - version: {process.env.REACT_APP_VERSION}
            <br />
            Rare Wine Api &copy; 2019 - version: {apiVersion}
          </Footer>
        </Layout>
      </Layout>
    </>
  );
}
