import { Table } from 'antd';
import React, { useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { FormattedDate } from 'react-intl';

import { OverlayLoadingComponent } from '../../../../../components/overlay-loading/overlay-loading';
import Searcher from '../../../../../rarewine-library-frontend/components/searcher/seacher';
import useFilterer from '../../../../../rarewine-library-frontend/hooks/useFilterer';
import useGetPendingPhotos from '../../../hooks/warehouse/usePendingPictureList';

import style from '../instock.module.scss';

interface PendingImageFormFilteredData {
  pending: PendingPhotoInfo | undefined;
  wineInfo: InStockWine | undefined;
}

export default function PendingImageForm(props: { StockListWines: InStockWine[] }) {
  const { data, loading } = useGetPendingPhotos();
  const [searchTerms, setSearchTerms] = useState<string[]>([]);

  const stockListMap = useMemo(() => props.StockListWines.toObject(w => w.wine.id), [props.StockListWines]);

  const wineData: PendingImageFormFilteredData[] | undefined = useMemo(
    () =>
      data?.map(x => {
        const productId = x.productIds.first()?.itemNumber;
        return {
          pending: x,
          wineInfo: productId ? stockListMap[productId] : undefined,
        };
      }),
    [stockListMap, data]
  );

  function uniqueFilter(value: any, index: any, arr: any[]) {
    return arr.indexOf(value) === index;
  }

  const filteredData = useFilterer(
    wineData,
    searchTerms,
    w =>
      `${w.pending?.productIds?.first()?.itemNumber ?? ''} ${w.wineInfo?.wine.producer} ${w.wineInfo?.wine.name}
            ${w.wineInfo?.wine.bottleSize} (${w.wineInfo?.wine.bottleSizeNumerical}ml) ${w.wineInfo?.variants
        .map(x => x.packagingString)
        .join(' ')}
            ${w.wineInfo?.variants.map(x => x.packagingSize).join(' ')} ${w.wineInfo?.wine.year} ${w.pending?.createdAt.date}`
  );

  const Highlight = ({ text }: { text?: string }) => (
    <Highlighter
      highlightClassName={style.highlight}
      highlightTag="span"
      searchWords={searchTerms}
      autoEscape={true}
      textToHighlight={text ?? ''}
    />
  );

  return (
    <OverlayLoadingComponent loading={loading}>
      <Searcher queryParameterName="itemNumber" onSearchTerms={setSearchTerms} />

      <Table<PendingImageFormFilteredData> dataSource={filteredData} rowKey={row => row!.pending!.id}>
        <Table.Column<PendingImageFormFilteredData>
          key="itemNumber"
          title="Item Number"
          render={(_, row) => {
            return <Highlight text={row.pending?.productIds[0].itemNumber} />;
          }}
        />

        <Table.Column<PendingImageFormFilteredData>
          key="Producer"
          title="Producer"
          render={(_, row) => {
            return <Highlight text={row.wineInfo?.wine.producer} />;
          }}
        />

        <Table.Column<PendingImageFormFilteredData>
          key="name"
          title="Name"
          render={(_, row) => {
            return <Highlight text={row.wineInfo?.wine.name} />;
          }}
        />

        <Table.Column<PendingImageFormFilteredData>
          key="bottle size"
          title="Bottle Size"
          render={(_, row) => {
            return <Highlight text={`${row.wineInfo?.wine.bottleSize} (${row.wineInfo?.wine.bottleSizeNumerical}ml)`} />;
          }}
        />

        <Table.Column<PendingImageFormFilteredData>
          key="BoxType"
          title="BoxType"
          render={(_, row) => {
            return (
              <Highlight
                text={row.wineInfo?.variants
                  .map(x => x.packagingString)
                  .filter(uniqueFilter)
                  .sort()
                  .join(', ')}
              />
            );
          }}
        />

        <Table.Column<PendingImageFormFilteredData>
          key="BoxSizes"
          title="Box sizes"
          render={(_, row) => {
            return (
              <Highlight
                text={row.wineInfo?.variants
                  .map(x => x.packagingSize || '-')
                  .filter(uniqueFilter)
                  .sort()
                  .join(', ')}
              />
            );
          }}
        />
        <Table.Column<PendingImageFormFilteredData>
          key="year"
          title="Year"
          render={(_, row) => {
            return <Highlight text={'' + row.wineInfo?.wine.year || 'NV'} />;
          }}
        />

        <Table.Column<PendingImageFormFilteredData>
          key="conditinos"
          title="Conditions"
          render={(_, row) => {
            return (
              <Highlight
                text={row.wineInfo?.variants
                  .map(x => x.wineCondition)
                  .filter(uniqueFilter)
                  .sort()
                  .join(', ')}
              />
            );
          }}
        />

        <Table.Column<PendingImageFormFilteredData>
          key="request day"
          title="Request day"
          render={(_, row) => {
            return <FormattedDate value={row.pending?.createdAt.date} />;
          }}
        />
      </Table>
    </OverlayLoadingComponent>
  );
}
