import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import getData from '../general/useGetApolloData';

type AddNotificationArgs = {
  input: RWNotification;
};

const mutation = gql`
  mutation addNotification($input: AddNotificationArgs!) {
    addNotification(input: $input) @client {
      event
      isRead
      parameters
      context
      id
      userId
    }
  }
`;

const useAddNotification = (options?: MutationOptions) => {
  const [mutate, { loading, error, data }] = useMutation<ApolloDataWrapper<RWNotification>, AddNotificationArgs>(mutation, options);

  return { mutate, loading, error, data: getData(data) };
};

export default useAddNotification;
