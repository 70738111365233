import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql.macro';

import getData from '../../../trading-app/hooks/general/useGetApolloData';

const query = gql`
  query GetPendingPhotos {
    GetPendingPhotos @rest(type: "PendingPhotoInfo", path: "offer/GetPendingPhotos", method: "GET") {
      id
      productIds @type(name: "ProductIds") {
        original
        itemNumber
        variantNumber
      }
      imageIds
      author
      createdAt
      message
      location
    }
  }
`;

export default function useGetPendingPhotos() {
  const { data, loading, error } = useQuery<ApolloDataWrapper<PendingPhotoInfo[]>>(query);

  return { data: getData<PendingPhotoInfo[]>(data), loading, error };
}
