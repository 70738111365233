import { Spin } from 'antd';
import React, { PropsWithChildren } from 'react';

const OverlayLoading = (props: { overrideText?: string }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: `100%`,
        height: `100%`,
        top: 0,
        backgroundColor: `rgba(255, 255, 255, 0.5)`,
        zIndex: 100,
        position: 'absolute',
      }}
    >
      <Spin size="large" />
      <h1>
        <i>{props.overrideText ?? 'Loading..'}</i>
      </h1>
    </div>
  );
};

export default OverlayLoading;

export function OverlayLoadingComponent(props: PropsWithChildren<{ loading: boolean }>) {
  if (!props.loading) return <>{props.children}</>;

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      {props.children}
      <OverlayLoading />
    </div>
  );
}
