import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import getData from '../general/useGetApolloData';

export const query = gql`
  query useStockWineImages($itemNumber: String!, $variantNumber: String!) {
    GetStockWineImages(itemNumber: $itemNumber, variantNumber: $variantNumber)
      @rest(type: "StockWineImagePayload", path: "warehouse/getImagesForWine/{args.itemNumber}/{args.variantNumber}", method: "GET") {
      poResult @type(name: "StockPo") {
        item @type(name: "Item") {
          id
          lwin
          year
          name
          namePart1
          namePart2
          namePart3
          description
          remark
          bottleSizeMl
          packagingSize
          packaging
          wineCondition
          wineConditionString
          labelCondition
          labelConditionString
          warehousePlacement
        }
        purchaseLinesForItem @type(name: "PuchaseLines") {
          itemId
          quantity
          quantityOutstanding
          warehousePlacement
        }
        salesLinesForItem @type(name: "PurchaseSalesLines") {
          itemId
          quantity
          quantityOutstanding
          warehousePlacement
        }
      }
      imageResult @type(name: "StockWineImage") {
        id
        active
        productIds
        images
        author
        disabled
        message
        location
        createdAt
        modifiedAt
      }
    }
  }
`;

const mutation = gql`
  mutation RequestWineImages($input: RequestWineImageInput!) {
    requestWineImages(input: $input) @rest(type: "RequestWineImages", path: "warehouse/case", method: "PUT") {
      id
      active
      productIds
      imageIds
      author
      disabled
      creationDate
      message
      location
    }
  }
`;

export function useStockWineImages(itemNumber: string, variantNumber: string): ApolloReturnValue<StockWineImagePayload[]> {
  const { data, loading, error, refetch, networkStatus } = useQuery<ApolloDataWrapper<StockWineImagePayload[]>>(query, {
    variables: { itemNumber: itemNumber, variantNumber: variantNumber },
  });

  return { data: getData(data), loading: loading || networkStatus === 4, error, refetch };
}

export function useRequestNewWineImages(options: MutationOptions) {
  const [mutate, { loading, error, data }] = useMutation<ApolloDataWrapper<RequestWineImageInput>, ApolloInput<RequestWineImageInput>>(
    mutation,
    options
  );

  return { mutate, loading, error, data: getData(data) };
}
