import { Empty } from 'antd';
import React from 'react';

import useUser from '../../apps/trading-app/hooks/user/useUser';
import useUserAuth from '../../apps/trading-app/hooks/user/useUserAuth';
import SetupAccount from '../account/setupAccount';
import SplashScreen from '../splash-screen/splash-screen';
import ConnectionError from './connection-error';

const AuthGate: React.FunctionComponent<any> = props => {
  const { userId } = useUserAuth();
  const { data: user, error, loading: userLoading } = useUser();

  // login with auth0
  if (!userId || userLoading) {
    return <SplashScreen />;
  }

  if (error?.message === 'Network error: Failed to fetch') {
    return <ConnectionError />;
  }

  // create user if there is none
  if (!user) {
    return <SetupAccount />;
  }

  return props.children || <Empty></Empty>;
};

export default AuthGate;
