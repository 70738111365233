import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const CustomerMakeInvestCustomerMutation = gql`
  mutation SyncStocklist($input: InputArgs!) {
    SyncStocklist(input: $input) @rest(type: "boolean", path: "warehouse/updateStocklist", method: "POST") {
      success
    }
  }
`;

export default function useSyncStocklist() {
  const [mutate, { loading, error }] = useMutation<ApolloDataWrapper<boolean>, ApolloInput<{}>>(CustomerMakeInvestCustomerMutation);

  return { mutate: mutate, loading, error };
}
