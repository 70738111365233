import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import getData from '../general/useGetApolloData';

export type PatchLwinResult = {
  success: boolean;
};

const Mutation = gql`
  mutation PatchLwin {
    data(input: {}) @rest(type: "PatchLwinResult", path: "wine/patchLatestLwin", method: "POST") {
      success
    }
  }
`;

const usePatchLwin = () => {
  const [mutate, { loading, data }] = useMutation<ApolloDataWrapper<PatchLwinResult>>(Mutation);

  return { mutate, loading, data: getData(data) };
};

export default usePatchLwin;
