/* eslint-disable */
// According to ts, array prototypes shouldn't be overwritten, therefore =>

export type IDictionary<TValue> = Record<string, TValue>;

declare global {
  interface Array<T> {
    /**
     * Groups the array by a value selected by the selector.
     * @param selector A function to select the property by which to group
     */
    groupBy<T2>(selector: (item: T) => T2): IDictionary<T[]>;
  }
}
Array.prototype.groupBy = function (selector: (item: any) => any) {
  const accumulator: any = {};

  for (const item of this) {
    const key = selector(item);

    if (!accumulator[key]) accumulator[key] = [];

    accumulator[key].push(item);
  }

  return accumulator;
};

declare global {
  interface Array<T> {
    /**
     * Groups the array by a value selected by the selector, mapping the array's value by the given valueSelector.
     * @param selector A function to select the property by which to group
     * @param valueSelector A mapping function on the array's items.
     */
    groupByAndMap<T2, T3>(selector: (item: T) => T2, valueSelector: (item: T) => T3): IDictionary<T3[]>;
  }
}

Array.prototype.groupByAndMap = function <T, T2, T3>(selector: (item: T) => T2, valueSelector: (item: T) => T3) {
  const accumulator: any = {};

  for (const item of this) {
    const key = selector(item);

    if (!accumulator[key]) accumulator[key] = [];

    accumulator[key].push(valueSelector(item));
  }

  return accumulator;
};

declare global {
  interface Array<T> {
    /**
     * Sums the values of the array selected by the selector.
     * @param selector A function to select the number to sum by
     */
    sumBy(selector: (item: T) => number): number;
  }
}
Array.prototype.sumBy = function (selector: (item: any) => number) {
  return this.map(selector).reduce((a, b) => a + b, 0);
};

declare global {
  interface Array<T> {
    /**
     * Gets the first element in the array.
     */
    first(): T | undefined;
  }
}
Array.prototype.first = function () {
  return this[0];
};

declare global {
  interface Array<T> {
    /**
     * Gets the last element in the array.
     */
    last(): T | undefined;
  }
}
Array.prototype.last = function () {
  return this[this.length - 1];
};

declare global {
  interface Array<T> {
    /**
     * Gets the maximal valued element.
     */
    max(): T | undefined;
  }
}
Array.prototype.max = function () {
  let max = this.first();

  for (const next of this.slice(1)) {
    if (max === undefined || max < next) {
      max = next;
    }
  }

  return max;
};

declare global {
  interface Array<T> {
    /**
     * Gets the minimal valued element.
     */
    min(): T | undefined;
  }
}
Array.prototype.min = function () {
  let min = this.first();

  for (const next of this.slice(1)) {
    if (min === undefined || min > next) {
      min = next;
    }
  }

  return min;
};

declare global {
  interface Array<T> {
    /**
     * Gets the average number.
     */
    avg(): T | undefined;
  }
}
Array.prototype.avg = function () {
  if (!this.length) return undefined;

  const sum = this.sumBy(x => x);
  return sum / this.length;
};

function _compare(a: any, b: any): number {
  // a value trumps a non-value
  if (a === undefined || a === null) {
    if (b === undefined || b === null) {
      return 0;
    } else {
      return -1;
    }
  } else if (b === undefined || b === null) {
    return 1;
  }

  // if we're dealing with different types, try as strings
  if (typeof a !== typeof b) {
    return _compare(JSON.stringify(a), JSON.stringify(b));
  }

  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }

  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }

  if (typeof a === 'boolean' && typeof b === 'boolean') {
    return +a - +b;
  }

  const rawCompare = a - b;
  if (!Number.isNaN(rawCompare)) {
    return rawCompare;
  } else {
    return _compare(JSON.stringify(a), JSON.stringify(b));
  }
}
declare global {
  interface Array<T> {
    /**
     * Returns a new array, ordered ascendingly by the selected value.
     * @param selector A function to select the value by which to order.
     */
    orderBy<T2>(selector: (item: T) => T2): Array<T>;
  }
}
Array.prototype.orderBy = function (selector: (item: any) => any) {
  if (!this.length) return [];

  const ordered = [...this];

  ordered.sort((a, b) => _compare(selector(a), selector(b)));

  return ordered;
};

declare global {
  interface Array<T> {
    /**
     * Returns a new array, ordered descendingly by the selected value.
     * @param selector A function to select the value by which to order.
     */
    orderByDesc<T2>(selector: (item: T) => T2): Array<T>;
  }
}
Array.prototype.orderByDesc = function (selector: (item: any) => any) {
  if (!this.length) return [];

  const ordered = [...this];

  ordered.sort((a, b) => -_compare(selector(a), selector(b)));

  return ordered;
};

declare global {
  interface Array<T> {
    /**
     * Returns a new array, with duplicates deleted selected by the predicate.
     * @param selector A function to select the value by which to order.
     */
    uniqueBy<T2>(selector: (item: T) => T2): Array<T>;
  }
}
Array.prototype.uniqueBy = function (selector: (item: any) => any) {
  return [...new Set(this.map(selector))].map(u => this.find(x => selector(x) === u)!);
};

declare global {
  interface Array<T> {
    /**
     * Returns a new array, with duplicates deleted selected by the array element.
     */
    unique: () => Array<T>;
  }
}
Array.prototype.unique = function () {
  return this.uniqueBy(x => x);
};

declare global {
  interface Array<T> {
    /**
     * Returns a new object, grouped by the selected value.
     * @param selector A function to select the value by which to group.
     */
    toObject(keySelector: (item: T) => string | number): IDictionary<T>;
  }
}
Array.prototype.toObject = function (keySelector: (item: any) => string | number): { [key: string]: any } {
  return this.reduce((acc, x) => {
    acc[keySelector(x)] = x;
    return acc;
  }, {});
};

declare global {
  /**
   * Checks if the content of two arrays are the same. This function returns true,
   * if the contents are equal, and false if not.
   * @param other The array to compare the contents with.
   * @param strict If set to true, this will enforce strict ordering on the arrays.
   * I.e. ['a', 'b'].contentEquals(['b', 'a'], true) === false.
   */
  interface Array<T> {
    contentEquals(other: Array<T>, strict?: boolean): boolean;
  }
}
Array.prototype.contentEquals = function <T>(other: Array<T>, strict?: boolean) {
  if (!this && !other) return true;
  if (this.length !== other.length) return false;

  if (strict) {
    for (var i = 0; i < this.length; i++) {
      if (this[i] !== other[i]) return false;
    }
  } else {
    for (var i = 0; i < this.length; i++) {
      if (!other.includes(this[i])) return false;
    }
  }
  return true;
};

/// Number Extensions
declare global {
  /**
   * How many percent is {this} of {other}.
   * this / other * 100
   */
  interface Number {
    percentOf(other: number): number;
  }
}
Number.prototype.percentOf = function (other: number) {
  return (this.valueOf() / other) * 100;
};

declare global {
  /**
   * How many percent is {this} of {other}.
   * this / other * 100
   */
  interface Number {
    deltaPercentOf(other: number): number;
  }
}
Number.prototype.deltaPercentOf = function (other: number) {
  const diff = this.valueOf() - other;

  return diff.percentOf(other);
};

declare global {
  /**
   * Rounds number to nearest integer.
   */
  interface Number {
    round(): number;
  }
}
Number.prototype.round = function () {
  return Math.round(this.valueOf());
};

export {};
