import useApps from '../apps';
import { createEvent, useForceUpdateEvent } from '../trading-app/hooks/general/useForceUpdate';
import useLocalStorage from './useLocalStorage';

const defaults = {
  type: 'info',
  closable: true,
};

let alerts: Alert[] = [];
const onLoaded = createEvent();

export default function useAlerts() {
  const forceUpdate = useForceUpdateEvent(onLoaded);
  const [hidden, setHidden] = useLocalStorage<string[]>('rw_hidden_alerts', []);
  const { currentApp, currentRoute } = useApps();

  return {
    alerts: [...alerts.filter(a => (!a.scopeToApp || a.scopeToApp === currentApp) && (!a.scopeToRoute || a.scopeToRoute === currentRoute))],
    addAlert: (alert: Alert) => {
      if (hidden.includes(alert.type)) return;

      alert = {
        ...defaults,
        ...alert,
      } as Alert;

      // if singleton, update instead of add
      if (alert.singletonKey && alerts.some(a => a.singletonKey === alert.singletonKey)) {
        const idx = alerts.findIndex(a => a.singletonKey === alert.singletonKey);
        alerts.splice(idx, 1, alert);
      } else {
        alerts.push(alert);
      }

      forceUpdate();
    },
    removeAlert: (alert: Alert) => {
      const idx = alerts.indexOf(alert);
      if (idx >= 0) {
        alerts.splice(idx, 1);
        forceUpdate();
      }
    },
    removeBySingletonKey: (key: string) => {
      const idx = alerts.findIndex(a => a.singletonKey === key);
      if (idx >= 0) {
        alerts.splice(idx, 1);
        forceUpdate();
      }
    },
    stopShowing: (type: string) => {
      if (!hidden.includes(type)) {
        setHidden([...hidden, type]);
        alerts = [...alerts.filter(x => x.type !== type)];
        forceUpdate();
      }
    },
    startShowing: (type: string) => {
      const idx = hidden.indexOf(type);
      if (idx >= 0) {
        const arr = [...hidden];
        arr.slice(idx, 1);

        setHidden([...arr]);
      }
    },
  };
}
