import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useEffect, useRef } from 'react';

import { notificationsQuery } from '../notifications/useNotifications';
import useUser from '../user/useUser';

const mutation = gql`
  mutation dummy {
    dummy @client {
      success
    }
  }
`;

const useRefetchQueries = () => {
  const [mutate] = useMutation(mutation);
  const queryList = useRef([{ name: 'notificationsQuery', query: { query: notificationsQuery, variables: { userId: '' } } }]);
  const { data: user } = useUser();

  useEffect(() => {
    if (user) {
      const notificationQuery = queryList.current.find(x => x.name === 'notificationsQuery');
      notificationQuery!.query.variables!.userId = user.id;
    }
  }, [user]);

  const trigger = (queries: string[]) => {
    const queryArray = queryList.current
      .filter(x => queries.includes(x.name))
      .map(q => {
        return { query: q.query.query, variables: q.query.variables };
      });

    mutate({ refetchQueries: queryArray });
  };

  return { trigger: trigger };
};

export default useRefetchQueries;
