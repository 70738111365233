import { useMemo } from 'react';
import uuidv4 from 'uuid/v4';

import useCurrencyConverter from '../currency/useCurrencyConverter';
import useForceUpdate from '../general/useForceUpdate';
import { useWriteStats } from '../general/useStats';
import useUserSettings from '../user/GetUserSettings';
import useWarehouseData from '../warehouse/useWarehouseData';
import useGetOffers from './GetOffersQuery';
import usePackaging from './usePackaging';

export function useMasterList() {
  const { data, loadingCurrent, loadingTotal, refetch } = useGetOffers();
  const { data: userSettings } = useUserSettings();
  const { dataMap: navData, reload } = useWarehouseData();
  const forceUpdate = useForceUpdate();

  const cc = useCurrencyConverter();
  const pc = usePackaging();

  const createHeadersFromData = (data: OfferLine[]) => {
    let groups: { [key: string]: OfferLine[] } = {};

    data.forEach(offerLine => {
      if (!offerLine.linkedWine) return;

      const pck = offerLine.isOWC ? 'OWC' : offerLine.isOC ? 'OC' : 'Loose';
      const key = `${offerLine.linkedWine}_${offerLine.year ?? -1}_${offerLine.bottleSizeNumerical}_${pck}`;

      if (!groups[key]) {
        groups[key] = [offerLine];
      } else {
        groups[key].push(offerLine);
      }
    });

    const headers = Object.entries(groups).map(([key, offerlines]) => {
      const wineType =
        offerlines[0].wineName.length > 0 && offerlines[0].colour.length > 0
          ? `(${offerlines[0].colour}${offerlines[0].type !== 'Still' ? ' - ' + offerlines[0].type : ''})`
          : '';

      var header: OfferHeader = {
        id: uuidv4(),
        packaging: pc.GetPackaging(offerlines),
        packagingTypes: [],
        priceHigh: 0,
        priceAvg: 0,
        priceLow: 99999999,
        priceDiff: 0,
        priceDiffPercent: 0,
        rwMarginHigh: -1,
        rwMarginLow: -1,
        rwMarginAvg: -1,
        rwMargins: [],
        producer: offerlines[0].producer,
        year: offerlines[0].year! || 0 /** We know year cannot be null here because the OfferLine is not in the validation step  */,
        wine: `${offerlines[0].wineName} ${wineType}`,
        bottleSize: offerlines[0].bottleSize,
        bottleSizeNumerical: offerlines[0].bottleSizeNumerical,
        type: offerlines[0].type,
        colour: offerlines[0].colour,
        quantity: 0,
        region: offerlines[0].region,
        subRegion: offerlines[0].subRegion,
        offers: offerlines,
        linkedWine: offerlines[0]
          .linkedWine! /** We know linkedWine cannot be null here because the OfferLine is not in the validation step  */,
        linkedWineLwin: offerlines.find(x => x.linkedWineLwin)?.linkedWineLwin,
        lastUpdated: new Date(-1),
        totalOffers: offerlines.length,
        currency: userSettings?.currency,
      };

      let lastUpdated = 0;

      for (const offer of offerlines) {
        header.quantity += offer.quantity;

        if (offer.isIB) header.packagingTypes.push('IB');
        if (offer.isOWC) header.packagingTypes.push('OWC');
        else if (offer.isOC) header.packagingTypes.push('OC');
        else header.packagingTypes.push('Loose');

        header.priceHigh = Math.round(Math.max(header.priceHigh, cc.Convert(offer.price, offer.currency, userSettings?.currency).value));
        header.priceLow = Math.round(Math.min(header.priceLow, cc.Convert(offer.price, offer.currency, userSettings?.currency).value));
        header.priceAvg += cc.Convert(offer.price, offer.currency, userSettings?.currency).value;

        if (lastUpdated < offer.createdAt!.epoch) {
          lastUpdated = offer.createdAt!.epoch;
        }
      }

      header.priceAvg = Math.round(header.priceAvg / offerlines.length);
      header.priceDiff = Math.round(header.priceHigh - header.priceLow);
      header.priceDiffPercent = Math.round(Math.round((100 * header.priceDiff) / header.priceLow));
      header.packagingTypes = [...new Set(header.packagingTypes)];
      header.lastUpdated = new Date(lastUpdated);

      const lwin = offerlines.find(x => x.linkedWineLwin)?.linkedWineLwin ?? 'unknown';
      const navDataKey = key.replace(offerlines[0].linkedWine!, lwin);

      const navLines = navData[navDataKey];
      if (navLines?.length > 0) {
        let avgPrice = navLines.reduce((sum, next) => sum + next.lastCost || 0, 0) / navLines.length;
        header.quantity += navLines.reduce((sum, next) => sum + next.available, 0);

        header.rwMarginHigh = Math.round(((avgPrice - header.priceLow) / header.priceLow) * 10000) / 100;
        header.rwMarginAvg = Math.round(((avgPrice - header.priceAvg) / header.priceLow) * 10000) / 100;
        header.rwMarginLow = Math.round(((avgPrice - header.priceHigh) / header.priceLow) * 10000) / 100;
        header.instock = navLines.map(x => x);
      }

      return header;
    });

    return headers;
  };

  //    const offers = useMemo(() => createHeadersFromData(filteredOffers) || [], [offerDataArray, stockLines]);
  const offers = useMemo(
    () => (data.length ? createHeadersFromData(data.filter(x => !x.disableReason && !isNaN(x.price) && x.price !== 0)) : []),
    [data, navData]
  );

  //Save some client-side fun facts
  useWriteStats(offers);

  return {
    rawData: data,
    offers: offers,
    api: { refetchOffers: refetch, refetchStock: reload, refresh: forceUpdate },
    loading: data === undefined,
    loadingCurrent,
    loadingTotal,
  };
}
