import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql.macro';

import getData from '../../trading-app/hooks/general/useGetApolloData';

type GetPurchaseOrdersInput = {
  statuses: WarehouseStatus[];
  page: number;
  pageSize?: number;
};

const query = gql`
  query GetPurchaseOrders($input: GetPurchaseOrdersInput!) {
    GetPurchaseOrders(input: $input) @rest(type: "GetPurchaseOrders", path: "nav/ordersByStatus", method: "POST") {
      id
      status
      images
      vendorName
    }
  }
`;

const defaults: GetPurchaseOrdersInput = {
  statuses: [],
  page: 0,
  pageSize: 25,
};

export default function usePurchaseOrders(input?: GetPurchaseOrdersInput) {
  const { data, loading, error } = useQuery<ApolloDataWrapper<PurchaseOrderImageSet[]>, ApolloInput<GetPurchaseOrdersInput>>(query, {
    variables: { input: { ...defaults, ...input } },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  return { data: getData<PurchaseOrderImageSet[]>(data), loading, error };
}
