import { SyncOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { OverlayLoadingComponent } from '../../../../components/overlay-loading/overlay-loading';
import Css from '../../../../helpers/css/Css';
import Searcher from '../../../../rarewine-library-frontend/components/searcher/seacher';
import useFilterer from '../../../../rarewine-library-frontend/hooks/useFilterer';
import GetPhotoList from '../../hooks/warehouse/usePictureList';
import PictureDay from './picture-day/picture-day';

import style from './picture-list.module.scss';

export default function PictureList() {
  const daysInWeek = 7;
  const { data, loading, refetch } = GetPhotoList(daysInWeek);
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [refetching, setRefetching] = useState(false);

  const params = useParams<{ pictureID?: string }>();

  async function DoRefetch() {
    setRefetching(true);
    await refetch?.();
    setRefetching(false);
  }

  const filteredData = useFilterer(
    data,
    searchTerms,
    c => `${c.imageResult.productIds[0].itemNumber} ${c.imageResult.productIds[0].variantNumber} 
  ${c.poResult.item?.id} ${c.poResult.item?.year} ${c.poResult.item?.name}`
  );

  function createPictureDays() {
    let allDayLists = [];
    let day = new Date();
    day.setDate(day.getDate() + 1);

    for (let i = 1; i <= daysInWeek; i++) {
      day.setDate(day.getDate() - 1);
      let specificDayData = filteredData.filter(x => new Date(x.imageResult.modifiedAt.date).getDate() === day.getDate());
      if (specificDayData.length >= 1) {
        allDayLists.push(
          <PictureDay data={specificDayData} key={specificDayData[0]?.imageResult?.images[0]} activePictureID={params.pictureID} />
        );
      }
    }

    return allDayLists;
  }

  return (
    <OverlayLoadingComponent loading={loading}>
      <>
        <div className={style.headerText} style={{ textAlign: 'center' }}>
          Pictures from within the last week
        </div>
        <Searcher
          onSearchTerms={setSearchTerms}
          buttonsRight={[
            loading || refetching ? (
              <SyncOutlined spin />
            ) : (
              <Tooltip title="Refresh list">
                <SyncOutlined className={Css.Packs.Clickable.Primary} onClick={DoRefetch} />
              </Tooltip>
            ),
          ]}
        />
        {createPictureDays()}
      </>
    </OverlayLoadingComponent>
  );
}
