import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import getData from '../general/useGetApolloData';

const mutation = gql`
  mutation DeleteCase($input: DeleteCaseArgs!) {
    deleteCase(input: $input) @rest(type: "DeleteCase", path: "warehouse/deleteCase/{args.input.caseId}", method: "POST") {
      success
    }
  }
`;

export function useDeleteCase() {
  const [mutate, { loading, error, data }] = useMutation<ApolloDataWrapper<{ success: boolean }>, ApolloInput<{ caseId: string }>>(
    mutation
  );

  return { mutate, loading, error, data: getData(data) };
}
