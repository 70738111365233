function getData<T>(data: ApolloDataWrapper<T> | undefined) {
  if (data === undefined) {
    return undefined;
  }

  const dataName = Object.getOwnPropertyNames(data)[0];
  return data[dataName] as T;
}

export default getData;
