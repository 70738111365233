import { Button, Carousel, Col, Modal, Pagination, Row } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import React, { useRef } from 'react';

import DownloadWarehouseImages from '../download-warehouse-images/download-warehouse-images';

export default function PurchaseOrderImageCarousel(props: { purchaseOrderHeader: PurchaseOrderImageSet; width?: number | string }) {
  const ref = useRef<CarouselRef>(null);

  return (
    <Row justify="center">
      <Col>
        <Row justify="center">
          <Carousel style={{ width: props.width ?? 500 }} ref={ref} effect="fade" dots={false}>
            {props.purchaseOrderHeader.images.map(img => (
              <div key={img}>
                <img alt="" width={props.width ?? 500} src={`${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-warehouse/${img}`} />
              </div>
            ))}
          </Carousel>
        </Row>
        <Row justify="center">
          <Pagination
            total={props.purchaseOrderHeader.images.length}
            pageSize={1}
            itemRender={(page, type, originalElement) => {
              const image = (
                <div>
                  <img
                    alt=""
                    style={{ width: '40px', height: '40px' }}
                    src={`${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-warehouse/${props.purchaseOrderHeader.images[page - 1]}`}
                  />
                </div>
              );
              const inner = type === 'next' || type === 'prev' ? originalElement : image;

              return <div style={{ lineHeight: '40px' }}>{inner}</div>;
            }}
            onChange={newPage => {
              ref.current?.goTo(newPage - 1, false);
            }}
          />
        </Row>
        <Row justify="center" style={{ marginTop: 32 }}>
          <Col>
            <Button
              type="link"
              onClick={() => {
                Modal.info({
                  title: (
                    <>
                      <Row>
                        <Col>
                          <span>Download images for {props.purchaseOrderHeader.id}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span>{props.purchaseOrderHeader.vendorName}</span>
                        </Col>
                      </Row>
                    </>
                  ),
                  width: '50%',
                  maskClosable: true,
                  content: (
                    <>
                      <DownloadWarehouseImages
                        poNumber={props.purchaseOrderHeader.id}
                        vendorName={props.purchaseOrderHeader.vendorName}
                        images={props.purchaseOrderHeader.images}
                      />
                    </>
                  ),
                });
              }}
            >
              Download images
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
