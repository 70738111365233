import { MenuOutlined, MoreOutlined, QuestionCircleOutlined, StopOutlined } from '@ant-design/icons';
import { Badge, Col, Empty, Layout, Popover, Row } from 'antd';
import React, { useState } from 'react';

import useApps from '../../../apps/apps';
import { useGuideManager } from '../../../apps/sharedHooks/useGuide';
import Notifications from '../../../apps/trading-app/components/trading-app-header/components/notifications/notifications';
import UserProfile from '../../../apps/trading-app/components/trading-app-header/components/user-profile/user-profile';
import Css from '../../../helpers/css/Css';

import style from './AppHostHeader.module.scss';

const { Header } = Layout;

export default function AppHostHeader(props: { onHamburgerClicked: () => void }) {
  const [buttonsShown, setButtonsShown] = useState(false);

  const { currentApp } = useApps();

  return (
    <Header className={style.appHeader} style={{ backgroundColor: 'white', height: '64px' }}>
      <Row justify="space-between" style={{ height: '100%' }}>
        <Col className={style.mobileOnly} span={4} onClick={props.onHamburgerClicked}>
          <Row justify="center" align="middle" style={{ height: '100%' }}>
            <MenuOutlined />
          </Row>
        </Col>
        <Col xs={16} lg={10}>
          {currentApp?.headerBar}
        </Col>
        <Col className={style.mobileOnly} span={4} onClick={() => setButtonsShown(!buttonsShown)}>
          <Row justify="center" align="middle" style={{ height: '100%' }}>
            <MoreOutlined />
          </Row>
        </Col>
        <AppHostHeaderButtons currentApp={currentApp} shown={buttonsShown} />
      </Row>
    </Header>
  );
}

function AppHostHeaderButtons({ currentApp, ...props }: { currentApp: AppInfo; shown: boolean }) {
  const { loadedGuides, resetGuide, stopGuide } = useGuideManager();

  return (
    <Col className={[style.headerMenu, props.shown ? style.open : ''].join(' ')}>
      <Row>
        {currentApp?.headerButtons?.map((button, index) => (
          <Col className={style.headerIcon + Css.Packs.Clickable.Primary} key={index}>
            {button}
          </Col>
        ))}
        <Col className={style.headerIcon + Css.Packs.Clickable.Primary} key="help">
          <Popover
            placement="bottom"
            title="Guides available on this page (click to start)"
            trigger="hover"
            overlayStyle={{ minWidth: 400 }}
            content={
              <>
                {loadedGuides.length > 0 ? (
                  loadedGuides.map(g => (
                    <Row key={g} justify="space-between">
                      <Col className={Css.Packs.Clickable.VerySubtle} onClick={() => resetGuide(g)}>
                        {g}
                      </Col>
                      <Col className={Css.Packs.Clickable.Error} onClick={() => stopGuide(g)}>
                        <StopOutlined />
                      </Col>
                    </Row>
                  ))
                ) : (
                  <Empty description="No guides available here, sorry :(" />
                )}
              </>
            }
          >
            <div style={{ transform: 'scale(0.8)' }}>
              <Badge count={loadedGuides.length} offset={[5, -5]}>
                <div style={{ transform: 'scale(2)' }}>
                  <QuestionCircleOutlined />
                </div>
              </Badge>
            </div>
          </Popover>
        </Col>
        <Col className={style.headerIcon + Css.Packs.Clickable.Primary} key="notifications">
          <Notifications />
        </Col>
        <Col key="profile">
          <UserProfile />
        </Col>
      </Row>
    </Col>
  );
}
