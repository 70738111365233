import { Tooltip } from 'antd';
import React from 'react';

import livex from '../../../images/liv-ex.png';

export default function LivExLogo(props: { tooltip: string }) {
  return (
    <Tooltip title={props.tooltip}>
      <img src={livex} style={{ maxWidth: 60 }} alt="Liv|Ex" />
    </Tooltip>
  );
}
