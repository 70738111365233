import { useAuth0 } from '../../../../helpers/useAuth0';

const useUserAuth = () => {
  const { user } = useAuth0();

  return {
    userId: user?.sub as string,
    email: user?.email as string,
  };
};

export default useUserAuth;
