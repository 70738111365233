import { EyeOutlined, EyeTwoTone } from '@ant-design/icons';
import { Avatar, Button, List } from 'antd';
import React, { useState } from 'react';

import useRWHistory from '../../../../../../../components/hooks/general/useRWHistory';

import style from '../notifications.module.scss';

type Params = {
  requestedBy: string;
  requestMessage: string;
  imageCount: number;
  barcodes: Barcode[];
};

export default function WarehouseNewImages(props: { notification: RWNotification }) {
  const history = useRWHistory();
  const [moused, setMoused] = useState(false);
  const params = props.notification.parameters as Params;

  return (
    <div style={{ width: '100%' }} onMouseEnter={x => !moused && setMoused(true)} onMouseLeave={x => moused && setMoused(false)}>
      <List.Item.Meta
        className={`${props.notification.isRead ? '' : style.unread} ${style.notificationItem}`}
        avatar={
          <Avatar
            style={moused ? { backgroundColor: 'green' } : {}}
            icon={moused ? <EyeTwoTone twoToneColor="lightgreen" /> : <EyeOutlined />}
          />
        }
        title={
          <Button
            type="link"
            onClick={() => {
              history.push(
                `/trading/instock?search=${(params.barcodes[0] as any).ItemNumber}&variant=${(params.barcodes[0] as any).VariantNumber}`
              );
            }}
          >{`${params.imageCount} New images for POs ${params.barcodes.map(x => x.poNumber).join(', ')}`}</Button>
        }
        description={
          <>
            Requested by {params.requestedBy} with message {params.requestMessage}
          </>
        }
      />
    </div>
  );
}
