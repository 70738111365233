import { Row, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { FormattedNumber } from 'react-intl';

import useInterval from '../../hooks/general/useInterval';
import { useStats } from '../../hooks/general/useStats';

const TradingAppHeader = () => {
  const { data } = useStats();
  const [currentHeadline, setCurrentHeadline] = useState(0);

  const headlines = useMemo(() => {
    let tmpArr: React.ReactNode[] = [
      <>
        We can supply{' '}
        <b>
          <FormattedNumber value={data?.totalBottles || 0} />
        </b>{' '}
        total bottles!
      </>,
      <>
        We currently have{' '}
        <b>
          <FormattedNumber value={data?.liters || 0} />
        </b>{' '}
        liters of wine available!
      </>,
      <>
        We can offer{' '}
        <b>
          <FormattedNumber value={data?.totalValue || 0} />
        </b>
        € worth of wine!
      </>,
      <>
        <b>
          <FormattedNumber value={data?.potentialEarnings || 0} />
        </b>
        € of potential earnings are availble - sell something!
      </>,
      <>
        We have{' '}
        <b>
          <FormattedNumber value={data?.standard || 0} />
        </b>{' '}
        normal bottles!
      </>,
      <>
        There's currently{' '}
        <b>
          <FormattedNumber value={data?.magnum || 0} />
        </b>{' '}
        magnum bottles!
      </>,
      <>
        Jerobaum sounds funny, and we have{' '}
        <b>
          <FormattedNumber value={data?.jerobaum || 0} />
        </b>{' '}
        of them!
      </>,
      <>
        Is{' '}
        <b>
          <FormattedNumber value={data?.imperial || 0} />
        </b>{' '}
        imperial wines enough to warrent an imperial wine cellar?
      </>,
      <>
        Melchizedek is pretty rare, which is probably why we only have{' '}
        <b>
          <FormattedNumber value={data?.melchizedek || 0} />
        </b>{' '}
        of them!
      </>,
      <>
        We have{' '}
        <b>
          <FormattedNumber value={data?.totalOffers || 0} />
        </b>{' '}
        offers in the system!
      </>,
      <>
        Our oldest wine is from{' '}
        <b>
          <FormattedNumber value={data?.oldestWine || 0} />
        </b>{' '}
      </>,
    ];

    tmpArr =
      data?.double_magnum !== undefined
        ? [
            ...tmpArr,
            <>
              <b>
                <FormattedNumber value={data?.double_magnum || 0} />
              </b>{' '}
              double magnum bottles - wow!
            </>,
          ]
        : tmpArr;
    tmpArr =
      data?.lung_size !== undefined
        ? [
            ...tmpArr,
            <>
              <b>
                <FormattedNumber value={data?.lung_size || 0} />
              </b>{' '}
              Lung sized bottles - That's 730cl, why not just add the last 20?
            </>,
          ]
        : tmpArr;
    tmpArr =
      data?.pinte !== undefined
        ? [
            ...tmpArr,
            <>
              The British has been around too. We have{' '}
              <b>
                <FormattedNumber value={data?.pinte || 0} />
              </b>{' '}
              pint bottles.
            </>,
          ]
        : tmpArr;
    return tmpArr;
  }, [data]);

  useInterval(() => {
    setCurrentHeadline(Math.floor(Math.random() * headlines.length));
  }, 5000);

  return (
    <Row>
      <Typography.Text ellipsis>{headlines[currentHeadline]}</Typography.Text>
    </Row>
  );
};

export default TradingAppHeader;
