import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import getData from '../general/useGetApolloData';

type ApiResponse = {
  API: string;
};

const query = gql`
  query GetApiVersion {
    data @rest(type: "version", path: "version", method: "GET") {
      API
    }
  }
`;

export default () => {
  const { data, loading, error } = useQuery<ApolloDataWrapper<ApiResponse>>(query);

  const apiVersion = loading ? 'loading...' : error ? error.name + ' - ' + error.message : getData(data)?.API || '?';

  return apiVersion;
};
