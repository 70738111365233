import { ContainerOutlined } from '@ant-design/icons';
import { Avatar, Button, List } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedTime, useIntl } from 'react-intl';

import style from '../notifications.module.scss';

interface props {
  notification: RWNotification;
}

interface OfferParsingStartedPayload {
  fromEmail: string;
  estimatedLines: number;
}

const OnOfferParsingStartedNotification = (props: props) => {
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');

  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (!props.notification.parameters.fromEmail)
      throw new Error(
        `The parameter payload "${props.notification.parameters}" could not be parsed into the form of OfferParsingStartedPayload. Expected key 'fromEmail': string.`
      );
    if (!props.notification.parameters.estimatedLines)
      throw new Error(
        `The parameter payload "${props.notification.parameters}" could not be parsed into the form of OfferParsingStartedPayload. Expected key 'estimatedLines': number.`
      );

    setMessage(
      f(
        {
          id: `tradingapp.notification.${props.notification.context}.${props.notification.event}`,
          defaultMessage: 'Processing of an offer from {email} has started with an estimated amount of lines of: {estimatedLines}',
        },
        { email: props.notification.parameters.fromEmail, estimatedLines: props.notification.parameters.estimatedLines }
      )
    );
    setTitle('Offer analysis started');
  }, [f, props.notification]);

  return (
    <>
      <List.Item.Meta
        className={`${props.notification.isRead ? '' : style.unread} ${style.notificationItem}`}
        avatar={<Avatar icon={<ContainerOutlined />} />}
        title={<Button type="link">{title}</Button>}
        description={
          <>
            {message}
            <br />
            <span className={style.date}>
              <i>
                <FormattedDate value={props.notification.createdAt.date} /> <FormattedTime value={props.notification.createdAt.date} />
              </i>
            </span>
          </>
        }
      />
    </>
  );
};

export default OnOfferParsingStartedNotification;
