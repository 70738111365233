import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql.macro';

import getData from '../../../trading-app/hooks/general/useGetApolloData';

export const query = gql`
  query useStockWineImages($days: String!) {
    GetStockWineImages(days: $days) @rest(type: "StockWineImagePayload", path: "offer/GetAllPictures/{args.days}", method: "GET") {
      poResult @type(name: "StockPo") {
        item @type(name: "Item") {
          id
          lwin
          year
          name
          namePart1
          namePart2
          namePart3
          description
          remark
          bottleSizeMl
          packagingSize
          packaging
          wineCondition
          wineConditionString
          labelCondition
          labelConditionString
          warehousePlacement
        }
        purchaseLinesForItem @type(name: "PuchaseLines") {
          itemId
          quantity
          quantityOutstanding
          warehousePlacement
        }
        salesLinesForItem @type(name: "PurchaseSalesLines") {
          itemId
          quantity
          quantityOutstanding
          warehousePlacement
        }
      }
      imageResult @type(name: "imageResult") {
        id
        active
        productIds
        images
        author
        disabled
        message
        location
        createdAt
        modifiedAt
      }
    }
  }
`;

export default function useGetPhotoList(days: number) {
  const { data, loading, error, refetch } = useQuery<ApolloDataWrapper<StockWineImagePayload[]>>(query, {
    variables: { days: days },
  });

  return { data: getData<StockWineImagePayload[]>(data), loading, error, refetch };
}
