import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import getData from '../general/useGetApolloData';

interface SetUserNotificationsToReadArgs {
  input: {
    userId: string;
  };
}

export const setUserNotificationsToReadMutation = gql`
  mutation setUserNotificationsToRead($input: SetUserNotificationsToReadArgs!) {
    setUserNotificationsToRead(input: $input)
      @rest(type: "RWNotification", path: "notification/setUserNotificationsToRead", method: "POST") {
      id
      userId
      context
      event
      isRead
      parameters
    }
  }
`;

const useSetUserNotificationsToRead = (options?: MutationOptions) => {
  const [mutate, { loading, error, data }] = useMutation<ApolloDataWrapper<RWNotification>, SetUserNotificationsToReadArgs>(
    setUserNotificationsToReadMutation,
    options
  );
  return { mutate, loading, error, data: getData(data) };
};

export default useSetUserNotificationsToRead;
