import { ContainerOutlined } from '@ant-design/icons';
import { Avatar, Button, List } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedTime, useIntl } from 'react-intl';

import style from '../notifications.module.scss';

interface props {
  notification: RWNotification;
}

interface OfferRejectedInvalidAttachmentParameters {
  fromEmail: string;
  attachment: string;
}

const OnOfferRecjectedInvalidAttachmentNotification = (props: props) => {
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');

  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (!props.notification.parameters.fromEmail)
      throw new Error(
        `The parameter payload "${props.notification.parameters}" could not be parsed into the form of OfferRejectedInvalidAttachmentParameters. Expected key 'fromEmail': string.`
      );
    if (!props.notification.parameters.attachment)
      throw new Error(
        `The parameter payload "${props.notification.parameters}" could not be parsed into the form of OfferRejectedInvalidAttachmentParameters. Expected key 'attachment': string.`
      );

    setMessage(
      f(
        {
          id: `tradingapp.notification.${props.notification.context}.${props.notification.event}`,
          defaultMessage: 'An email from {email} has been rejected because it was not a modern Excel format.',
        },
        { email: props.notification.parameters.fromEmail, subject: props.notification.parameters.attachment }
      )
    );
    setTitle('Email rejected - invalid excel format');
  }, [props.notification, f]);

  return (
    <>
      <List.Item.Meta
        className={`${props.notification.isRead ? '' : style.unread} ${style.notificationItem}`}
        avatar={<Avatar icon={<ContainerOutlined />} />}
        title={<Button type="link">{title}</Button>}
        description={
          <>
            {message}
            <br />
            <a href={`${window.ENVARGS.REACT_APP_BLOBSTORAGE}${props.notification.parameters.attachment}`}>Download file</a>
            <br />
            <span className={style.date}>
              <i>
                <FormattedDate value={props.notification.createdAt.date} /> <FormattedTime value={props.notification.createdAt.date} />
              </i>
            </span>
          </>
        }
      />
    </>
  );
};

export default OnOfferRecjectedInvalidAttachmentNotification;
