export type Country = {
  Name: string;
  IsoCode2: string;
  IsoCode3: string;
  CountryCodes: string[];
  CountryValue: string;
};

const array: Country[] = [
  { Name: 'Afghanistan', IsoCode2: 'AF', IsoCode3: 'AFG', CountryCodes: ['+93'], CountryValue: 'Afghanistan' },
  { Name: 'Albania', IsoCode2: 'AL', IsoCode3: 'ALB', CountryCodes: ['+355'], CountryValue: 'Albania' },
  { Name: 'Algeria', IsoCode2: 'DZ', IsoCode3: 'DZA', CountryCodes: ['+213'], CountryValue: 'Algeria' },
  { Name: 'American Samoa', IsoCode2: 'AS', IsoCode3: 'ASM', CountryCodes: ['+1-684'], CountryValue: 'American_Samoa' },
  { Name: 'Andorra', IsoCode2: 'AD', IsoCode3: 'AND', CountryCodes: ['+376'], CountryValue: 'Andorra' },
  { Name: 'Angola', IsoCode2: 'AO', IsoCode3: 'AGO', CountryCodes: ['+244'], CountryValue: 'Angola' },
  { Name: 'Anguilla', IsoCode2: 'AI', IsoCode3: 'AIA', CountryCodes: ['+1-264'], CountryValue: 'Anguilla' },
  { Name: 'Antarctica', IsoCode2: 'AQ', IsoCode3: 'ATA', CountryCodes: ['+672'], CountryValue: 'Antarctica' },
  { Name: 'Antigua and Barbuda', IsoCode2: 'AG', IsoCode3: 'ATG', CountryCodes: ['+1-268'], CountryValue: 'Antigua_and_Barbuda' },
  { Name: 'Argentina', IsoCode2: 'AR', IsoCode3: 'ARG', CountryCodes: ['+54'], CountryValue: 'Argentina' },
  { Name: 'Armenia', IsoCode2: 'AM', IsoCode3: 'ARM', CountryCodes: ['+374'], CountryValue: 'Armenia' },
  { Name: 'Aruba', IsoCode2: 'AW', IsoCode3: 'ABW', CountryCodes: ['+297'], CountryValue: 'Aruba' },
  { Name: 'Australia', IsoCode2: 'AU', IsoCode3: 'AUS', CountryCodes: ['+61'], CountryValue: 'Australia' },
  { Name: 'Austria', IsoCode2: 'AT', IsoCode3: 'AUT', CountryCodes: ['+43'], CountryValue: 'Austria' },
  { Name: 'Azerbaijan', IsoCode2: 'AZ', IsoCode3: 'AZE', CountryCodes: ['+994'], CountryValue: 'Azerbaijan' },
  { Name: 'Bahamas', IsoCode2: 'BS', IsoCode3: 'BHS', CountryCodes: ['+1-242'], CountryValue: 'Bahamas' },
  { Name: 'Bahrain', IsoCode2: 'BH', IsoCode3: 'BHR', CountryCodes: ['+973'], CountryValue: 'Bahrain' },
  { Name: 'Bangladesh', IsoCode2: 'BD', IsoCode3: 'BGD', CountryCodes: ['+880'], CountryValue: 'Bangladesh' },
  { Name: 'Barbados', IsoCode2: 'BB', IsoCode3: 'BRB', CountryCodes: ['+1-246'], CountryValue: 'Barbados' },
  { Name: 'Belarus', IsoCode2: 'BY', IsoCode3: 'BLR', CountryCodes: ['+375'], CountryValue: 'Belarus' },
  { Name: 'Belgium', IsoCode2: 'BE', IsoCode3: 'BEL', CountryCodes: ['+32'], CountryValue: 'Belgium' },
  { Name: 'Belize', IsoCode2: 'BZ', IsoCode3: 'BLZ', CountryCodes: ['+501'], CountryValue: 'Belize' },
  { Name: 'Benin', IsoCode2: 'BJ', IsoCode3: 'BEN', CountryCodes: ['+229'], CountryValue: 'Benin' },
  { Name: 'Bermuda', IsoCode2: 'BM', IsoCode3: 'BMU', CountryCodes: ['+1-441'], CountryValue: 'Bermuda' },
  { Name: 'Bhutan', IsoCode2: 'BT', IsoCode3: 'BTN', CountryCodes: ['+975'], CountryValue: 'Bhutan' },
  { Name: 'Bolivia', IsoCode2: 'BO', IsoCode3: 'BOL', CountryCodes: ['+591'], CountryValue: 'Bolivia' },
  { Name: 'Bosnia and Herzegovina', IsoCode2: 'BA', IsoCode3: 'BIH', CountryCodes: ['+387'], CountryValue: 'Bosnia_and_Herzegovina' },
  { Name: 'Botswana', IsoCode2: 'BW', IsoCode3: 'BWA', CountryCodes: ['+267'], CountryValue: 'Botswana' },
  { Name: 'Brazil', IsoCode2: 'BR', IsoCode3: 'BRA', CountryCodes: ['+55'], CountryValue: 'Brazil' },
  {
    Name: 'British Indian Ocean Territory',
    IsoCode2: 'IO',
    IsoCode3: 'IOT',
    CountryCodes: ['+246'],
    CountryValue: 'British_Indian_Ocean_Territory',
  },
  { Name: 'British Virgin Islands', IsoCode2: 'VG', IsoCode3: 'VGB', CountryCodes: ['+1-284'], CountryValue: 'British_Virgin_Islands' },
  { Name: 'Brunei', IsoCode2: 'BN', IsoCode3: 'BRN', CountryCodes: ['+673'], CountryValue: 'Brunei' },
  { Name: 'Bulgaria', IsoCode2: 'BG', IsoCode3: 'BGR', CountryCodes: ['+359'], CountryValue: 'Bulgaria' },
  { Name: 'Burkina Faso', IsoCode2: 'BF', IsoCode3: 'BFA', CountryCodes: ['+226'], CountryValue: 'Burkina_Faso' },
  { Name: 'Burundi', IsoCode2: 'BI', IsoCode3: 'BDI', CountryCodes: ['+257'], CountryValue: 'Burundi' },
  { Name: 'Cambodia', IsoCode2: 'KH', IsoCode3: 'KHM', CountryCodes: ['+855'], CountryValue: 'Cambodia' },
  { Name: 'Cameroon', IsoCode2: 'CM', IsoCode3: 'CMR', CountryCodes: ['+237'], CountryValue: 'Cameroon' },
  { Name: 'Canada', IsoCode2: 'CA', IsoCode3: 'CAN', CountryCodes: ['+1'], CountryValue: 'Canada' },
  { Name: 'Cape Verde', IsoCode2: 'CV', IsoCode3: 'CPV', CountryCodes: ['+238'], CountryValue: 'Cape_Verde' },
  { Name: 'Cayman Islands', IsoCode2: 'KY', IsoCode3: 'CYM', CountryCodes: ['+1-345'], CountryValue: 'Cayman_Islands' },
  { Name: 'Central African Republic', IsoCode2: 'CF', IsoCode3: 'CAF', CountryCodes: ['+236'], CountryValue: 'Central_African_Republic' },
  { Name: 'Chad', IsoCode2: 'TD', IsoCode3: 'TCD', CountryCodes: ['+235'], CountryValue: 'Chad' },
  { Name: 'Chile', IsoCode2: 'CL', IsoCode3: 'CHL', CountryCodes: ['+56'], CountryValue: 'Chile' },
  { Name: 'China', IsoCode2: 'CN', IsoCode3: 'CHN', CountryCodes: ['+86'], CountryValue: 'China' },
  { Name: 'Christmas Island', IsoCode2: 'CX', IsoCode3: 'CXR', CountryCodes: ['+61'], CountryValue: 'Christmas_Island' },
  { Name: 'Cocos Islands', IsoCode2: 'CC', IsoCode3: 'CCK', CountryCodes: ['+61'], CountryValue: 'Cocos_Islands' },
  { Name: 'Colombia', IsoCode2: 'CO', IsoCode3: 'COL', CountryCodes: ['+57'], CountryValue: 'Colombia' },
  { Name: 'Comoros', IsoCode2: 'KM', IsoCode3: 'COM', CountryCodes: ['+269'], CountryValue: 'Comoros' },
  { Name: 'Cook Islands', IsoCode2: 'CK', IsoCode3: 'COK', CountryCodes: ['+682'], CountryValue: 'Cook_Islands' },
  { Name: 'Costa Rica', IsoCode2: 'CR', IsoCode3: 'CRI', CountryCodes: ['+506'], CountryValue: 'Costa_Rica' },
  { Name: 'Croatia', IsoCode2: 'HR', IsoCode3: 'HRV', CountryCodes: ['+385'], CountryValue: 'Croatia' },
  { Name: 'Cuba', IsoCode2: 'CU', IsoCode3: 'CUB', CountryCodes: ['+53'], CountryValue: 'Cuba' },
  { Name: 'Curacao', IsoCode2: 'CW', IsoCode3: 'CUW', CountryCodes: ['+599'], CountryValue: 'Curacao' },
  { Name: 'Cyprus', IsoCode2: 'CY', IsoCode3: 'CYP', CountryCodes: ['+357'], CountryValue: 'Cyprus' },
  { Name: 'Czech Republic', IsoCode2: 'CZ', IsoCode3: 'CZE', CountryCodes: ['+420'], CountryValue: 'Czech_Republic' },
  {
    Name: 'Democratic Republic of the Congo',
    IsoCode2: 'CD',
    IsoCode3: 'COD',
    CountryCodes: ['+243'],
    CountryValue: 'Democratic_Republic_of_the_Congo',
  },
  { Name: 'Denmark', IsoCode2: 'DK', IsoCode3: 'DNK', CountryCodes: ['+45'], CountryValue: 'Denmark' },
  { Name: 'Djibouti', IsoCode2: 'DJ', IsoCode3: 'DJI', CountryCodes: ['+253'], CountryValue: 'Djibouti' },
  { Name: 'Dominica', IsoCode2: 'DM', IsoCode3: 'DMA', CountryCodes: ['+1-767'], CountryValue: 'Dominica' },
  {
    Name: 'Dominican Republic',
    IsoCode2: 'DO',
    IsoCode3: 'DOM',
    CountryCodes: ['+1-809', '1-829', '1-849'],
    CountryValue: 'Dominican_Republic',
  },
  { Name: 'East Timor', IsoCode2: 'TL', IsoCode3: 'TLS', CountryCodes: ['+670'], CountryValue: 'East_Timor' },
  { Name: 'Ecuador', IsoCode2: 'EC', IsoCode3: 'ECU', CountryCodes: ['+593'], CountryValue: 'Ecuador' },
  { Name: 'Egypt', IsoCode2: 'EG', IsoCode3: 'EGY', CountryCodes: ['+20'], CountryValue: 'Egypt' },
  { Name: 'El Salvador', IsoCode2: 'SV', IsoCode3: 'SLV', CountryCodes: ['+503'], CountryValue: 'El_Salvador' },
  { Name: 'Equatorial Guinea', IsoCode2: 'GQ', IsoCode3: 'GNQ', CountryCodes: ['+240'], CountryValue: 'Equatorial_Guinea' },
  { Name: 'Eritrea', IsoCode2: 'ER', IsoCode3: 'ERI', CountryCodes: ['+291'], CountryValue: 'Eritrea' },
  { Name: 'Estonia', IsoCode2: 'EE', IsoCode3: 'EST', CountryCodes: ['+372'], CountryValue: 'Estonia' },
  { Name: 'Ethiopia', IsoCode2: 'ET', IsoCode3: 'ETH', CountryCodes: ['+251'], CountryValue: 'Ethiopia' },
  { Name: 'Falkland Islands', IsoCode2: 'FK', IsoCode3: 'FLK', CountryCodes: ['+500'], CountryValue: 'Falkland_Islands' },
  { Name: 'Faroe Islands', IsoCode2: 'FO', IsoCode3: 'FRO', CountryCodes: ['+298'], CountryValue: 'Faroe_Islands' },
  { Name: 'Fiji', IsoCode2: 'FJ', IsoCode3: 'FJI', CountryCodes: ['+679'], CountryValue: 'Fiji' },
  { Name: 'Finland', IsoCode2: 'FI', IsoCode3: 'FIN', CountryCodes: ['+358'], CountryValue: 'Finland' },
  { Name: 'France', IsoCode2: 'FR', IsoCode3: 'FRA', CountryCodes: ['+33'], CountryValue: 'France' },
  { Name: 'French Polynesia', IsoCode2: 'PF', IsoCode3: 'PYF', CountryCodes: ['+689'], CountryValue: 'French_Polynesia' },
  { Name: 'Gabon', IsoCode2: 'GA', IsoCode3: 'GAB', CountryCodes: ['+241'], CountryValue: 'Gabon' },
  { Name: 'Gambia', IsoCode2: 'GM', IsoCode3: 'GMB', CountryCodes: ['+220'], CountryValue: 'Gambia' },
  { Name: 'Georgia', IsoCode2: 'GE', IsoCode3: 'GEO', CountryCodes: ['+995'], CountryValue: 'Georgia' },
  { Name: 'Germany', IsoCode2: 'DE', IsoCode3: 'DEU', CountryCodes: ['+49'], CountryValue: 'Germany' },
  { Name: 'Ghana', IsoCode2: 'GH', IsoCode3: 'GHA', CountryCodes: ['+233'], CountryValue: 'Ghana' },
  { Name: 'Gibraltar', IsoCode2: 'GI', IsoCode3: 'GIB', CountryCodes: ['+350'], CountryValue: 'Gibraltar' },
  { Name: 'Greece', IsoCode2: 'GR', IsoCode3: 'GRC', CountryCodes: ['+30'], CountryValue: 'Greece' },
  { Name: 'Greenland', IsoCode2: 'GL', IsoCode3: 'GRL', CountryCodes: ['+299'], CountryValue: 'Greenland' },
  { Name: 'Grenada', IsoCode2: 'GD', IsoCode3: 'GRD', CountryCodes: ['+1-473'], CountryValue: 'Grenada' },
  { Name: 'Guam', IsoCode2: 'GU', IsoCode3: 'GUM', CountryCodes: ['+1-671'], CountryValue: 'Guam' },
  { Name: 'Guatemala', IsoCode2: 'GT', IsoCode3: 'GTM', CountryCodes: ['+502'], CountryValue: 'Guatemala' },
  { Name: 'Guernsey', IsoCode2: 'GG', IsoCode3: 'GGY', CountryCodes: ['+44-1481'], CountryValue: 'Guernsey' },
  { Name: 'Guinea', IsoCode2: 'GN', IsoCode3: 'GIN', CountryCodes: ['+224'], CountryValue: 'Guinea' },
  { Name: 'Guinea-Bissau', IsoCode2: 'GW', IsoCode3: 'GNB', CountryCodes: ['+245'], CountryValue: 'Guinea_Bissau' },
  { Name: 'Guyana', IsoCode2: 'GY', IsoCode3: 'GUY', CountryCodes: ['+592'], CountryValue: 'Guyana' },
  { Name: 'Haiti', IsoCode2: 'HT', IsoCode3: 'HTI', CountryCodes: ['+509'], CountryValue: 'Haiti' },
  { Name: 'Honduras', IsoCode2: 'HN', IsoCode3: 'HND', CountryCodes: ['+504'], CountryValue: 'Honduras' },
  { Name: 'Hong Kong', IsoCode2: 'HK', IsoCode3: 'HKG', CountryCodes: ['+852'], CountryValue: 'Hong_Kong' },
  { Name: 'Hungary', IsoCode2: 'HU', IsoCode3: 'HUN', CountryCodes: ['+36'], CountryValue: 'Hungary' },
  { Name: 'Iceland', IsoCode2: 'IS', IsoCode3: 'ISL', CountryCodes: ['+354'], CountryValue: 'Iceland' },
  { Name: 'India', IsoCode2: 'IN', IsoCode3: 'IND', CountryCodes: ['+91'], CountryValue: 'India' },
  { Name: 'Indonesia', IsoCode2: 'ID', IsoCode3: 'IDN', CountryCodes: ['+62'], CountryValue: 'Indonesia' },
  { Name: 'Iran', IsoCode2: 'IR', IsoCode3: 'IRN', CountryCodes: ['+98'], CountryValue: 'Iran' },
  { Name: 'Iraq', IsoCode2: 'IQ', IsoCode3: 'IRQ', CountryCodes: ['+964'], CountryValue: 'Iraq' },
  { Name: 'Ireland', IsoCode2: 'IE', IsoCode3: 'IRL', CountryCodes: ['+353'], CountryValue: 'Ireland' },
  { Name: 'Isle of Man', IsoCode2: 'IM', IsoCode3: 'IMN', CountryCodes: ['+44-1624'], CountryValue: 'Isle_of_Man' },
  { Name: 'Israel', IsoCode2: 'IL', IsoCode3: 'ISR', CountryCodes: ['+972'], CountryValue: 'Israel' },
  { Name: 'Italy', IsoCode2: 'IT', IsoCode3: 'ITA', CountryCodes: ['+39'], CountryValue: 'Italy' },
  { Name: 'Ivory Coast', IsoCode2: 'CI', IsoCode3: 'CIV', CountryCodes: ['+225'], CountryValue: 'Ivory_Coast' },
  { Name: 'Jamaica', IsoCode2: 'JM', IsoCode3: 'JAM', CountryCodes: ['+1-876'], CountryValue: 'Jamaica' },
  { Name: 'Japan', IsoCode2: 'JP', IsoCode3: 'JPN', CountryCodes: ['+81'], CountryValue: 'Japan' },
  { Name: 'Jersey', IsoCode2: 'JE', IsoCode3: 'JEY', CountryCodes: ['+44-1534'], CountryValue: 'Jersey' },
  { Name: 'Jordan', IsoCode2: 'JO', IsoCode3: 'JOR', CountryCodes: ['+962'], CountryValue: 'Jordan' },
  { Name: 'Kazakhstan', IsoCode2: 'KZ', IsoCode3: 'KAZ', CountryCodes: ['+7'], CountryValue: 'Kazakhstan' },
  { Name: 'Kenya', IsoCode2: 'KE', IsoCode3: 'KEN', CountryCodes: ['+254'], CountryValue: 'Kenya' },
  { Name: 'Kiribati', IsoCode2: 'KI', IsoCode3: 'KIR', CountryCodes: ['+686'], CountryValue: 'Kiribati' },
  { Name: 'Kosovo', IsoCode2: 'XK', IsoCode3: 'XKX', CountryCodes: ['+383'], CountryValue: 'Kosovo' },
  { Name: 'Kuwait', IsoCode2: 'KW', IsoCode3: 'KWT', CountryCodes: ['+965'], CountryValue: 'Kuwait' },
  { Name: 'Kyrgyzstan', IsoCode2: 'KG', IsoCode3: 'KGZ', CountryCodes: ['+996'], CountryValue: 'Kyrgyzstan' },
  { Name: 'Laos', IsoCode2: 'LA', IsoCode3: 'LAO', CountryCodes: ['+856'], CountryValue: 'Laos' },
  { Name: 'Latvia', IsoCode2: 'LV', IsoCode3: 'LVA', CountryCodes: ['+371'], CountryValue: 'Latvia' },
  { Name: 'Lebanon', IsoCode2: 'LB', IsoCode3: 'LBN', CountryCodes: ['+961'], CountryValue: 'Lebanon' },
  { Name: 'Lesotho', IsoCode2: 'LS', IsoCode3: 'LSO', CountryCodes: ['+266'], CountryValue: 'Lesotho' },
  { Name: 'Liberia', IsoCode2: 'LR', IsoCode3: 'LBR', CountryCodes: ['+231'], CountryValue: 'Liberia' },
  { Name: 'Libya', IsoCode2: 'LY', IsoCode3: 'LBY', CountryCodes: ['+218'], CountryValue: 'Libya' },
  { Name: 'Liechtenstein', IsoCode2: 'LI', IsoCode3: 'LIE', CountryCodes: ['+423'], CountryValue: 'Liechtenstein' },
  { Name: 'Lithuania', IsoCode2: 'LT', IsoCode3: 'LTU', CountryCodes: ['+370'], CountryValue: 'Lithuania' },
  { Name: 'Luxembourg', IsoCode2: 'LU', IsoCode3: 'LUX', CountryCodes: ['+352'], CountryValue: 'Luxembourg' },
  { Name: 'Macau', IsoCode2: 'MO', IsoCode3: 'MAC', CountryCodes: ['+853'], CountryValue: 'Macau' },
  { Name: 'Macedonia', IsoCode2: 'MK', IsoCode3: 'MKD', CountryCodes: ['+389'], CountryValue: 'Macedonia' },
  { Name: 'Madagascar', IsoCode2: 'MG', IsoCode3: 'MDG', CountryCodes: ['+261'], CountryValue: 'Madagascar' },
  { Name: 'Malawi', IsoCode2: 'MW', IsoCode3: 'MWI', CountryCodes: ['+265'], CountryValue: 'Malawi' },
  { Name: 'Malaysia', IsoCode2: 'MY', IsoCode3: 'MYS', CountryCodes: ['+60'], CountryValue: 'Malaysia' },
  { Name: 'Maldives', IsoCode2: 'MV', IsoCode3: 'MDV', CountryCodes: ['+960'], CountryValue: 'Maldives' },
  { Name: 'Mali', IsoCode2: 'ML', IsoCode3: 'MLI', CountryCodes: ['+223'], CountryValue: 'Mali' },
  { Name: 'Malta', IsoCode2: 'MT', IsoCode3: 'MLT', CountryCodes: ['+356'], CountryValue: 'Malta' },
  { Name: 'Marshall Islands', IsoCode2: 'MH', IsoCode3: 'MHL', CountryCodes: ['+692'], CountryValue: 'Marshall_Islands' },
  { Name: 'Mauritania', IsoCode2: 'MR', IsoCode3: 'MRT', CountryCodes: ['+222'], CountryValue: 'Mauritania' },
  { Name: 'Mauritius', IsoCode2: 'MU', IsoCode3: 'MUS', CountryCodes: ['+230'], CountryValue: 'Mauritius' },
  { Name: 'Mayotte', IsoCode2: 'YT', IsoCode3: 'MYT', CountryCodes: ['+262'], CountryValue: 'Mayotte' },
  { Name: 'Mexico', IsoCode2: 'MX', IsoCode3: 'MEX', CountryCodes: ['+52'], CountryValue: 'Mexico' },
  { Name: 'Micronesia', IsoCode2: 'FM', IsoCode3: 'FSM', CountryCodes: ['+691'], CountryValue: 'Micronesia' },
  { Name: 'Moldova', IsoCode2: 'MD', IsoCode3: 'MDA', CountryCodes: ['+373'], CountryValue: 'Moldova' },
  { Name: 'Monaco', IsoCode2: 'MC', IsoCode3: 'MCO', CountryCodes: ['+377'], CountryValue: 'Monaco' },
  { Name: 'Mongolia', IsoCode2: 'MN', IsoCode3: 'MNG', CountryCodes: ['+976'], CountryValue: 'Mongolia' },
  { Name: 'Montenegro', IsoCode2: 'ME', IsoCode3: 'MNE', CountryCodes: ['+382'], CountryValue: 'Montenegro' },
  { Name: 'Montserrat', IsoCode2: 'MS', IsoCode3: 'MSR', CountryCodes: ['+1-664'], CountryValue: 'Montserrat' },
  { Name: 'Morocco', IsoCode2: 'MA', IsoCode3: 'MAR', CountryCodes: ['+212'], CountryValue: 'Morocco' },
  { Name: 'Mozambique', IsoCode2: 'MZ', IsoCode3: 'MOZ', CountryCodes: ['+258'], CountryValue: 'Mozambique' },
  { Name: 'Myanmar', IsoCode2: 'MM', IsoCode3: 'MMR', CountryCodes: ['+95'], CountryValue: 'Myanmar' },
  { Name: 'Namibia', IsoCode2: 'NA', IsoCode3: 'NAM', CountryCodes: ['+264'], CountryValue: 'Namibia' },
  { Name: 'Nauru', IsoCode2: 'NR', IsoCode3: 'NRU', CountryCodes: ['+674'], CountryValue: 'Nauru' },
  { Name: 'Nepal', IsoCode2: 'NP', IsoCode3: 'NPL', CountryCodes: ['+977'], CountryValue: 'Nepal' },
  { Name: 'Netherlands', IsoCode2: 'NL', IsoCode3: 'NLD', CountryCodes: ['+31'], CountryValue: 'Netherlands' },
  { Name: 'Netherlands Antilles', IsoCode2: 'AN', IsoCode3: 'ANT', CountryCodes: ['+599'], CountryValue: 'Netherlands_Antilles' },
  { Name: 'New Caledonia', IsoCode2: 'NC', IsoCode3: 'NCL', CountryCodes: ['+687'], CountryValue: 'New_Caledonia' },
  { Name: 'New Zealand', IsoCode2: 'NZ', IsoCode3: 'NZL', CountryCodes: ['+64'], CountryValue: 'New_Zealand' },
  { Name: 'Nicaragua', IsoCode2: 'NI', IsoCode3: 'NIC', CountryCodes: ['+505'], CountryValue: 'Nicaragua' },
  { Name: 'Niger', IsoCode2: 'NE', IsoCode3: 'NER', CountryCodes: ['+227'], CountryValue: 'Niger' },
  { Name: 'Nigeria', IsoCode2: 'NG', IsoCode3: 'NGA', CountryCodes: ['+234'], CountryValue: 'Nigeria' },
  { Name: 'Niue', IsoCode2: 'NU', IsoCode3: 'NIU', CountryCodes: ['+683'], CountryValue: 'Niue' },
  { Name: 'North Korea', IsoCode2: 'KP', IsoCode3: 'PRK', CountryCodes: ['+850'], CountryValue: 'North_Korea' },
  { Name: 'Northern Mariana Islands', IsoCode2: 'MP', IsoCode3: 'MNP', CountryCodes: ['+1-670'], CountryValue: 'Northern_Mariana_Islands' },
  { Name: 'Norway', IsoCode2: 'NO', IsoCode3: 'NOR', CountryCodes: ['+47'], CountryValue: 'Norway' },
  { Name: 'Oman', IsoCode2: 'OM', IsoCode3: 'OMN', CountryCodes: ['+968'], CountryValue: 'Oman' },
  { Name: 'Pakistan', IsoCode2: 'PK', IsoCode3: 'PAK', CountryCodes: ['+92'], CountryValue: 'Pakistan' },
  { Name: 'Palau', IsoCode2: 'PW', IsoCode3: 'PLW', CountryCodes: ['+680'], CountryValue: 'Palau' },
  { Name: 'Palestine', IsoCode2: 'PS', IsoCode3: 'PSE', CountryCodes: ['+970'], CountryValue: 'Palestine' },
  { Name: 'Panama', IsoCode2: 'PA', IsoCode3: 'PAN', CountryCodes: ['+507'], CountryValue: 'Panama' },
  { Name: 'Papua New Guinea', IsoCode2: 'PG', IsoCode3: 'PNG', CountryCodes: ['+675'], CountryValue: 'Papua_New_Guinea' },
  { Name: 'Paraguay', IsoCode2: 'PY', IsoCode3: 'PRY', CountryCodes: ['+595'], CountryValue: 'Paraguay' },
  { Name: 'Peru', IsoCode2: 'PE', IsoCode3: 'PER', CountryCodes: ['+51'], CountryValue: 'Peru' },
  { Name: 'Philippines', IsoCode2: 'PH', IsoCode3: 'PHL', CountryCodes: ['+63'], CountryValue: 'Philippines' },
  { Name: 'Pitcairn', IsoCode2: 'PN', IsoCode3: 'PCN', CountryCodes: ['+64'], CountryValue: 'Pitcairn' },
  { Name: 'Poland', IsoCode2: 'PL', IsoCode3: 'POL', CountryCodes: ['+48'], CountryValue: 'Poland' },
  { Name: 'Portugal', IsoCode2: 'PT', IsoCode3: 'PRT', CountryCodes: ['+351'], CountryValue: 'Portugal' },
  { Name: 'Puerto Rico', IsoCode2: 'PR', IsoCode3: 'PRI', CountryCodes: ['+1-787,+_1-939'], CountryValue: 'Puerto_Rico' },
  { Name: 'Qatar', IsoCode2: 'QA', IsoCode3: 'QAT', CountryCodes: ['+974'], CountryValue: 'Qatar' },
  { Name: 'Republic of the Congo', IsoCode2: 'CG', IsoCode3: 'COG', CountryCodes: ['+242'], CountryValue: 'Republic_of_the_Congo' },
  { Name: 'Reunion', IsoCode2: 'RE', IsoCode3: 'REU', CountryCodes: ['+262'], CountryValue: 'Reunion' },
  { Name: 'Romania', IsoCode2: 'RO', IsoCode3: 'ROU', CountryCodes: ['+40'], CountryValue: 'Romania' },
  { Name: 'Russia', IsoCode2: 'RU', IsoCode3: 'RUS', CountryCodes: ['+7'], CountryValue: 'Russia' },
  { Name: 'Rwanda', IsoCode2: 'RW', IsoCode3: 'RWA', CountryCodes: ['+250'], CountryValue: 'Rwanda' },
  { Name: 'Saint Barthelemy', IsoCode2: 'BL', IsoCode3: 'BLM', CountryCodes: ['+590'], CountryValue: 'Saint_Barthelemy' },
  { Name: 'Saint Helena', IsoCode2: 'SH', IsoCode3: 'SHN', CountryCodes: ['+290'], CountryValue: 'Saint_Helena' },
  { Name: 'Saint Kitts and Nevis', IsoCode2: 'KN', IsoCode3: 'KNA', CountryCodes: ['+1-869'], CountryValue: 'Saint_Kitts_and_Nevis' },
  { Name: 'Saint Lucia', IsoCode2: 'LC', IsoCode3: 'LCA', CountryCodes: ['+1-758'], CountryValue: 'Saint_Lucia' },
  { Name: 'Saint Martin', IsoCode2: 'MF', IsoCode3: 'MAF', CountryCodes: ['+590'], CountryValue: 'Saint_Martin' },
  { Name: 'Saint Pierre and Miquelon', IsoCode2: 'PM', IsoCode3: 'SPM', CountryCodes: ['+508'], CountryValue: 'Saint_Pierre_and_Miquelon' },
  {
    Name: 'Saint Vincent and the Grenadines',
    IsoCode2: 'VC',
    IsoCode3: 'VCT',
    CountryCodes: ['+1-784'],
    CountryValue: 'Saint_Vincent_and_the_Grenadines',
  },
  { Name: 'Samoa', IsoCode2: 'WS', IsoCode3: 'WSM', CountryCodes: ['+685'], CountryValue: 'Samoa' },
  { Name: 'San Marino', IsoCode2: 'SM', IsoCode3: 'SMR', CountryCodes: ['+378'], CountryValue: 'San_Marino' },
  { Name: 'Sao Tome and Principe', IsoCode2: 'ST', IsoCode3: 'STP', CountryCodes: ['+239'], CountryValue: 'Sao_Tome_and_Principe' },
  { Name: 'Saudi Arabia', IsoCode2: 'SA', IsoCode3: 'SAU', CountryCodes: ['+966'], CountryValue: 'Saudi_Arabia' },
  { Name: 'Senegal', IsoCode2: 'SN', IsoCode3: 'SEN', CountryCodes: ['+221'], CountryValue: 'Senegal' },
  { Name: 'Serbia', IsoCode2: 'RS', IsoCode3: 'SRB', CountryCodes: ['+381'], CountryValue: 'Serbia' },
  { Name: 'Seychelles', IsoCode2: 'SC', IsoCode3: 'SYC', CountryCodes: ['+248'], CountryValue: 'Seychelles' },
  { Name: 'Sierra Leone', IsoCode2: 'SL', IsoCode3: 'SLE', CountryCodes: ['+232'], CountryValue: 'Sierra_Leone' },
  { Name: 'Singapore', IsoCode2: 'SG', IsoCode3: 'SGP', CountryCodes: ['+65'], CountryValue: 'Singapore' },
  { Name: 'Sint Maarten', IsoCode2: 'SX', IsoCode3: 'SXM', CountryCodes: ['+1-721'], CountryValue: 'Sint_Maarten' },
  { Name: 'Slovakia', IsoCode2: 'SK', IsoCode3: 'SVK', CountryCodes: ['+421'], CountryValue: 'Slovakia' },
  { Name: 'Slovenia', IsoCode2: 'SI', IsoCode3: 'SVN', CountryCodes: ['+386'], CountryValue: 'Slovenia' },
  { Name: 'Solomon Islands', IsoCode2: 'SB', IsoCode3: 'SLB', CountryCodes: ['+677'], CountryValue: 'Solomon_Islands' },
  { Name: 'Somalia', IsoCode2: 'SO', IsoCode3: 'SOM', CountryCodes: ['+252'], CountryValue: 'Somalia' },
  { Name: 'South Africa', IsoCode2: 'ZA', IsoCode3: 'ZAF', CountryCodes: ['+27'], CountryValue: 'South_Africa' },
  { Name: 'South Korea', IsoCode2: 'KR', IsoCode3: 'KOR', CountryCodes: ['+82'], CountryValue: 'South_Korea' },
  { Name: 'South Sudan', IsoCode2: 'SS', IsoCode3: 'SSD', CountryCodes: ['+211'], CountryValue: 'South_Sudan' },
  { Name: 'Spain', IsoCode2: 'ES', IsoCode3: 'ESP', CountryCodes: ['+34'], CountryValue: 'Spain' },
  { Name: 'Sri Lanka', IsoCode2: 'LK', IsoCode3: 'LKA', CountryCodes: ['+94'], CountryValue: 'Sri_Lanka' },
  { Name: 'Sudan', IsoCode2: 'SD', IsoCode3: 'SDN', CountryCodes: ['+249'], CountryValue: 'Sudan' },
  { Name: 'Suriname', IsoCode2: 'SR', IsoCode3: 'SUR', CountryCodes: ['+597'], CountryValue: 'Suriname' },
  { Name: 'Svalbard and Jan Mayen', IsoCode2: 'SJ', IsoCode3: 'SJM', CountryCodes: ['+47'], CountryValue: 'Svalbard_and_Jan_Mayen' },
  { Name: 'Swaziland', IsoCode2: 'SZ', IsoCode3: 'SWZ', CountryCodes: ['+268'], CountryValue: 'Swaziland' },
  { Name: 'Sweden', IsoCode2: 'SE', IsoCode3: 'SWE', CountryCodes: ['+46'], CountryValue: 'Sweden' },
  { Name: 'Switzerland', IsoCode2: 'CH', IsoCode3: 'CHE', CountryCodes: ['+41'], CountryValue: 'Switzerland' },
  { Name: 'Syria', IsoCode2: 'SY', IsoCode3: 'SYR', CountryCodes: ['+963'], CountryValue: 'Syria' },
  { Name: 'Taiwan', IsoCode2: 'TW', IsoCode3: 'TWN', CountryCodes: ['+886'], CountryValue: 'Taiwan' },
  { Name: 'Tajikistan', IsoCode2: 'TJ', IsoCode3: 'TJK', CountryCodes: ['+992'], CountryValue: 'Tajikistan' },
  { Name: 'Tanzania', IsoCode2: 'TZ', IsoCode3: 'TZA', CountryCodes: ['+255'], CountryValue: 'Tanzania' },
  { Name: 'Thailand', IsoCode2: 'TH', IsoCode3: 'THA', CountryCodes: ['+66'], CountryValue: 'Thailand' },
  { Name: 'Togo', IsoCode2: 'TG', IsoCode3: 'TGO', CountryCodes: ['+228'], CountryValue: 'Togo' },
  { Name: 'Tokelau', IsoCode2: 'TK', IsoCode3: 'TKL', CountryCodes: ['+690'], CountryValue: 'Tokelau' },
  { Name: 'Tonga', IsoCode2: 'TO', IsoCode3: 'TON', CountryCodes: ['+676'], CountryValue: 'Tonga' },
  { Name: 'Trinidad and Tobago', IsoCode2: 'TT', IsoCode3: 'TTO', CountryCodes: ['+1-868'], CountryValue: 'Trinidad_and_Tobago' },
  { Name: 'Tunisia', IsoCode2: 'TN', IsoCode3: 'TUN', CountryCodes: ['+216'], CountryValue: 'Tunisia' },
  { Name: 'Turkey', IsoCode2: 'TR', IsoCode3: 'TUR', CountryCodes: ['+90'], CountryValue: 'Turkey' },
  { Name: 'Turkmenistan', IsoCode2: 'TM', IsoCode3: 'TKM', CountryCodes: ['+993'], CountryValue: 'Turkmenistan' },
  { Name: 'Turks and Caicos Islands', IsoCode2: 'TC', IsoCode3: 'TCA', CountryCodes: ['+1-649'], CountryValue: 'Turks_and_Caicos_Islands' },
  { Name: 'Tuvalu', IsoCode2: 'TV', IsoCode3: 'TUV', CountryCodes: ['+688'], CountryValue: 'Tuvalu' },
  { Name: 'U.S. Virgin Islands', IsoCode2: 'VI', IsoCode3: 'VIR', CountryCodes: ['+1-340'], CountryValue: 'US_Virgin_Islands' },
  { Name: 'Uganda', IsoCode2: 'UG', IsoCode3: 'UGA', CountryCodes: ['+256'], CountryValue: 'Uganda' },
  { Name: 'Ukraine', IsoCode2: 'UA', IsoCode3: 'UKR', CountryCodes: ['+380'], CountryValue: 'Ukraine' },
  { Name: 'United Arab Emirates', IsoCode2: 'AE', IsoCode3: 'ARE', CountryCodes: ['+971'], CountryValue: 'United_Arab_Emirates' },
  { Name: 'United Kingdom', IsoCode2: 'GB', IsoCode3: 'GBR', CountryCodes: ['+44'], CountryValue: 'United_Kingdom' },
  { Name: 'United States', IsoCode2: 'US', IsoCode3: 'USA', CountryCodes: ['+1'], CountryValue: 'United_States' },
  { Name: 'Uruguay', IsoCode2: 'UY', IsoCode3: 'URY', CountryCodes: ['+598'], CountryValue: 'Uruguay' },
  { Name: 'Uzbekistan', IsoCode2: 'UZ', IsoCode3: 'UZB', CountryCodes: ['+998'], CountryValue: 'Uzbekistan' },
  { Name: 'Vanuatu', IsoCode2: 'VU', IsoCode3: 'VUT', CountryCodes: ['+678'], CountryValue: 'Vanuatu' },
  { Name: 'Vatican', IsoCode2: 'VA', IsoCode3: 'VAT', CountryCodes: ['+379'], CountryValue: 'Vatican' },
  { Name: 'Venezuela', IsoCode2: 'VE', IsoCode3: 'VEN', CountryCodes: ['+58'], CountryValue: 'Venezuela' },
  { Name: 'Vietnam', IsoCode2: 'VN', IsoCode3: 'VNM', CountryCodes: ['+84'], CountryValue: 'Vietnam' },
  { Name: 'Wallis and Futuna', IsoCode2: 'WF', IsoCode3: 'WLF', CountryCodes: ['+681'], CountryValue: 'Wallis_and_Futuna' },
  { Name: 'Western Sahara', IsoCode2: 'EH', IsoCode3: 'ESH', CountryCodes: ['+212'], CountryValue: 'Western_Sahara' },
  { Name: 'Yemen', IsoCode2: 'YE', IsoCode3: 'YEM', CountryCodes: ['+967'], CountryValue: 'Yemen' },
  { Name: 'Zambia', IsoCode2: 'ZM', IsoCode3: 'ZMB', CountryCodes: ['+260'], CountryValue: 'Zambia' },
  { Name: 'Zimbabwe', IsoCode2: 'ZW', IsoCode3: 'ZWE', CountryCodes: ['+263'], CountryValue: 'Zimbabwe' },
];

const countryMap = array.reduce((prev: { [key: string]: Country }, next) => {
  if (!prev[next.Name]) prev[next.Name] = next;
  if (!prev[next.IsoCode3]) prev[next.IsoCode3] = next;
  if (!prev[next.IsoCode2]) prev[next.IsoCode2] = next;
  if (!prev[next.CountryValue]) prev[next.CountryValue] = next;

  for (const code in next.CountryCodes) {
    if (!prev[code]) prev[code] = next;
  }

  prev['' + array.indexOf(next)] = next;

  return prev;
}, {});

const Countries = {
  array: array,
  lookup: (key?: string | number) => countryMap['' + key],
};

export default Countries;
