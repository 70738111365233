import { ContainerOutlined } from '@ant-design/icons';
import { Avatar, Button, List } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedTime, useIntl } from 'react-intl';

import style from '../notifications.module.scss';

interface props {
  notification: RWNotification;
}

interface OfferRejectedNoSenderParameters {
  fromEmail: string;
  subject: string;
}

const OnOfferRecjectedNoSenderNotification = (props: props) => {
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');

  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (!props.notification.parameters.fromEmail)
      throw new Error(
        `The parameter payload "${props.notification.parameters}" could not be parsed into the form of OfferRejectedNoSenderParameters. Expected key 'fromEmail': string.`
      );
    if (!props.notification.parameters.subject)
      throw new Error(
        `The parameter payload "${props.notification.parameters}" could not be parsed into the form of OfferRejectedNoSenderParameters. Expected key 'subject': string. `
      );

    setMessage(
      f(
        {
          id: `tradingapp.notification.${props.notification.context}.${props.notification.event}`,
          defaultMessage:
            'An email from {email} has been rejected as it was from a Rare Wine employee. The subject field should be the supplier email, but it was "{subject}".',
        },
        { email: props.notification.parameters.fromEmail, subject: props.notification.parameters.subject }
      )
    );

    setTitle('Email rejected - no supplier email');
  }, [props.notification, f]);

  return (
    <>
      <List.Item.Meta
        className={`${props.notification.isRead ? '' : style.unread} ${style.notificationItem}`}
        avatar={<Avatar icon={<ContainerOutlined />} />}
        title={<Button type="link">{title}</Button>}
        description={
          <>
            {message}
            <br />
            <span className={style.date}>
              <i>
                <FormattedDate value={props.notification.createdAt.date} /> <FormattedTime value={props.notification.createdAt.date} />
              </i>
            </span>
          </>
        }
      />
    </>
  );
};

export default OnOfferRecjectedNoSenderNotification;
