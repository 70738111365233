import { useContext } from 'react';

import { EventsContext } from '../../components/events-context/events-provider';

const useEvents = () => {
  const { connection } = useContext(EventsContext);
  return { connection: connection };
};

export default useEvents;
