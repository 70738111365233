import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import Highlighter from 'react-highlight-words';

import '../../rarewine-library-frontend/extensions/ArrayExtensions';
import useQueryParams from '../../rarewine-library-frontend/hooks/useQueryParams';
import { IDictionary, StockWine } from './types';

import style from './stock-list.module.scss';

const daysBeforeNewEpoch = new Date().getTime() - 86400000 * 14;

const fieldsOnWines = [
  'Producer',
  'Wine',
  'Country',
  'SubRegion',
  'Quantity',
  'Packaging',
  'Price',
  'Id',
  'Volume',
  'Condition',
  'Year',
  'Box',
  'BoxSize',
  'Colour',
  'Pictures',
  'FolderId',
  'Variant',
  'BacklabelType',
  'Remark',
  'LastPoDate',
  'LastPoDateEpoch',
  'Region',
  'Name',
  'New',
  'Type',
].map(w => w.charAt(0).toLowerCase() + w.slice(1));

/**
 * This function converts a series of url search queries into a nicely comma seperated format
 * @param {string[]} params - The keys of the URL search query string to search for.
 * @return {IDictionary<string[]>}  {IDictionary<string>} A dictionary of string arrays, values of the dictionary are the query search parameters. Comma seperated if more than one for each key.
 */
function useSplitableQueryParams(props: { params: string[] }): IDictionary<string[]> {
  const all = useQueryParams(props.params);
  const splitQueryParams: IDictionary<string[]> = {};
  Object.entries(all).forEach(([key, value]) => (splitQueryParams[key] = value.split(',').filter(e => !!e)));
  return splitQueryParams;
}

export function useColumnSplitQueryParams() {
  return useSplitableQueryParams({ params: fieldsOnWines });
}

export function GetColumns(props: { filteredData: StockWine[]; searchTerms: string[]; showRequestImageModal: (e: StockWine) => void }) {
  const Highlight = ({ text }: { text?: string }) => (
    <div className={style.textWrap}>
      <Highlighter
        highlightClassName={style.highlight}
        highlightTag="span"
        searchWords={props.searchTerms}
        autoEscape
        textToHighlight={text ?? ''}
      />
    </div>
  );

  const allQueryParams = useColumnSplitQueryParams();

  const CreateFilter = (list: StockWine[], selector: (w: StockWine) => any) => {
    const groupByProp: IDictionary<number> = {};
    const rawValues: IDictionary<any> = {};
    list.forEach(ol => {
      const key = selector(ol)?.toString() ?? '';

      if (groupByProp[key] !== undefined) {
        groupByProp[key]++;
      } else {
        groupByProp[key] = 1;
      }

      rawValues[key] = key;
    });

    return Object.keys(groupByProp)
      .sort()
      .map(key => {
        return {
          text: `${key} (${groupByProp[key]})`,
          value: rawValues[key] || '-',
        };
      });
  };

  const vintageFilter = CreateFilter(props.filteredData, wine => wine.Year);
  const boxFilter = CreateFilter(props.filteredData, wine => wine.Box);
  const countryFilter = CreateFilter(props.filteredData, wine => wine.Country);
  const RegionFilter = CreateFilter(props.filteredData, wine => wine.Region);
  const colourFilter = CreateFilter(props.filteredData, wine => wine.Colour);
  const conditionFilter = CreateFilter(props.filteredData, wine => wine.Condition ?? '');

  const imageFilter = (value: any, record: StockWine) => {
    if (value === 1 && record.Pictures != null) {
      return true;
    }

    if (value === 0 && record.Pictures == null) {
      return true;
    }

    return false;
  };

  const newFilter = (value: any, record: StockWine) => {
    if (value === 1 && record.LastPoDateEpoch > daysBeforeNewEpoch) {
      return true;
    }
    return false;
  };

  const numSort = (selector: (w: StockWine) => number) => (a: StockWine, b: StockWine) => selector(a) - selector(b);
  const lexSort = (selector: (w: StockWine) => string) => (a: StockWine, b: StockWine) => selector(a).localeCompare(selector(b));

  function SanitiseNA(str?: string) {
    return str === 'NA' ? '' : str;
  }

  const getConditionLabel = (cond: string) => {
    switch (cond) {
      case 'A':
        return 'Pristine condition';
      case 'B':
        return 'Great condition with minor imperfections';
      case 'C':
        return 'Decent condition with noteworthy imperfections';
      case 'D':
        return 'Significant imperfections';
      default:
        return '';
    }
  };

  if (window.innerWidth > 1200) {
    // the reason why we don't use isDesktop is because it does not work with conditionally rendering columns
    const desktopColumns: ColumnsType<StockWine> = [
      {
        title: 'New',
        align: 'center',
        defaultFilteredValue: allQueryParams['new'],
        filters: [{ text: 'New', value: 1 }],
        render: (_: any, e: StockWine) => <span style={{ color: 'red' }}>{e.New && 'New!'}</span>,
        onFilter: newFilter,
      },
      {
        title: 'Quantity',
        align: 'center',
        render: (_: any, e: StockWine) => {
          const qty = parseInt(e.Quantity) > 120 ? `> 120` : `${e.Quantity}`;
          return <Highlight text={qty} />;
        },
        sorter: numSort(w => parseInt(w.Quantity, 10)),
      },
      {
        title: 'Vol',
        defaultFilteredValue: allQueryParams['vol'],
        sorter: numSort(w => parseInt(w.Volume, 10)),
        filters: CreateFilter(props.filteredData, w => `${w.Volume} ml`),
        render: (_: any, e: StockWine) => <Highlight text={`${e.Volume} ml`} />,
      },
      {
        title: 'Name',
        render: (_: any, e: StockWine) => <Highlight text={e.Name} />,
        sorter: lexSort(w => w.Name),
      },
      {
        title: 'Country',
        defaultFilteredValue: allQueryParams['country'],
        filters: countryFilter,
        sorter: lexSort(w => w.Name),
        onFilter: (value: any, record: StockWine) => record.Country === value || (!record.Country && value === '-'),
        render: (_: any, e: StockWine) => <Highlight text={e.Country} />,
      },
      {
        title: 'Region',
        defaultFilteredValue: allQueryParams['region'],
        filters: RegionFilter,
        sorter: lexSort(w => w.Region),
        onFilter: (value: any, record: StockWine) => record.Region === value || (!record.Region && value === '-'),
        render: (_: any, e: StockWine) => <Highlight text={SanitiseNA(e.Region)} />,
      },
      {
        title: 'Vintage',
        defaultFilteredValue: allQueryParams['vintage'],
        filters: vintageFilter,
        sorter: numSort(w => w.Year),
        onFilter: (value: any, record: StockWine) => record.Year === value || (!record.Year && value === '-'),
        render: (_: any, e: StockWine) => <Highlight text={e.Year.toString()} />,
      },
      {
        title: 'Colour',
        defaultFilteredValue: allQueryParams['colour'],
        filters: colourFilter,
        onFilter: (value: any, record: StockWine) => record.Colour === value || (!record.Colour && value === '-'),
        render: (_: any, e: StockWine) => <Highlight text={e.Colour} />,
      },
      {
        title: 'Price',
        sorter: numSort(w => w.Price),
        render: (_: any, e: StockWine) => (
          <b>
            <Highlight text={`€${Math.round(e.Price).toLocaleString('da-DK', { minimumFractionDigits: 2 })}`} />
          </b>
        ),
      },
      {
        title: 'Box',
        defaultFilteredValue: allQueryParams['box'],
        filters: boxFilter,
        sorter: lexSort(w => w.Box),
        onFilter: (value: any, record: StockWine) => record.Box === value,
        render: (_: any, e: StockWine) => <Highlight text={e.Box + (e.BoxSize || '')} />,
      },
      {
        title: 'Condition',
        defaultFilteredValue: allQueryParams['condition'],
        filters: conditionFilter,
        sorter: lexSort(w => w.Condition),
        onFilter: (value: any, record: StockWine) => record.Condition === value || (!record.Condition && value === '-'),
        align: 'center',
        render: (_: any, e: StockWine) => (
          <Tooltip destroyTooltipOnHide={{ keepParent: false }} title={getConditionLabel(e.Condition)}>
            <div>
              <Highlight text={e.Condition} />
            </div>
          </Tooltip>
        ),
      },
      {
        title: 'Note',
        align: 'center',
        render: (_: any, e: StockWine) => {
          let t = `${e.BacklabelType === 1 ? 'US Label' : ''} ${e.Remark !== '' ? e.Remark : ''}`;
          return <Highlight text={t} />;
        },
      },
      {
        title: 'Pictures',
        defaultFilteredValue: allQueryParams['pictures'],
        filters: [
          { text: 'Pictures', value: 1 },
          { text: 'No picture', value: 0 },
        ],
        onFilter: imageFilter,
        render: (_: any, e: StockWine) => <>{<Button onClick={() => props.showRequestImageModal(e)}>Request image</Button>}</>,
      },
    ];

    return desktopColumns;
  }

  const mobileColumns: ColumnsType<StockWine> = [
    {
      title: 'New',
      key: 'mobile-new',
      align: 'center',
      defaultFilteredValue: allQueryParams['new'],
      render: (_: any, e: StockWine) => <span style={{ color: 'red' }}>{e.New && 'New!'}</span>,
      filters: [{ text: 'New', value: 1 }],
      onFilter: newFilter,
    },
    {
      title: 'Wine',
      key: 'mobile-wine',
      render: (_: any, e: StockWine) => (
        <>
          <Highlight text={`${e.Name} ${e.Year}`} />
          <Highlight text={`${e.Quantity}x ${parseInt(e.Volume) / 1000}L - ${e.Box + (e.BoxSize || '')} @ €${Math.round(e.Price)}`} />
          <Highlight text={`${e.BacklabelType === 1 ? 'US Label' : ''} ${e.Remark}`} />
        </>
      ),
      sorter: (a: StockWine, b: StockWine) => a.Name.localeCompare(b.Name),
    },
    {
      title: 'Pictures',
      defaultFilteredValue: allQueryParams['pictures'],
      filters: [
        { text: 'Pictures', value: 1 },
        { text: 'No picture', value: 0 },
      ],
      onFilter: imageFilter,
      render: (_: any, e: StockWine) => <>{<Button onClick={() => props.showRequestImageModal(e)}>Request image</Button>}</>,
    },
  ];

  return mobileColumns;
}
