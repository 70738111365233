import GetExchangeRates from './GetExchangeRates';

const useCurrencyConverter = () => {
  const { data } = GetExchangeRates();

  const Convert = (
    value: number,
    valueCurrency: string,
    outputCurrency: Currency | '€' | '$' | '£' = '€'
  ): { originalCurrency: string; outputCurrency: string; value: number } => {
    let lookup: { [key: string]: string } = { '£': 'GBP', '€': 'EUR', $: 'USD', CHF: 'CHF', chf: 'CHF' };

    //If the output/input currency is a symbol, then look up the actual currency code.
    const ocurrency = (outputCurrency || 'EUR').length === 1 ? lookup[outputCurrency] : outputCurrency?.toLocaleUpperCase();
    const icurrency = (valueCurrency || 'EUR').length === 1 ? lookup[valueCurrency] : valueCurrency?.toLocaleUpperCase();

    //If the input and output currency is the same, just return the value
    if (icurrency === ocurrency || !data) {
      return { originalCurrency: icurrency, outputCurrency: ocurrency, value: Math.round(value * 100) / 100 };
    }

    //Convert any value to euro first
    const eurValue = icurrency !== 'EUR' ? value / data?.rates?.[icurrency] : value;
    //Convert the value in euro to the actual output currency unless the output is euro
    const outputValue = ocurrency === 'EUR' ? eurValue : eurValue * data?.rates?.[ocurrency];

    return { originalCurrency: icurrency, outputCurrency: ocurrency, value: Math.round(outputValue * 100) / 100 };
  };

  const ToSymbol = (value: Currency | CurrencySymbol) => {
    let lookup: { [key: string]: string } = { USD: '$', EUR: '€', GBP: '£' };
    return !lookup[value] ? value : lookup[value];
  };

  return { Convert: Convert, ToSymbol: ToSymbol, data: data };
};

export default useCurrencyConverter;
