import { Button, Col, Row, Typography } from 'antd';
import React from 'react';

import useRWHistory from '../hooks/general/useRWHistory';

export default function NotFound(props: { basePath: string }) {
  const history = useRWHistory();

  return (
    <Col span={24}>
      <Row justify="center">
        <Typography.Title level={2}>Not found</Typography.Title>
      </Row>
      <Row justify="center">
        <Button onClick={() => history.push('/' + props.basePath)} type="primary">
          Go App Landing Page
        </Button>
      </Row>
    </Col>
  );
}
