import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import getData from '../general/useGetApolloData';

type GetUserSettingsResponse = {
  currency: Currency;
};

const query = gql`
  query GetUserSettings {
    userSettings @client {
      currency
    }
  }
`;

const useUserSettings = () => {
  const { data, loading, error } = useQuery<ApolloDataWrapper<GetUserSettingsResponse>>(query);

  return { data: getData(data), loading, error };
};

export default useUserSettings;
