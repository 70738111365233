import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-boost';
import { ApolloLink, Observable, Operation } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { RestLink } from 'apollo-link-rest';
import camelcaseKeys from 'camelcase-keys';
import { DirectiveNode, ExecutionResult, SelectionSetNode } from 'graphql';
import React, { useEffect, useMemo, useRef } from 'react';
import { ApolloProvider } from 'react-apollo';

import { GetActiveUsers } from '../apps/trading-app/hooks/general/useActiveUsers';
import { notificationsQuery } from '../apps/trading-app/hooks/notifications/useNotifications';
import { useAuth0 } from '../helpers/useAuth0';
import ApolloResponseHook from './apollo-response-hook';

const convertJsonToFormData = (model: any, form: FormData | null = null, parentName: string = '') => {
  let formData = form || new FormData();

  Object.entries(model).forEach(prop => {
    const [propertyName, propertyValue] = prop;
    if (propertyValue === undefined || propertyName === '__typename') return;

    let formKey = parentName ? `${parentName}[${propertyName}]` : propertyName;

    if (propertyValue instanceof Date) {
      formData.append(formKey, propertyValue.toISOString());
    } else if (propertyValue instanceof File) {
      formData.append(formKey, propertyValue, propertyValue.name);
    } else if (propertyValue instanceof Array) {
      propertyValue.forEach((element, index) => {
        const arrayIndexKey = `${formKey}[${index}]`;

        if (typeof element !== 'object') formData.append(arrayIndexKey, element);
        else {
          convertJsonToFormData(element, formData, arrayIndexKey);
        }
      });
    } else if (typeof propertyValue === 'object' && !(propertyValue instanceof File)) {
      convertJsonToFormData(propertyValue, formData, formKey);
    } else {
      formData.append(formKey, (propertyValue as any).toString());
    }
  });

  return formData;
};

type DirectiveIterator = {
  readonly directives?: ReadonlyArray<DirectiveNode>;
  readonly selectionSet?: SelectionSetNode;
};

function findDirective(operation: Operation, directive: string) {
  const directives: DirectiveNode[] = [];

  const findDirectives = (selection: DirectiveIterator) => {
    selection.directives?.forEach(dir => {
      if (dir.name.value === directive) {
        directives.push(dir);
      }
    });

    selection.selectionSet?.selections.forEach(findDirectives);
  };

  operation.query.definitions.map(x => x as DirectiveIterator).forEach(findDirectives);

  return directives;
}

const Apollo: React.FunctionComponent = props => {
  const { getTokenSilently } = useAuth0();
  const tokenProvider = useRef(getTokenSilently);

  useEffect(() => {
    tokenProvider.current = getTokenSilently;
  }, [getTokenSilently]);

  // This is a memo, because otherwise Auth0 provider would cause this to be created
  // on each history push. This makes it only be re-created when the user logs in / out
  const client = useMemo(() => {
    const authLink = new ApolloLink((operation, next) => {
      const restDirective = findDirective(operation, 'rest');

      if (restDirective.length && restDirective[0].arguments?.some(arg => arg.name.value === 'endpoint')) {
        // if rest directive has endpoint option, don't add authorization header
        return next(operation);
      } else {
        // no endpoint specified in rest directive, so we're hitting the RW api
        // add authorization header

        // create a new observable for Apollo, which will get the auth token, patch the header and then notify apollo
        // it has to be this way due to getTokenSilently being async
        return new Observable(observable => {
          let sub: any = null;

          tokenProvider.current().then(token => {
            operation.setContext((context: Record<string, any>) => {
              return {
                headers: {
                  ...context.headers,
                  Authorization: `Bearer ${token}`,
                },
              };
            });

            sub = next(operation).subscribe(observable);
          });

          return () => (sub ? sub.unsubscribe() : null);
        });
      }
    });

    const graphLink = new HttpLink({
      uri: window.ENVARGS.REACT_APP_RAREWINE_GRAPH_URL,
    });

    const restLink = new RestLink({
      endpoints: {
        bingSearch: 'https://api.cognitive.microsoft.com/bing/v7.0/',
        cosmosSearch:
          'https://rarewine-search.search.windows.net/indexes/cosmosdb-index/docs?api-version=2019-05-06&api-key=C9C7EDFD80BE138027CB412695BB60DC',
      },
      responseTransformer: async response => {
        let transform = response.url.includes('rarewine-search.search.windows.net');

        return response
          .json()
          .then((data: any) => {
            if (transform) {
              let newData: any = {};
              Object.getOwnPropertyNames(data).forEach(x => {
                const newKey = x.replace('@', '').replace('.', '');

                if (x === 'value') {
                  newData[newKey] = camelcaseKeys(data[x]);
                } else {
                  newData[newKey] = data[x];
                }
              });
              return newData;
            }
            return data;
          })
          .then((response: ExecutionResult) => {
            ApolloResponseHook(response);

            return response;
          });
      },
      uri: window.ENVARGS.REACT_APP_RAREWINE_API_URL,
      typePatcher: {
        WineSearchResult: data => {
          if (data !== null) {
            data.value = data.value.map((wine: Wine) => ({
              __typename: 'Wine',
              ...wine,
            }));

            return data;
          }
        },
        GetUser: data => {
          if (data !== null) {
            data.user = { ...data.user, __typename: 'User' };
          }

          return data;
        },
        GetSuppliers: (data: { result: Supplier[] }) => {
          if (data !== null) {
            data.result = data.result.map(res => {
              return {
                __typename: 'Supplier',
                ...res,
              };
            });
          }
          return data;
        },
        GetWineLinksResult: data => {
          if (data.results !== null) {
            data.results = data.results.map((offerLink: any) => ({
              __typename: 'OfferLink',
              ...offerLink,
            }));

            return data;
          }
        },
        SellerTemplatePayload: data => {
          if (data.result !== null) {
            data.result = data.result.map((sellerTemplate: any) => ({
              __typename: 'SellerTemplate',
              ...sellerTemplate,
            }));

            return data;
          }
        },
        OfferUploadPaylad: data => {
          if (data.results !== null) {
            data.results = data.results.map((offerupload: any) => ({
              __typename: 'OfferUpload',
              ...offerupload,
            }));

            return data;
          }
        },
      },
      bodySerializers: {
        form: (data: any, headers: Headers) => {
          const formData = convertJsonToFormData(data);

          headers.delete('Content-Type');

          return { body: formData, headers };
        },
      },
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          // eslint-disable-next-line
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );
      // eslint-disable-next-line
      if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const client = new ApolloClient({
      cache: new InMemoryCache({
        dataIdFromObject: object => (object.id ? `${object.__typename}_${object.id}` : null),
      }),
      link: ApolloLink.from([errorLink, authLink, restLink, ApolloLink.split(op => op.getContext().clientName === 'graph', graphLink)]),
      resolvers: {
        Mutation: {
          addNotification: (_, variables, { cache, getCacheKey }) => {
            const notifications = cache.readQuery({ query: notificationsQuery });
            const list = notifications.notifications;

            const newNotification = {
              __typename: 'Notification',
              createdAt: { __typeName: 'CreatedAt' },
              modifiedAt: { __typename: 'ModifiedAt ' },
              ...variables.input,
            };

            const data = { notifications: [...list, newNotification] };
            cache.writeData({ data });
            return newNotification;
          },
          setActiveUser: (_, variables, { cache, getCacheKey }) => {
            const cacheUsers: { activeUsers: ActiveUser[] } = cache.readQuery({ query: GetActiveUsers });
            const users = cacheUsers.activeUsers;
            const user: ActiveUser = { __typename: 'ActiveUser', ...variables.input };

            let activeUser = users.find(x => x.email === user.email) || null;

            if (activeUser) {
              activeUser.location = variables.input.location;
            } else {
              activeUser = user;
            }

            const data = {
              activeUsers: [
                activeUser,
                ...users.filter(x => x.email !== activeUser!.email && !((Date.now() - x.lastUpdated) / 1000 > 360)),
              ],
            };

            cache.writeData({ data });
            return activeUser;
          },
          dummy: (_, variables) => {
            return true;
          },
        },
      },
    });

    client.cache.writeData({
      data: {
        stats: {
          __typename: 'rwStats',
          totalValue: 0,
          potentialEarnings: 0,
          totalBottles: 0,
          totalOffers: 0,
          oldestWine: 0,
          liters: 0,
          split: 0,
          quarter: 0,
          clavelin_half: 0,
          half: 0,
          tenth: 0,
          pinte: 0,
          clavelin_full: 0,
          rioja: 0,
          lung_size: 0,
          standard: 0,
          cylinder: 0,
          magnum: 0,
          marie_jeanne: 0,
          double_magnum: 0,
          jerobaum: 0,
          rehoboam: 0,
          mckenzie: 0,
          imperial: 0,
          salmanazar: 0,
          balthazar: 0,
          nebuchadnezzar: 0,
          melchior: 0,
          primat: 0,
          melchizedek: 0,
          maximus: 0,
        },
        exchangeRates: {
          date: '',
          rates: { GBP: 1, DKK: 0.5 },
          base: 'EUR',
        },
        token: null,
        userSettings: {
          __typename: 'UserSettings',
          currency: 'EUR',
        },
        notifications: [],
        activeUsers: [],
      },
    });

    return client;
  }, [tokenProvider]);

  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>{props.children}</ApolloHooksProvider>
    </ApolloProvider>
  );
};

export default Apollo;
