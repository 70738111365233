import React from 'react';

import useInterval from '../../apps/trading-app/hooks/general/useInterval';
import crate1 from '../../images/splash/crate_01.jpg';
import OverlayLoading from '../overlay-loading/overlay-loading';

import style from '../splash-screen/splash-screen.module.scss';

export default function ConnectionError() {
  useInterval(() => {
    const current = window.location.href;
    window.location.href = current;
  }, 10000);

  return (
    <>
      <img alt="" className={style.bg} src={crate1} />
      <OverlayLoading overrideText="Unable to connect, retrying in 10 seconds..." />
    </>
  );
}
