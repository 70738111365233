import { useEffect } from 'react';

import useForceUpdate from '../general/useForceUpdate';
import useAuthToken from '../meta/useAuthToken';

let data: InStockWine[] = [];
let dataMap: { [key: string]: NavItemVariant[] } = {};
let loading = false;
let onLoaded: (() => void)[] = [];

function getKey(variant: NavItemVariant) {
  const pck = variant.packagingString.includes('OWC') ? 'OWC' : variant.packagingString.includes('OC') ? 'OC' : 'Loose';
  return `${variant.lwin}_${variant.year || -1}_${variant.bottleSize}_${pck}`;
}

async function LoadData(accessToken: string) {
  if (loading) return;
  loading = true;

  let response = await fetch(`${window.ENVARGS.REACT_APP_RAREWINE_API_URL}nav/warehouse`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  var dataArray = (await response.json()) as NavItemVariant[];

  var map = dataArray.reduce((prev: { [key: string]: InStockWine }, cur) => {
    const key = cur.id; //getKey(cur);
    if (!prev[key]) {
      prev[key] = {
        wine: {
          id: cur.id,
          lwin: cur.lwin,
          description: cur.description,
          name: cur.namePart2,
          producer: cur.namePart1,
          subRegion: cur.namePart3,
          year: cur.year,
          bottleSize: cur.bottleSizeString,
          bottleSizeNumerical: cur.bottleSize,
          totalAvailable: 0,
          lastPoDate: new Date(cur.lastPoDate),
        },
        variants: [],
      };
    }

    const inStock = prev[key];
    inStock.variants.push(cur);
    inStock.wine.totalAvailable += cur.available;

    return prev;
  }, {});

  data = Object.values(map);

  dataMap = {};
  data.forEach(w =>
    w.variants?.forEach(v => {
      const key = getKey(v);

      if (!dataMap[key]) {
        dataMap[key] = [];
      }

      dataMap[key].push(v);
    })
  );

  loading = false;

  onLoaded.forEach(callback => callback());
}

export default function useWarehouseData() {
  const authToken = useAuthToken();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    onLoaded.push(forceUpdate);

    return () => {
      const idx = onLoaded.indexOf(forceUpdate);

      onLoaded.splice(idx, 1);
    };
  }, []);

  useEffect(() => {
    if (authToken && data.length === 0) {
      LoadData(authToken);
    }
  }, [authToken]);

  return {
    data: data,
    dataMap: dataMap,
    loading: loading,
    error: false,
    reload: () => LoadData(authToken!),
  };
}
