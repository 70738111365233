import { List } from 'antd';
import React from 'react';

import PictureCard from '../picture-card/picture-card';

import style from './../picture-list.module.scss';

export default function PictureDay(props: { data: StockWineImagePayload[]; activePictureID: string | undefined }) {
  function createPictureList(dayName: string, data: StockWineImagePayload[]) {
    let date = new Date(data[0].imageResult.modifiedAt.date);

    return (
      <div>
        <div className={style.dayText}>
          {dayName} ({date.getDate()}/{date.getMonth()})
        </div>
        <List
          grid={{
            gutter: 4,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 3,
            xxl: 5,
          }}
          dataSource={data}
          renderItem={item => (
            <List.Item style={{ display: 'flex', justifyContent: 'center' }}>
              <PictureCard data={item} activePictureID={props.activePictureID} />
            </List.Item>
          )}
          style={{ marginBottom: '15px', justifyContent: 'center' }}
        ></List>
      </div>
    );
  }

  if (props.data) {
    let date = new Date(props.data[0]?.imageResult.modifiedAt.date).getDay();

    switch (date) {
      case 1:
        return createPictureList('Monday', props.data);
      case 2:
        return createPictureList('Tuesday', props.data);
      case 3:
        return createPictureList('Wednesday', props.data);
      case 4:
        return createPictureList('Thursday', props.data);
      case 5:
        return createPictureList('Friday', props.data);
      case 6:
        return createPictureList('Saturday', props.data);
      case 7:
        return createPictureList('Sunday', props.data);
    }
  }

  return <div></div>;
}
