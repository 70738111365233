import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { useState } from 'react';

export default function useZip() {
  const [zip, setZip] = useState(new JSZip());

  const addToZip = (fileName: string, file: Blob) => {
    zip.file<'blob'>(fileName, file);
  };

  const download = (fileName: string) => {
    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, fileName);
    });
  };

  const empty = () => {
    setZip(new JSZip());
  };

  return { add: addToZip, download: download, clear: empty };
}
