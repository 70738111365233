import { BellOutlined } from '@ant-design/icons';
import { Badge, Drawer, List } from 'antd';
import React, { useState } from 'react';

import useNotifications, { notificationsQuery } from '../../../../hooks/notifications/useNotifications';
import useSetUserNotificationsToRead from '../../../../hooks/notifications/useSetUserNotificationsToRead';
import useUser from '../../../../hooks/user/useUser';
import AgentMatchNotification from './notification-renders/agent-match-notification';
import NotFoundNotification from './notification-renders/not-found-notification';
import OnOfferParsingStartedNotification from './notification-renders/on-offer-parsing-started-notification';
import OnOfferRecjectedInvalidAttachmentNotification from './notification-renders/on-offer-rejected-invalid-attachment';
import OnOfferRecjectedNoSenderNotification from './notification-renders/on-offer-rejected-no-sender-notification';
import OnOfferRejectedNotification from './notification-renders/on-offer-rejected-notification';
import WarehouseNewImages from './notification-renders/warehouse-new-images';

import style from './notifications.module.scss';

const Notifications = () => {
  const [showNotifications, setShowNotifications] = useState(false);

  const { data: user } = useUser();

  const { data } = useNotifications({
    userId: user?.id,
  });

  const { mutate } = useSetUserNotificationsToRead();

  return (
    <>
      <span
        className={style.icon}
        onClick={() => {
          if (data?.some(x => !x.isRead)) {
            mutate({
              variables: { input: { userId: user!.id } },
              refetchQueries: [{ query: notificationsQuery, variables: { userId: user!.id } }],
            });
          }

          setShowNotifications(true);
        }}
      >
        <Badge count={data?.filter(x => !x.isRead).length || 0}>
          <BellOutlined className={style.bell} />
        </Badge>
      </span>
      <Drawer
        title="Notifications"
        placement="right"
        closable={false}
        onClose={() => setShowNotifications(false)}
        visible={showNotifications}
        width="15%"
        bodyStyle={{ padding: 0 }}
      >
        <List
          dataSource={data}
          renderItem={item => {
            switch (item.context) {
              case 'OFFER_PROCESSING':
                switch (item.event) {
                  case 'REJECTED':
                    return (
                      <List.Item>
                        <OnOfferRejectedNotification notification={item} />
                      </List.Item>
                    );
                  case 'NO_SENDER':
                    return (
                      <List.Item>
                        <OnOfferRecjectedNoSenderNotification notification={item} />
                      </List.Item>
                    );
                  case 'INVALID_ATTACHMENT':
                    return (
                      <List.Item>
                        <OnOfferRecjectedInvalidAttachmentNotification notification={item} />
                      </List.Item>
                    );
                  case 'ACCEPTED':
                    return (
                      <List.Item>
                        <OnOfferParsingStartedNotification notification={item} />
                      </List.Item>
                    );
                }
                break;
              case 'AGENT':
                switch (item.event) {
                  case 'MATCHED':
                    return (
                      <List.Item>
                        <AgentMatchNotification notification={item} />
                      </List.Item>
                    );
                }
                break;
              case 'WAREHOUSE':
                switch (item.event) {
                  case 'NEW_IMAGES':
                    return (
                      <List.Item>
                        <WarehouseNewImages notification={item} />
                      </List.Item>
                    );
                }
                break;
              default:
                return (
                  <List.Item>
                    <NotFoundNotification notification={item} />
                  </List.Item>
                );
            }
          }}
        />
      </Drawer>
    </>
  );
};

export default Notifications;
