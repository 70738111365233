import color from './color.module.scss';
import cursor from './cursor.module.scss';
import flash from './flash.module.scss';
import font from './font.module.scss';
import opacity from './opacity.module.scss';
import size from './size.module.scss';
import transition from './transition.module.scss';

function wrap(text: string) {
  return ' ' + text + ' ';
}

const effects = {
  Focus: {
    Color: {
      Primary: wrap(color.focusColorPrimary),
      Link: wrap(color.focusColorLink),
      Success: wrap(color.focusColorSuccess),
      Warning: wrap(color.focusColorWarning),
      Error: wrap(color.focusColorError),
      White: wrap(color.focusColorWhite),
    },
  },
  Active: {
    Size: {
      Smallest: wrap(size.activeSizeSmallest),
      Smaller: wrap(size.activeSizeSmaller),
      Small: wrap(size.activeSizeSmall),
      Normal: wrap(size.activeSizeNormal),
      Big: wrap(size.activeSizeBig),
      Bigger: wrap(size.activeSizeBigger),
      Biggest: wrap(size.activeSizeBiggest),
    },
    Color: {
      Primary: wrap(color.activeColorPrimary),
      Link: wrap(color.activeColorLink),
      Success: wrap(color.activeColorSuccess),
      Warning: wrap(color.activeColorWarning),
      Error: wrap(color.activeColorError),
      White: wrap(color.activeColorWhite),
    },
  },
  Hover: {
    Font: {
      Bold: wrap(font.hoverBold),
      Bolder: wrap(font.hoverBolder),
      Boldest: wrap(font.hoverBoldest),
    },
    Cursor: {
      Pointer: wrap(cursor.hoverPointer),
      Default: wrap(cursor.hoverDefault),
      Disabled: wrap(cursor.hoverDisabled),
    },
    Color: {
      Primary: wrap(color.hoverColorPrimary),
      Link: wrap(color.hoverColorLink),
      Success: wrap(color.hoverColorSuccess),
      Warning: wrap(color.hoverColorWarning),
      Error: wrap(color.hoverColorError),
      White: wrap(color.hoverColorWhite),
    },
    Opacity: {
      Opacity0: wrap(opacity.hoverOpacity0),
      Opacity10: wrap(opacity.hoverOpacity10),
      Opacity20: wrap(opacity.hoverOpacity20),
      Opacity30: wrap(opacity.hoverOpacity30),
      Opacity40: wrap(opacity.hoverOpacity40),
      Opacity50: wrap(opacity.hoverOpacity50),
      Opacity60: wrap(opacity.hoverOpacity60),
      Opacity70: wrap(opacity.hoverOpacity70),
      Opacity80: wrap(opacity.hoverOpacity80),
      Opacity90: wrap(opacity.hoverOpacity90),
      Opacity100: wrap(opacity.hoverOpacity100),
    },
    Size: {
      Smallest: wrap(size.hoverSizeSmallest),
      Smaller: wrap(size.hoverSizeSmaller),
      Small: wrap(size.hoverSizeSmall),
      Normal: wrap(size.hoverSizeNormal),
      Big: wrap(size.hoverSizeBig),
      Bigger: wrap(size.hoverSizeBigger),
      Biggest: wrap(size.hoverSizeBiggest),
    },
  },
  Static: {
    Color: {
      Primary: wrap(color.colorPrimary),
      Link: wrap(color.colorLink),
      Success: wrap(color.colorSuccess),
      Warning: wrap(color.colorWarning),
      Error: wrap(color.colorError),
      White: wrap(color.colorWhite),
    },
    Opacity: {
      Hidden: wrap(opacity.hidden),
      Opacity0: wrap(opacity.opacity0),
      Opacity10: wrap(opacity.opacity10),
      Opacity20: wrap(opacity.opacity20),
      Opacity30: wrap(opacity.opacity30),
      Opacity40: wrap(opacity.opacity40),
      Opacity50: wrap(opacity.opacity50),
      Opacity60: wrap(opacity.opacity60),
      Opacity70: wrap(opacity.opacity70),
      Opacity80: wrap(opacity.opacity80),
      Opacity90: wrap(opacity.opacity90),
      Opacity100: wrap(opacity.opacity100),
    },
    Size: {
      Smallest: wrap(size.sizeSmallest),
      Smaller: wrap(size.sizeSmaller),
      Small: wrap(size.sizeSmall),
      Normal: wrap(size.sizeNormal),
      Big: wrap(size.sizeBig),
      Bigger: wrap(size.sizeBigger),
      Biggest: wrap(size.sizeBiggest),
    },
    Font: {
      Bold: wrap(font.bold),
      Bolder: wrap(font.bolder),
      Boldest: wrap(font.boldest),
    },
    Cursor: {
      DisableEvents: wrap(cursor.pointerEventsNone),
    },
  },
  Transition: {
    From: {
      Left: wrap(transition.fromLeft),
      Right: wrap(transition.fromRight),
      Top: wrap(transition.fromTop),
      Bottom: wrap(transition.fromBottom),
      Center: wrap(transition.fromCenter),
    },
    VeryQuick: {
      Linear: wrap(transition.VeryQuickLinear),
      Overshoot: wrap(transition.VeryQuickOvershoot),
      Anticipate: wrap(transition.VeryQuickAnticipate),
      AnticipateOvershoot: wrap(transition.VeryQuickAnticipateOvershoot),
    },
    Quick: {
      Linear: wrap(transition.QuickLinear),
      Overshoot: wrap(transition.QuickOvershoot),
      Anticipate: wrap(transition.QuickAnticipate),
      AnticipateOvershoot: wrap(transition.QuickAnticipateOvershoot),
    },
    Normal: {
      Linear: wrap(transition.Linear),
      Overshoot: wrap(transition.Overshoot),
      Anticipate: wrap(transition.Anticipate),
      AnticipateOvershoot: wrap(transition.AnticipateOvershoot),
    },
    Slow: {
      Linear: wrap(transition.SlowLinear),
      Overshoot: wrap(transition.SlowOvershoot),
      Anticipate: wrap(transition.SlowAnticipate),
      AnticipateOvershoot: wrap(transition.SlowAnticipateOvershoot),
    },
    VerySlow: {
      Linear: wrap(transition.VerySlowLinear),
      Overshoot: wrap(transition.VerySlowOvershoot),
      Anticipate: wrap(transition.VerySlowAnticipate),
      AnticipateOvershoot: wrap(transition.VerySlowAnticipateOvershoot),
    },
  },
  Animations: {
    Flash: {
      Quick: {
        Primary: wrap(flash.quickPrimary),
        Link: wrap(flash.quickLink),
        Success: wrap(flash.quickSuccess),
        Warning: wrap(flash.quickWarning),
        Error: wrap(flash.quickError),
      },
      Normal: {
        Primary: wrap(flash.primary),
        Link: wrap(flash.link),
        Success: wrap(flash.success),
        Warning: wrap(flash.warning),
        Error: wrap(flash.error),
      },
      Slow: {
        Primary: wrap(flash.slowPrimary),
        Link: wrap(flash.slowLink),
        Success: wrap(flash.slowSuccess),
        Warning: wrap(flash.slowWarning),
        Error: wrap(flash.slowError),
      },
    },
  },
};

const packs = {
  Discoverable: effects.Static.Opacity.Opacity0 + effects.Hover.Opacity.Opacity100,
  Clickable: {
    Primary:
      effects.Hover.Cursor.Pointer +
      effects.Hover.Color.Primary +
      effects.Hover.Size.Bigger +
      effects.Active.Size.Biggest +
      effects.Transition.Quick.AnticipateOvershoot,
    Success:
      effects.Hover.Cursor.Pointer +
      effects.Hover.Color.Success +
      effects.Hover.Size.Bigger +
      effects.Active.Size.Biggest +
      effects.Transition.Quick.AnticipateOvershoot,
    Error:
      effects.Hover.Cursor.Pointer +
      effects.Hover.Color.Error +
      effects.Hover.Size.Bigger +
      effects.Active.Size.Biggest +
      effects.Transition.Quick.AnticipateOvershoot,
    Subtle:
      effects.Hover.Cursor.Pointer + effects.Hover.Size.Big + effects.Active.Size.Bigger + effects.Transition.Quick.AnticipateOvershoot,
    VerySubtle: effects.Hover.Cursor.Pointer + effects.Hover.Font.Bold + effects.Transition.Quick.AnticipateOvershoot,
    Disabled: effects.Hover.Cursor.Disabled + effects.Static.Opacity.Opacity50,
  },
};

const CSS = {
  Effects: { ...effects },
  Packs: { ...packs },
};

export default CSS;
