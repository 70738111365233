import { useState, useEffect } from 'react';

export default function useBlobFetch<T>(
  url: string
): { data: T | undefined; error: any | undefined; loading: boolean; lastModified: string | null } {
  const [data, setData] = useState<T | undefined>(undefined);
  const [error, setError] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [lastModified, setLastModified] = useState<string | null>('');

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const res = await fetch(`${window.ENVARGS.REACT_APP_BLOBSTORAGE}/${url}`);
        const date = res.headers.get('Last-Modified');
        const json = (await res.json()) as T;
        setLastModified(date);
        setData(json);
        setLoading(false);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, []);

  return { data, error, loading, lastModified };
}
