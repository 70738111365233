import { Spin } from 'antd';
import React from 'react';

import useRWHistory from '../hooks/general/useRWHistory';

export default function MsgraphLanding() {
  const history = useRWHistory();

  history.push('/');

  return <Spin />;
}
