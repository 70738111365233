import { ModalFuncProps } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useModal from 'antd/lib/modal/useModal';
import React from 'react';

type ScalableModalProps = Omit<ModalFuncProps, 'width'> & {
  desktopWidth?: string | number;
};
type ScalableModalFunc = (
  props: ScalableModalProps
) => {
  destroy: () => void;
  update: (configUpdate: any) => void;
};

export type ScalableModal = {
  info: ScalableModalFunc;
  success: ScalableModalFunc;
  error: ScalableModalFunc;
  confirm: ScalableModalFunc;
};

function useScalableModal(): [ScalableModal, React.ReactElement<any, string | React.JSXElementConstructor<any>>] {
  const [modal, modalContext] = useModal();
  const isDesktop = useBreakpoint().lg;
  const scalableModal: ScalableModal = {
    info: props => modal.info({ ...props, width: isDesktop ? props.desktopWidth : '95%' }),
    success: props => modal.success({ ...props, width: isDesktop ? props.desktopWidth : '95%' }),
    error: props => modal.error({ ...props, width: isDesktop ? props.desktopWidth : '95%' }),
    confirm: props => modal.confirm({ ...props, width: isDesktop ? props.desktopWidth : '95%' }),
  };

  return [scalableModal, modalContext];
}

export default function WithModal(props: { children: (modal: ScalableModal) => React.ReactNode }) {
  const [modal, modalContext] = useScalableModal();
  return (
    <>
      {props.children(modal)}
      {modalContext}
    </>
  );
}
