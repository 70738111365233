import React from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Router, Switch } from 'react-router-dom';

import ApplicationInsightsProvider from './apps/trading-app/components/application-insights/ApplicationInsights';
import EventsProvider from './apps/trading-app/components/events-context/events-provider';
import AppHost from './components/app-host/AppHost';
import AuthGate from './components/auth-gate/auth-gate';
import Callback from './components/callback/callback';
import MsgraphLanding from './components/msgraph/msgraph';
import ApplicationInsightsProviderStockList from './components/stock-list/components/applicationinsights';
import StockList from './components/stock-list/stock-list';
import { Auth0Provider } from './helpers/useAuth0';
import history from './history';
import messages from './localization/en.json';
import Apollo from './setup/apollo';

function App() {
  if (window.location.hostname === 'wines.rarewine.com') {
    if (window.location.pathname !== '/stocklist') {
      window.location.href = 'https://wines.rarewine.com/stocklist';
      return <div>Loading the stocklist</div>;
    }
  } else if (window.location.hostname.includes('rarewine.com') && !window.location.hostname.startsWith('portal.')) {
    window.location.href = 'https://www.rarewine.dk';
    return <div>You're not allowed here.</div>;
  }

  return (
    //The routing is wired. Notice the history model used is `memoryHistory` beacuse the static-website / blob storage hosting of azure don't support url changes.
    <Router history={history}>
      <IntlProvider locale="en" messages={messages}>
        <Switch>
          <Route exact path="/stockList">
            <ApplicationInsightsProviderStockList>
              <StockList />
            </ApplicationInsightsProviderStockList>
          </Route>
          <Auth0Provider>
            <ApplicationInsightsProvider>
              <Apollo>
                <Route exact path="/callback">
                  <Callback />
                </Route>
                <Route exact path="/msgraph">
                  <MsgraphLanding />
                </Route>
                <Route path="*">
                  <AuthGate>
                    <EventsProvider>
                      <AppHost />
                    </EventsProvider>
                  </AuthGate>
                </Route>
              </Apollo>
            </ApplicationInsightsProvider>
          </Auth0Provider>
        </Switch>
      </IntlProvider>
    </Router>
  );
}

export default App;
