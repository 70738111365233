import { Alert, Button, PageHeader, Progress, Upload } from 'antd';
import React from 'react';

import GetLwinStateQuery from '../../hooks/wine/GetLwinStateQuery';
import PatchLatestLwin from '../../hooks/wine/PatchLatestLwin';
import SyncLwinMutation from '../../hooks/wine/SyncLwinMutation';

const LwinSync = () => {
  const { data: lwinState } = GetLwinStateQuery(2000);
  const { mutate: sync, loading: syncLoading, data } = SyncLwinMutation();
  const { mutate: patch, loading: patchLoading } = PatchLatestLwin();

  const anyLoading = syncLoading || patchLoading;

  return (
    <>
      <PageHeader
        title={'Sync Liv|EX data'}
        subTitle={
          lwinState?.syncInProgress
            ? `Sync in progress... estimated time remaining: ${lwinState.estimatedSyncTimeRemaining}`
            : `Last sync: ${lwinState?.lastSynchronizationDate || 'Never'}`
        }
      ></PageHeader>
      {lwinState?.syncInProgress && (
        <>
          <Progress
            type="circle"
            width={512}
            percent={(100 * lwinState.winesSynced) / lwinState.winesToSync}
            format={() => `${lwinState.winesSynced} / ${lwinState.winesToSync}`}
          />
        </>
      )}
      {!lwinState?.syncInProgress && (
        <>
          <Button loading={anyLoading} onClick={() => patch()}>
            Patch Latest Lwin File
          </Button>
          <Button loading={anyLoading} onClick={() => sync()}>
            Auto Synchronize from latests Patched Lwin File
          </Button>
          <Upload action={window.ENVARGS.REACT_APP_RAREWINE_API_URL + 'wine/syncManual'} accept=".xlsx" name="file">
            <Button loading={anyLoading}>Synchronize from file</Button>
          </Upload>

          {!syncLoading && data && !data.started && <Alert message="No new wines" />}
        </>
      )}
    </>
  );
};

export default LwinSync;
