import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql.macro';
import { useState } from 'react';

import getData from '../../trading-app/hooks/general/useGetApolloData';

const query = gql`
  query GetImageProductMaps {
    GetImageProductMaps @rest(type: "GetImageProductMaps", path: "warehouse/allcases", method: "GET") {
      id
      productIds
      imageIds
      author
      creationDate
      message
      location
      orderNumber
    }
  }
`;

export default function useImageProductMaps() {
  const [refetching, setRefetching] = useState(false);
  const { data, loading, error, refetch } = useQuery<ApolloDataWrapper<ProductImageMapRequest[]>>(query);

  return {
    data: getData<ProductImageMapRequest[]>(data),
    loading: loading || refetching,
    error,
    refetch: async () => {
      setRefetching(true);
      await refetch();
      setRefetching(false);
    },
  };
}
