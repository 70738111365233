import { useEffect } from 'react';

import { createEvent, useForceUpdateEvent } from '../trading-app/hooks/general/useForceUpdate';
import useLocalStorage, { useLocalStorageManager } from './useLocalStorage';

const guidePrefix = 'guide|';

const guideLoadedEvent = createEvent();
const loadedGuides: { [key: string]: number } = {};

type Guide = {
  step: number;
  data?: any;
};

export function useGuideManager() {
  const forceUpdate = useForceUpdateEvent(guideLoadedEvent);
  const { getLocalState, setLocalState } = useLocalStorageManager();

  return {
    loadedGuides: Object.entries(loadedGuides)
      .filter(([key, value]) => value > 0)
      .map(([key, value]) => key),
    getGuideState: (key: string) => {
      getLocalState<Guide>(guidePrefix + key);
    },
    resetGuide: (key: string) => {
      setLocalState<Guide>(guidePrefix + key, { step: 0 });
      forceUpdate();
    },
    stopGuide: (key: string) => {
      setLocalState<Guide>(guidePrefix + key, { step: -1 });
    },
  };
}

export default function useGuide<TData = any>(key: string) {
  const forceUpdate = useForceUpdateEvent(guideLoadedEvent);

  // reference counting in a global object so the manager
  // can keep track of which guides are in scope
  useEffect(() => {
    if (loadedGuides[key] === undefined) {
      loadedGuides[key] = 1;
    } else {
      loadedGuides[key]++;
    }

    forceUpdate();

    return () => {
      loadedGuides[key]--;
      forceUpdate();
    };
  }, []);

  // start at -1 so guide isn't triggered before it's reset
  const [current, setCurrent] = useLocalStorage<Guide>(guidePrefix + key, { step: -1 });

  return {
    guideStep: current.step,
    guideData: current.data as TData,
    showGuide: (step: number) => (step === current.step ? true : false),
    completeStep: (completedStep: number, data: any = null) => {
      // don't complete step if this is not the step we're at
      if (completedStep === current.step) {
        setCurrent({
          step: current.step + 1,
          data: data,
        });
        forceUpdate();
      }
    },
    resetGuide: () => {
      setCurrent({ step: 0 });
      forceUpdate();
    },
  };
}
