import { message } from 'antd';
import { useEffect, useState } from 'react';

import useStateArray from '../general/useStateArray';
import useAuthToken from '../meta/useAuthToken';

// state managed outside of react to prevent wonky lifetime
let dataBuffer: OfferLine[] = [];
let loading = false;
let current = 0;
let goal = 1;
let requests = 0;

// notify react hook that data has changed
let notifyProgress: (() => void) | null = null;

function startLoadData(token: string) {
  // concurrency gate
  if (loading) return;
  loading = true;

  // reset state
  dataBuffer = [];
  current = 0;
  goal = 1;
  requests = 1;
  notifyProgress?.();

  // send request to get list of offers
  fetch(`${window.ENVARGS.REACT_APP_RAREWINE_API_URL}offer/getActiveOffers/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => response.json())
    .then((offers: Offer[]) => {
      // sum of lines in all offers, used for progress calculation
      goal = offers.map(x => x.amount).reduce((a, b) => a + b, 0);

      requests = offers.length;

      notifyProgress?.();

      // send a request for each offer to get the lines for that offer
      offers.forEach(offer => {
        fetch(`${window.ENVARGS.REACT_APP_RAREWINE_API_URL}offer/getActiveLinesForOffer/${offer.id!}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(response => response.json())
          .then((offerlines: OfferLine[]) => {
            current += offer.amount;
            dataBuffer.push(...offerlines);
          })
          .catch(error => {
            message.error('' + error.message);
          })
          .finally(() => {
            requests--;

            if (requests <= 0) {
              // adjust goal to reflect that we're finished in case there were small variances
              goal = current;
              loading = false;
            }

            // notify useGetOffers hook if mounted
            notifyProgress?.();
          });
      });
    })
    .catch(e => {
      loading = false;
      message.error(e.message);
    });
}

const useGetOffers = () => {
  const token = useAuthToken();
  const [loadingCurrent, setLoadingCurrent] = useState(current);
  const [loadingTotal, setLoadingTotal] = useState(goal);
  const data = useStateArray<OfferLine>(!loading && dataBuffer.length ? dataBuffer : []);

  // subscribe/unsubscribe to data fetching updates
  useEffect(() => {
    notifyProgress = () => {
      setLoadingCurrent(current);
      setLoadingTotal(goal);

      // if we're done fetching data, set the exposed hook data to point to the filled data buffer
      if (current === goal) {
        data.api.set(dataBuffer);
      }
    };

    return () => {
      notifyProgress = null;
    };
  }, []);

  // start initial data fetching if we have a token and no data
  useEffect(() => {
    if (token && !data.array.length) {
      getOffers();
    }
  }, [token]);

  const getOffers = () => {
    if (token) {
      startLoadData(token);
    } else {
      message.error('no auth token');
    }
  };

  return {
    data: data.array,
    loadingCurrent,
    loadingTotal,
    refetch: getOffers,
  };
};

export default useGetOffers;
