import { Button, Slider, Space, Table, Tag, Tooltip } from 'antd';
import React, { useState } from 'react';
import Highlighter from 'react-highlight-words';

import Css from '../../../../../helpers/css/Css';
import WithModal from '../../../../../helpers/with-modal';
import useGuide from '../../../../sharedHooks/useGuide';
import LivExLogo from './liv-ex-logo';

import style from '../instock.module.scss';

const InlineTyndhaaret = ({ value, bottleSizeNumerical, available }: any) => {
  const [quantity, setQuantity] = useState<any>(1);

  return (
    <div>
      <h3>How many bottles do you want?</h3>
      <br />
      <Slider tooltipVisible={true} defaultValue={quantity} min={0} max={available} onChange={setQuantity} />
      <br />
      <br />
      Bottles:{' '}
      <span>
        <b>{quantity}</b>
      </span>
      <br />
      <br />
      <span>Your price: </span>
      <b>{Math.round((value + (bottleSizeNumerical / 1000) * 11.26) * quantity)}</b> eur
      <br />
      Taxes: <b>{Math.round((value + (bottleSizeNumerical / 1000) * 11.26) * quantity) * 0.25}</b>eur
      <br />
      Alcohol tax: <b>{(bottleSizeNumerical / 1000) * 11.26 * quantity}</b> eur
    </div>
  );
};

export default function NestedVariantTable(props: {
  data: NavItemVariant[];
  searchTerms: string[];
  showImagesFor: (n: NavItemVariant) => void;
}) {
  const { showGuide, completeStep } = useGuide('See/Request images');

  const backLabelTypeFormat = { 0: '', 1: 'US LABEL', 2: 'CA LABEL', 3: 'JP LABEL', 4: 'TW LABEL', 5: 'SG LABEL', 6: 'AUS LABEL', 7: '' };

  const LabelToName = (labelType: number) => {
    let lookup: { [key: number]: string } = backLabelTypeFormat;
    return !lookup[labelType] ? '' : lookup[labelType];
  };

  const Highlight = ({ text }: { text?: string }) => (
    <Highlighter
      highlightClassName={style.highlight}
      highlightTag="span"
      searchWords={props.searchTerms}
      autoEscape={true}
      textToHighlight={text ?? ''}
    />
  );

  return (
    <WithModal>
      {modal => (
        <Table<NavItemVariant> dataSource={props.data} rowKey={row => row.variant}>
          <Table.Column<NavItemVariant> title="Variant Number" dataIndex="variant" />
          <Table.Column<NavItemVariant>
            key="packaging"
            title="Packaging"
            dataIndex="packaging"
            sorter={(a, b) => a.packagingSize - b.packagingSize}
            render={(_, row) => `${row.packagingString}${row.packagingSize || ''}`}
          />
          <Table.Column<NavItemVariant>
            key="backLabelType"
            title="Backlabel type"
            dataIndex="backLabelType"
            sorter={(a, b) => a.backLabelType - b.backLabelType}
            render={(_, row) => {
              return (
                <>
                  <Tag>{LabelToName(row.backLabelType)}</Tag>
                </>
              );
            }}
          />
          <Table.Column<NavItemVariant>
            key="wineCondition"
            title="Condition"
            dataIndex="wineCondition"
            sorter={(a, b) => a.wineCondition.localeCompare(b.wineCondition)}
            render={(_, row) => <Highlight text={row.wineCondition} />}
          />
          <Table.Column<NavItemVariant>
            key="lastCost"
            title="Last Cost Price (EUR)"
            dataIndex="lastCost"
            sorter={(a, b) => a.lastCost - b.lastCost}
            render={(_, row) => {
              return Math.round(row.lastCost * 100) / 100;
            }}
          />
          <Table.Column<NavItemVariant>
            key="unitCost"
            title="Unit Cost Price (EUR)"
            dataIndex="unitCost"
            sorter={(a, b) => a.unitCost - b.unitCost}
            render={(_, row) => {
              return Math.round(row.unitCost);
            }}
          />
          <Table.Column<NavItemVariant>
            key="unitPrice"
            title="Unit Price (EUR)"
            dataIndex="unitPrice"
            sorter={(a, b) => a.unitPrice - b.unitPrice}
            render={(_, row) => Math.round(row.unitPrice)}
          />
          <Table.Column<NavItemVariant>
            key="available"
            title="Available"
            dataIndex="available"
            sorter={(a, b) => a.available - b.available}
          />
          <Table.Column<NavItemVariant>
            key="liveex"
            title={<LivExLogo tooltip="Variant reserved for Liv|Ex" />}
            render={(_, i) => (i.reservedForLivEx ? 'Reserved' : 'No')}
          />

          <Table.Column<NavItemVariant>
            key="actions"
            title="Actions"
            dataIndex="actions"
            render={(_, row) => {
              const imgButton = (
                <Button
                  className={Css.Packs.Clickable.Subtle + Css.Effects.Transition.From.Left}
                  style={{ marginLeft: 12 }}
                  type="link"
                  onClick={e => {
                    completeStep(1);
                    props.showImagesFor(row);
                  }}
                >
                  See Images
                </Button>
              );

              const calcButton = (
                <Button
                  className={Css.Packs.Clickable.Subtle + Css.Effects.Transition.From.Left}
                  style={{ marginLeft: 12 }}
                  type="link"
                  onClick={e => {
                    modal.info({
                      title: 'Price Calculator',
                      maskClosable: true,
                      desktopWidth: '50%',
                      content: <InlineTyndhaaret value={row.unitCost} bottleSizeNumerical={row.bottleSize} available={row.available} />,
                    });
                  }}
                >
                  Calculate price
                </Button>
              );

              if (row.variant === props.data[0]?.variant) {
                return (
                  <Space direction="vertical">
                    <Tooltip visible={showGuide(1)} title="Click to see/request images">
                      {imgButton}
                    </Tooltip>
                    {calcButton}
                  </Space>
                );
              }

              return (
                <Space direction="vertical">
                  {imgButton}
                  {calcButton}
                </Space>
              );
            }}
          />
        </Table>
      )}
    </WithModal>
  );
}
