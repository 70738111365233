import { Input, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

function SendMessageModal(props: { sendFrom: User; sendTo: ActiveUser; OnMessageSent: (message: string) => void }) {
  const handleSendMessage = (msg: string) => {
    props.OnMessageSent(msg);
  };

  return (
    <>
      <Text>Send message to {props.sendTo.firstName}</Text>
      <Input.Search onSearch={handleSendMessage} enterButton="Send" />
    </>
  );
}

export default SendMessageModal;
