import { Button, Col, Image, Modal, Row, Space, Typography } from 'antd';
import React from 'react';

import useLocalStorage from '../../../apps/sharedHooks/useLocalStorage';

import './age-check-modal.scss';

export function AgeCheckModal() {
  const [isOldEnough, setIsOldEnough] = useLocalStorage<Boolean>('age-check-modal-is-old-enough', false);

  return (
    <Modal
      className="antd-modal-transparent"
      style={{ marginTop: '20vh' }}
      maskStyle={{ backdropFilter: 'blur(5px)', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
      visible={!isOldEnough}
      closable={false}
      footer={false}
      width={800}
    >
      <Col>
        <Row justify="center" style={{ margin: '20px' }}>
          <Image
            preview={false}
            src={`${window.ENVARGS.REACT_APP_BLOBSTORAGE}/rare-wine-public/stocklist/RW_glass_white.svg`}
            width={150}
          />
        </Row>

        <Row justify="center">
          <Typography.Text>To use this website, you must be of legal age to buy and consume</Typography.Text>
        </Row>

        <Row justify="center">
          <Typography.Text>alcohol in your country of residence. If there is no legislation in this</Typography.Text>
        </Row>

        <Row justify="center">
          <Typography.Text>regard in your country, you must be at least 18 years old.</Typography.Text>
        </Row>

        <Row justify="center" style={{ margin: '20px' }}>
          <Typography.Text>I accept the terms and conditions of this site</Typography.Text>
        </Row>

        <Row justify="center">
          <Space size="large">
            <Button
              style={{ width: '90px' }}
              size="large"
              onClick={() => {
                window.location.href = 'https://www.google.com';
              }}
            >
              <Row align="middle" justify="center">
                <Typography.Text>No</Typography.Text>
              </Row>
            </Button>

            <Button
              style={{ width: '90px' }}
              size="large"
              onClick={() => {
                setIsOldEnough(true);
              }}
            >
              <Row align="middle" justify="center">
                <Typography.Text>Yes</Typography.Text>
              </Row>
            </Button>
          </Space>
        </Row>
      </Col>
    </Modal>
  );
}
