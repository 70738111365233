import { Tag } from 'antd';
import React from 'react';
import { isArray } from 'util';

const usePackaging = () => {
  const GetPackaging = (offers: OfferLine | OfferLine[]) => {
    let tags: JSX.Element[] = [];
    let parsedTypes: String[] = [];

    const data = isArray(offers) ? offers : [offers];
    data.forEach(offer => {
      if (offer.isOWC && !parsedTypes.find(x => x === `OWC${offer.bottlesPerCase}`)) {
        parsedTypes.push(`OWC${offer.bottlesPerCase}`);
        tags.push(
          <Tag key={`owc${offer.bottlesPerCase}`} color="#b36f11">
            OWC{offer.bottlesPerCase}
          </Tag>
        );
      } else if (offer.isOC && !offer.isOWC && !parsedTypes.find(x => x === `OC${offer.bottlesPerCase}`)) {
        parsedTypes.push(`OC${offer.bottlesPerCase}`);
        tags.push(
          <Tag key={`oc${offer.bottlesPerCase}`} color="#b36f11">
            OC{offer.bottlesPerCase}
          </Tag>
        );
      }

      if (offer.isIB && !parsedTypes.find(x => x === `IB`)) {
        parsedTypes.push(`IB`);
        tags.push(
          <Tag key={`ib${offer.bottlesPerCase}`} color="silver">
            IB
          </Tag>
        );
      }
    });

    return tags;
  };

  return { GetPackaging: GetPackaging };
};

export default usePackaging;
