import { useState } from 'react';

export default function useCreateDraftEmail(authToken: string) {
  const [loading, setLoading] = useState(false);

  const createEmailDraft = async (imageRequestId: string, wineName: string, selectedImages?: string[]) => {
    try {
      setLoading(true);
      const resp = await fetch(`${window.ENVARGS.REACT_APP_RAREWINE_API_URL}warehouse/CreateDraftEmailWithPic/${imageRequestId}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          emailSubject: wineName,
          imageIds: selectedImages,
        }),
      });
      setLoading(false);
      if (!resp.ok) {
        throw new Error(await resp.text());
      }
    } finally {
      setLoading(false);
    }
  };
  return { loading, createEmailDraft };
}
