import { useParams } from 'react-router-dom';

export default function useQueryParams(params: string[]): { [key: string]: string } {
  const locationParams: any = useParams();

  let result: any = {};
  params.forEach(p => {
    if (locationParams[p]) result[p] = locationParams[p];
  });

  return result;
}
